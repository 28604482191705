export const shop_item = [
	{
		"id": 1,
		"name": "Bắp cải trắng",
		"unit": "Đơn vị tính: 1kg\r\nĐóng gói theo từng cây/cắt miếng trọng lượng 1kg",
		"package_size": "Bắp cải miền bắc 0.8kg- 1,5kg",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, không có vết thâm đen hay úa vàng \r\n- Bắp cải cuộn chặt; Cắt bỏ lá già\r\n- Cắt gốc sát lá ngoài cùng; vết cắt gốc gọn, đẹp, không bị thâm đen hoặc nâu hóa\r\n- Không bị nứt mặt do quá già, có từ 1-2 lá ôm\r\n- Không bị giập, không nấm thối, không thối gốc hay côn trùng, nấm bệnh phá hoại. Chấp nhận lớp dập < 1cm2 không ăn sâu vào lá trong. \r\n- Chấp nhận lá ôm bên ngoài héo nhẹ do vận chuyển. \r\n- Không dính bùn đất, tạp chất lạ.\r\n- Diện tích sâu không quá 10%",
		"package_description": "Bao gói: Màng co khổ 35/40 cm\r\n- Tem: 1 tem 1 tem thông tin QRcode",
		"package_specs": "Quấn màng co, dán tem thông tin QRcode lên giữa mặt bắp/mặt cắt bắp\r\n- Màng co quấn kín, kéo phần thừa về phía gốc đối với nguyên bắp\r\n- Kéo phần thừa màng co về phía sau bắp đối với bắp cắt",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 2,
		"name": "Cải bó xôi",
		"unit": "Đơn vị tính: bó\r\nĐóng túi: 400g ± 5%",
		"package_size": "22cm-35cm",
		"quality_requirement": "- Sản phẩm tươi mới, màu xanh đến nhạt đặc trưng, lá nguyên vẹn, không có vết thâm đen hay úa vàng.\r\n- Không bị già (không trổ ngồng, không xơ cứng)\r\n- Cắt gốc, vết cắt gốc gọn, đẹp; làm sạch lá vàng héo úa\r\n- Không bị giập, thối úng hay côn trùng, sâu bệnh, nấm bệnh phá hoại\r\n- Bẹ lá không bị tách rời ra, không bị vàng mép lá. Chấp nhận lá sâu ăn ≤ 5% diện tích lá.\r\n- Không dính bùn đất, tạp chất lạ.\r\n- Chỉ nhập kho khi tỷ lệ nguyên liệu đạt tiêu chuẩn ≥ 75%. Riêng với lỗi thỗi nhũn chỉ cho phép < 3%",
		"package_description": "Bao gói: \r\n - Buộc lạt/cói/chun\r\n- Tem: Tem treo chỉ gồm 1 tem thông tin QRcode",
		"package_specs": "- Đóng bằng túi loại chứa được 5kg.\r\n- Củ quả phía dưới, rau ăn lá xếp trên\r\n- Dán 1 tem thương hiệu phía ngoài túi",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 3,
		"name": "Cải mèo",
		"unit": "Đơn vị tính: bó\r\nĐóng túi: 400g ± 5%",
		"package_size": "25cm-45cm\r\n\r\n",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, không có vết thâm đen hay úa vàng, lá xẻ thuỳ đặc trưng của giống.\r\n- Không bị già (thu hoạch đúng tuổi).\r\n- Không bị giập, thối úng hay côn trùng, nấm bệnh phá hoại.\r\n- Vết cắt gốc gọn, đẹp\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao gói: \r\n - Buộc lạt/cói/chun\r\n- Tem: Tem treo chỉ gồm 1 tem thông tin QRcode",
		"package_specs": "- Buộc cách gốc 7-10cm",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 4,
		"name": "Cải chíp",
		"unit": "Đơn vị tính: bó\r\nĐóng túi: 400g ± 5%",
		"package_size": "15cm-22cm\r\n",
		"quality_requirement": "- Sản phẩm tươi mới, màu xanh, nguyên vẹn, không có vết thâm đen hay úa vàng.\r\n- Không bị già (không trổ ngồng, không xơ cứng)\r\n- Không bị giập, thối úng hay côn trùng, nấm bệnh phá hoại.\r\n- Cắt gốc, vết cắt gốc gọn, đẹp, không bị thâm đen hoặc nâu hóa \r\n- Bẹ lá không bị tách rời ra, không bị vàng mép lá. Chấp nhận lá rỗ (bọ nhảy) ≤ 10% diện tích lá. Trái vụ ≤ 15%\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao gói: \r\n - Buộc lạt/cói/chun\r\n- Tem: Tem treo chỉ gồm 1 tem thông tin QRcode",
		"package_specs": "-  Buộc dây giữa phần bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 5,
		"name": "Cải ngồng",
		"unit": "Đơn vị tính: bó\r\nĐóng túi: 400g ± 5%",
		"package_size": "20cm-30cm",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, không có vết thâm đen hay úa vàng.\r\n- Không bị già (thu hoạch đúng tuổi). Chấp nhận cây có hoa đã nở ngắn, không vượt cao hơn lá, tỷ lệ cây có hoa ≤ 20% lô hàng.\r\n- Không bị giập, thối úng hay côn trùng, nấm bệnh phá hoại.\r\n- Vết cắt gốc gọn, đẹp\r\n- Bẹ lá không bị tách rời ra, không bị vàng mép lá. Chấp nhận lá rỗ (bọ nhảy) ≤ 10% diện tích lá. Trái vụ ≤ 15%\r\n- Tỉ lệ thân lá hài hòa và đạt chiều dài quy định.\r\n- Không dính bùn đất, tạp chất lạ.\r\n- Chỉ nhập kho khi tỷ lệ nguyên liệu đạt tiêu chuẩn ≥ 80%. Riêng với lỗi thỗi nhũn chỉ cho phép < 3%",
		"package_description": "Bao gói: \r\n - Buộc lạt/cói/chun\r\n- Tem: Tem treo chỉ gồm 1 tem thông tin QRcode",
		"package_specs": "-  Buộc dây giữa phần bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 6,
		"name": "Cải ngọt",
		"unit": "Đơn vị tính: bó\r\nĐóng túi: 400g ± 5%",
		"package_size": "25cm-35cm",
		"quality_requirement": "- Sản phẩm tươi mới, màu xanh, nguyên vẹn, không có vết thâm đen hay úa vàng.\r\n- Không bị già (không trổ ngồng, không xơ cứng)\r\n- Không bị giập, thối úng hay côn trùng, nấm bệnh phá hoại.\r\n- Cắt gốc, vết cắt gốc gọn, đẹp \r\n- Bẹ lá không bị tách rời ra, không bị vàng mép lá. Chấp nhận lá rỗ (bọ nhảy) ≤ 15% diện tích lá.\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao gói: \r\n - Buộc lạt/cói/chun\r\n- Tem: Tem treo chỉ gồm 1 tem thông tin QRcode",
		"package_specs": "-  Buộc dây giữa phần bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 7,
		"name": "Cải xanh",
		"unit": "Đơn vị tính: bó\r\nĐóng túi: 400g ± 5%",
		"package_size": "22cm-35cm",
		"quality_requirement": "- Sản phẩm tươi mới, màu xanh nhạt đặc trưng, lá nguyên vẹn, không có vết thâm đen hay úa vàng.\r\n- Không bị già (không trổ ngồng, không xơ cứng)\r\n- Cắt gốc, vết cắt gốc gọn, đẹp; làm sạch lá vàng úa\r\n- Không bị giập, thối úng hay côn trùng, sâu bệnh, nấm bệnh phá hoại\r\n- Bẹ lá không bị tách rời ra, không bị vàng mép lá. Chấp nhận lá rỗ (bọ nhảy) ≤ 15% diện tích lá.\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao gói: \r\n - Buộc lạt/cói/chun\r\n- Tem: Tem treo chỉ gồm 1 tem thông tin QRcode",
		"package_specs": "-  Buộc dây giữa phần bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 8,
		"name": "Rau mồng tơi",
		"unit": "Đơn vị tính: bó\r\nĐóng túi: 400g ± 5%",
		"package_size": "10cm-25cm\r\n",
		"quality_requirement": "- Sản phẩm tươi mới, Màu xanh nhạt đến xanh đậm, nguyên vẹn, không có vết thâm đen hay úa vàng.\r\n- Không bị già;\r\n- Không bị giập, thối úng hay côn trùng, nấm bệnh phá hoại.\r\n- Vết cắt gốc gọn, đẹp, không bị thâm đen hoặc nâu hóa\r\n- Chấp nhận đốm mắt cua nhỏ < 5% diện tích lá.\r\n- Không dính bùn đất, tạp chất lạ.\r\n- Không có hoa nở, không cụt ngọn",
		"package_description": "Bao gói: \r\n - Buộc lạt/cói/chun\r\n- Tem: Tem treo chỉ gồm 1 tem thông tin QRcode",
		"package_specs": "-  Buộc dây giữa phần bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 9,
		"name": "Dền đỏ",
		"unit": "Đơn vị tính: bó\r\nĐóng túi: 400g ± 5%",
		"package_size": "15cm-22cm",
		"quality_requirement": "- Sản phẩm tươi mới. Màu đỏ tươi đến đỏ đậm\r\n- Thân + lá nguyên vẹn, không héo úa.\r\n- Không bị dập nát, không bị gãy ngọn, \r\n- Chưa có hoa.\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao gói: \r\n - Buộc lạt/cói/chun\r\n- Tem: Tem treo chỉ gồm 1 tem thông tin QRcode",
		"package_specs": "-  Buộc dây giữa phần bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 10,
		"name": "Ngọn rau lang",
		"unit": "Đơn vị tính: bó\r\nĐóng túi: 400g ± 5%",
		"package_size": "15cm-20cm",
		"quality_requirement": "- Sản phẩm tươi mới, màu xanh nhạt, nguyên vẹn, không có vết thâm đen hay úa vàng.\r\n- Không bị già (thu hoạch đúng tuổi). \r\n- Không bị giập, thối úng hay côn trùng, nấm bệnh phá hoại.\r\n- Vết cắt gốc gọn, đẹp\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao gói: \r\n - Buộc lạt/cói/chun\r\n- Tem: Tem treo chỉ gồm 1 tem thông tin QRcode",
		"package_specs": "-  Buộc dây giữa phần bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 11,
		"name": "Rau muống",
		"unit": "Đơn vị tính: bó\r\nĐóng túi: 400g ± 5%",
		"package_size": "20cm-25cm",
		"quality_requirement": "-  Sản phẩm tươi mới, màu xanh nhạt đến đậm, nguyên vẹn, không có vết thâm đen hay úa vàng\r\n- Không bị già (thân không xơ cứng, nách lá không mọc rễ, không có hoa)\r\n- Không bị giập, thối úng hay côn trùng,  nấm bệnh phá hoại, không có vết bệnh rỉ trắng. Chấp nhận thủng lá <10% diện tích lá.\r\n- Vết cắt gốc gọn, đẹp, không bị thâm đen hoặc nâu hóa\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao gói: \r\n - Buộc lạt/cói/chun\r\n- Tem: Tem treo chỉ gồm 1 tem thông tin QRcode",
		"package_specs": "-  Buộc dây giữa phần bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 12,
		"name": "Xà lách lolo xanh",
		"unit": "Đơn vị tính: kg\r\nĐóng túi: 300g ± 10%",
		"package_size": "15cm-25cm",
		"quality_requirement": "- Sản phẩm tươi mới, màu xanh nhạt, nguyên vẹn, khô ráo, không có vết thâm đen hay úa vàng\r\n- Không bị già (thân không trổ ngồng, không xơ cứng, lá không có màu xanh đậm)\r\n- Không bị giập, thối úng hay côn trùng, nấm bệnh phá hoại.\r\n- Vết cắt gốc gọn, đẹp \r\n- Cắt bỏ lá già bên ngoài,\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao gói: \r\n - Đóng túi nilon\r\n- Tem: 1 tem thông tin QRcode",
		"package_specs": "- Dán 1 tem thương hiệu thông tin Qrcode phần giữa túi",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 13,
		"name": "Xà lách mỡ (cuộn)",
		"unit": "Đơn vị tính: kg\r\nĐóng túi: 400g ± 10%",
		"package_size": "15cm-25cm",
		"quality_requirement": "- Sản phẩm tươi mới, màu xanh nhạt, nguyên vẹn, khô ráo, không có vết thâm đen hay úa vàng\r\n- Không bị già (thân không trổ ngồng, không xơ cứng, lá không có màu xanh đậm)\r\n- Không bị giập, thối úng hay côn trùng, nấm bệnh phá hoại.\r\n- Vết cắt gốc gọn, đẹp \r\n- Cắt bỏ lá già bên ngoài,\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao gói: \r\n - Đóng túi nilon\r\n- Tem: 1 tem thông tin QRcode",
		"package_specs": "- Dán 1 tem thương hiệu thông tin Qrcode phần giữa túi",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 14,
		"name": "Giá đỗ",
		"unit": "Đóng khay/hộp giấy/túi 300g ± 5%",
		"package_size": "4cm-5cm",
		"quality_requirement": "- Sản phẩm tươi mới, còn nguyên vẹn cả rễ + thân + lá mầm, đã loại bỏ lớp vỏ đậu bao lá mầm.\r\n- Không bị dập úng. màu trắng, không đổi màu .\r\n- Không dính bùn đất, tạp chất lạ.\r\n- thân màu trắng, lá mầm vàng nhạt",
		"package_description": "Bao bì: \r\nKhay xốp trắng 14x22/túi 25x35cm\r\n- Tem: 1 tem treo thông tin QR code",
		"package_specs": "- Tem dán phần giữa phần mặt trên của khay/hộp",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 1 ÷ 2 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 15,
		"name": "Dưa chuột thường",
		"unit": "Đơn vị tính: túi/khay\r\nĐóng túi lưới 1kg ± 10%\r\nĐóng khay 0,5kg",
		"package_size": "Chiều dài: 12 cm ÷ 22 cm\r\n- Khối lượng: 100 g ÷ 200 g/quả",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, hình dạng cân đối\r\n- Vỏ căng bóng hoặc có gai, màu xanh đặc trưng, không dị dạng, hai đầu trái không phình to thắt giữa\r\n- Quả không bị già (hạt to và cứng) hoặc bị héo, tóp đầu.\r\n- Vết cắt cuống không phạm vào thịt quả.\r\n- Quả không bị giập, nứt, héo, úng thối, côn trùng chích, nấm bệnh phá hoại.\r\n- Quả không dính đất cát và tạp chất lạ",
		"package_description": "Bao bì: \r\n - Túi lưới nhựa màu xanh\r\nDây thít hoặc đóng khay \r\n- Tem:  1 tem thông tin Qrcode",
		"package_specs": "- Đóng túi lưới dây thít\r\n- Hoặc đóng khay quấn màng co\r\n- Đóng túi lưới dán tem thông tin phần khoá thít",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 16,
		"name": "Dưa chuột baby",
		"unit": "Đơn vị tính: khay\r\nĐóng khay 500g ± 5%",
		"package_size": "- 7cm-12cm\r\n- Cuống quả <1cm\r\n- Đường kính quả ≤ 3 cm",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, hình dạng cân đối\r\n- Vỏ căng bóng, hoặc hơi gân tuỳ giống, màu xanh đậm đặc trưng, không dị dạng, hai đầu trái không phình to thắt giữa\r\n- Quả không bị già (hạt to và cứng) hoặc bị héo, tóp đầu.\r\n- Vết cắt cuống không phạm vào thịt quả, cuống ≤ 1cm\r\n- Quả không bị giập, nứt, héo, úng thối, côn trùng chích, nấm bệnh phá hoại.\r\n- Quả không dính đất cát và tạp chất lạ",
		"package_description": "Bao bì: \r\nQuả dài > 9cm đóng khay 14 x20cm. Quả ≤ 9cm đóng khay 12 x20 \r\n- Tem: 1 tem thông tin Qrcode",
		"package_specs": "- Đóng khay quấn màng co\r\n- Tem dán phần giữa của giữa khay",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 17,
		"name": "Su hào",
		"unit": "Đơn vị tính: kg\r\nĐóng túi lưới xanh 1kg ± 15%\r\nHoặc túi Nilon",
		"package_size": "250g-400r/củ, cắt sát đáy củ",
		"quality_requirement": "- Củ hình dạng cân đối, củ màu xanh nhạt, không bị già, không xơ cứng. \r\n- Cắt sát củ, vết cắt gọn. \r\n- Không trầy xước, không sâu bệnh.\r\n - Không dính bùn đất, tạp chất lạ.\r\n-  Có 3 lá non;",
		"package_description": "Bao gói:\r\n- Để nguyên củ không báo gói\r\n- Dán tem thông tin Qrcode vào phần cạnh củ",
		"package_specs": "- Dán tem lên cạch củ",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 18,
		"name": "Súp lơ trắng",
		"unit": "Đơn vị tính: kg\r\nĐóng gói theo từng cây",
		"package_size": "- Đường kính bông ≥ 12cm\r\nChiều dài cuống ≤ 3cm",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, màu xanh mạ non đặc trưng, hình dáng san hô đặc trưng, không có vết thâm đen hay úa vàng. \r\n- Cây được làm sạch lá (cả phần cuống lá) chỉ còn 3 ÷ 4 lá bẹ ôm bông, lá ôm không dài quá 15cm.\r\n- Mặt bông không úa vàng hoặc có màu đen, không bị quá già (không mọc lá trên bông, mặt bông màu vàng nâu + sơ cứng), không bị sâu cắn hoặc dính tạp chất.\r\n- Cuống không bị thối, nhũn; vết nứt đen ≤ 1cm và ≤ 10% lô hàng. \r\n- Không bị héo, mềm, bầm giập hoặc mặt bông không nguyên vẹn\r\n- Không bị bệnh, côn trùng phá hoại.\r\n- Không dính tạp chất lạ.",
		"package_description": "Bao gói: Màng co khổ 35/40 cm\r\n- Tem: 1 tem 1 tem thông tin QRcode",
		"package_specs": "Quấn màng co, dán tem QRcode trên lên giữa mặt hoa.\r\n- Màng co quấn kín, kéo phần thừa về phía gốc\r\n- Các cạnh tem dán chặt trên màng co",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 19,
		"name": "Súp lơ xanh",
		"unit": "Đơn vị tính: kg\r\nĐóng gói theo từng cây",
		"package_size": "- Đường kính bông ≥ 10cm, chiều dài ≤ 20cm\r\n- Dài cuống ≤ 12cm",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, màu xanh mạ đặc trưng, hình dáng san hô đặc trưng, không có vết thâm đen hay úa vàng. \r\n- Cây được làm sạch lá (cả phần cuống lá) chỉ còn 3 ÷ 4 lá bẹ ôm bông, lá ôm không quá 15cm.\r\n- Mặt bông không úa vàng hoặc có màu đen, không bị quá già (không mọc lá trên bông, mặt bông màu vàng nâu + sơ cứng), không bị sâu cắn hoặc dính tạp chất.\r\n- Cuống không bị thối, nhũn; vết nứt đen ≤ 1cm và ≤ 10% lô hàng. \r\n- Không bị héo, mềm, bầm giập hoặc mặt bông không nguyên vẹn\r\n- Không bị bệnh, côn trùng phá hoại.\r\n- Không dính tạp chất lạ.",
		"package_description": "Bao gói: Màng co khổ 35/40 cm\r\n- Tem: 1 tem 1 tem thông tin QRcode",
		"package_specs": "Quấn màng co, dán tem QRcode trên lên giữa mặt hoa.\r\n- Màng co quấn kín, kéo phần thừa về phía gốc\r\n- Các cạnh tem dán chặt trên màng co",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 20,
		"name": "Bầu sao",
		"unit": "Đơn vị tính: kg\r\nĐóng gói theo từng quả",
		"package_size": "- chiều dài quả >30cm\r\n- nặng từ 0,5kg-1,2kg",
		"quality_requirement": "- Quả căng đều, màu xanh nhạt, không bị xây xát, không có vết của triệu chứng sâu bệnh hại. \r\n- Không dị dạng, không dính bùn đất và tạp chất lạ.\r\n- Còn nguyên cuống từ 1-2cm, vết cắt cuống gọn.",
		"package_description": "Bao bì: không đóng gói\r\n- Tem: 1 tem thông tin Qrcode",
		"package_specs": "- Dán tem trên 1/3 về phía cuống quả",
		"storage_instruction": "Bảo quản khô thoáng mát\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 21,
		"name": "Bí xanh",
		"unit": "Đơn vị tính: kg\r\nĐóng gói theo từng quả",
		"package_size": "0,8kg-1,5kg",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, hình dạng thuôn dài\r\n- Vỏ căng, màu xanh đạm đặc trưng. Chấp nhận sẹo khô nhỏ, mờ-không ăn sâu vào thịt quả, diện tích sẹo ≤ 5% diện tích bề mặt quả.\r\n- Quả không bị già (hạt to, cứng, ruột xốp, chưa lên phấn trắng)\r\n- Vết cắt cuống không phạm vào thịt quả. \r\n- Quả không bị giập, nứt, héo, úng thối, côn trùng chích, sâu bệnh, nấm bệnh phá hoại.\r\n- Quả không dính đất cát và tạp chất lạ",
		"package_description": "Bao bì: không đóng gói\r\n- Tem: 1 tem thông tin QA code",
		"package_specs": "- Dán tem trên 1/3 về phía cuống quả",
		"storage_instruction": "Bảo quản khô thoáng mát\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 22,
		"name": "Bí đỏ hồ lô",
		"unit": "Đơn vị tính: kg\r\nĐóng gói theo từng quả",
		"package_size": "0,7kg-1,5kg",
		"quality_requirement": "- Sản phẩm nguyên vẹn, còn cuống, hình thon dài hơi thắt lại ở giữa\r\n- Vỏ cứng, màu xanh vàng/ vàng đặc trưng. Chấp nhận sẹo khô nhỏ, mờ-không ăn sâu vào thịt quả, diện tích sẹo ≤ 5% diện tích bề mặt quả.\r\n- Thịt bí màu vàng tươi, không bị xốp\r\n- Quả không bị non (hạt nhỏ, mềm). \r\n- Quả không bị giập, nứt, héo, úng thối, côn trùng chích, sâu bệnh, nấm bệnh phá hoại.\r\n- Quả không dính đất cát và tạp chất lạ",
		"package_description": "Bao bì: không đóng gói\r\n- Tem: 1 tem thông tin QA code",
		"package_specs": "- Dán tem trên 1/3 về phía cuống quả",
		"storage_instruction": "Bảo quản khô thoáng mát\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 23,
		"name": "Quả susu",
		"unit": "Đơn vị tính: khay/túi\r\nĐóng 3 - 4 quả/ khay/túi 500g ± 15%/",
		"package_size": "Khối lượng: \r\n150g ÷ 350g/quả",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, hình dạng cân đối, không bị thâm đen, có gai mềm hoặc không có gai (tùy giống)\r\n- Màu xanh non. Chấp nhận sẹo khô nhỏ, mờ-không ăn sâu vào thịt quả, diện tích sẹo ≤ 5% diện tích bề mặt quả.\r\n- Quả không bị già, xơ cứng, chưa mọc mầm\r\n- Vết cắt cuống không phạm vào thịt quả. \r\n- Quả không bị giập, nứt, héo, úng thối, côn trùng chích, sâu bệnh, nấm bệnh phá hoại.\r\n- Quả không dính đất cát và tạp chất lạ",
		"package_description": "Bao bì: \r\n- Túi lưới nhựa màu xanh, dây thít/đóng khay\r\n- Tem:  1 tem thông tin QR code",
		"package_specs": "- Đóng túi lưới dây thít\r\n- Hoặc đóng khay quấn màng co\r\n- Đóng túi lưới dán tem thông tin phần khoá thít\r\n- Đóng khay dán tem giữa khay",
		"storage_instruction": "Bảo quản khô thoáng mát\r\nSử dụng tốt nhất trong 5 ÷ 7 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 24,
		"name": "Bí đỏ ngô non",
		"unit": "Đơn vị tính: kg\r\nĐóng gói theo từng quả",
		"package_size": "0,3kg-0,6kg",
		"quality_requirement": "- Sản phẩm nguyên vẹn, còn cuống, hình thon dài hơi thắt lại ở giữa\r\n- Vỏ cứng, màu xanh vàng/ vàng đặc trưng. Chấp nhận sẹo khô nhỏ, mờ-không ăn sâu vào thịt quả, diện tích sẹo ≤ 5% diện tích bề mặt quả.\r\n- Thịt bí màu vàng tươi, không bị xốp\r\n- Quả không bị non (hạt nhỏ, mềm). \r\n- Quả không bị giập, nứt, héo, úng thối, côn trùng chích, sâu bệnh, nấm bệnh phá hoại.\r\n- Quả không dính đất cát và tạp chất lạ",
		"package_description": "Bao bì: không đóng gói\r\n- Tem: 1 tem thông tin QA code",
		"package_specs": "- Dán tem trên cạnh quả",
		"storage_instruction": "Bảo quản khô thoáng mát\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 25,
		"name": "Cà chua thường",
		"unit": "Đơn vị tính: túi/khay\r\nĐóng túi lưới 500g  ± 15%\r\nQuả ≥ 100g đóng khay 6 quả (600g - 700g)",
		"package_size": "- 80gr-200gr/quả\r\n- Trong mỗi khay sản phẩm, quả nặng nhất không nặng hơn quả nhẹ nhất 30gr",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, hình dạng cân đối, chấp nhận dị dạng nhẹ.\r\n- Vỏ căng bóng, màu đặc trưng. Chấp nhận sẹo khô nhỏ, mờ-không ăn sâu vào thịt quả, diện tích sẹo ≤ 5% diện tích bề mặt quả.\r\n- Độ chín ở mức 4 ÷ 5 (trái còn cứng chắc). Tỷ lệ độ 4 < 50% và yêu và yêu cầu lọc những quả độ 4 và độ 5 riêng từng sọt\r\n- Vết cắt cuống không phạm vào thịt quả. \r\n- Quả không bị giập, nứt, héo, úng thối, côn trùng chích, sâu bệnh, nấm bệnh phá hoại.\r\n- Quả không dính đất cát và tạp chất lạ",
		"package_description": "Bao bì: \r\n- Túi lưới nhựa màu đỏ, dây thít\r\n- Đóng khay \r\n- Tem: 1 tem thông tin QR code\r\n",
		"package_specs": "- Đóng túi lưới dây thít\r\n- Hoặc đóng khay quấn màng co\r\n- Đóng túi lưới dán tem thông tin phần khoá thít\r\n- Đóng khay dán tem giữa khay",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 26,
		"name": "Củ cải trắng",
		"unit": "Đơn vị tính: túi\r\nĐóng túi lưới 500kg ± 15%",
		"package_size": "- 20-30cm\r\n- Phần cuống lá không dài quá 2cm",
		"quality_requirement": "- Củ hình dạng cân đối, màu trắng đều, không trầy xước.  \r\n- Không có dấu hiệu dập úng, hư hỏng, chẽ thân, rễ thân, sâu ăn vỏ, nám đen\r\n-  Không dính bùn đất, tạp chất lạ",
		"package_description": "Bao bì: \r\n- Đóng túi lưới xanh\r\n- Tem:  1 tem thông tin QR code",
		"package_specs": "- Túi lưới dây thít \r\n- Dán tem thông tin lên phần nhựa thít",
		"storage_instruction": "Bảo quản khô thoáng mát\r\nSử dụng tốt nhất trong 5 ÷ 7 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 27,
		"name": "Lặc lày",
		"unit": "Đơn vị tính: túi\r\nĐóng túi: 500g ± 10%",
		"package_size": "Chiều dài quả: \r\n10cm ÷ 20cm",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, hình dạng thuôn dài đặc trưng của giống, không dị dạng, màu xanh sọc trắng\r\n- Chấp nhận sẹo khô nhỏ, mờ- không ăn sâu vào thịt quả, diện tích sẹo ≤ 5% diện tích bề mặt quả.\r\n- Quả không bị già cứng (hột không to cứng, bấm nhẹ được).\r\n- Cuống còn tươi, nguyên vẹn, vết cắt cuống không phạm vào thịt quả.\r\n- Quả không bị giập, nứt, héo, úng thối, côn trùng chích, sâu bệnh, nấm bệnh phá hoại.\r\n- Quả không dính đất cát và tạp chất lạ\r\n- Chỉ nhập kho khi tỷ lệ nguyên liệu đạt tiêu chuẩn ≥ 80%",
		"package_description": "Bao bì: \r\nTúi OPP/PP\r\n25x35cm\r\n- Tem: 1 tem thông tin QR code",
		"package_specs": "- Đóng túi hàn miệng \r\n- Tem dán phần giữa của túi rau",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 28,
		"name": "Mướp đắng",
		"unit": "Đơn vị tính: khay/túi\r\nĐóng khay/túi: 500g ± 10%",
		"package_size": "Chiều dài quả:  15cm ÷ 25cm",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, hình dạng thuôn dài đặc trưng của giống, không dị dạng\r\n- Vỏ xanh sáng, màu sắc đồng đều. Chấp nhận sẹo khô nhỏ, mờ- không ăn sâu vào thịt quả, diện tích sẹo ≤ 5% diện tích bề mặt quả.\r\n- Quả không bị già (hột không to cứng, vỏ không chuyển màu vàng).\r\n- Cuống còn tươi, nguyên vẹn, vết cắt cuống không phạm vào thịt quả.\r\n- Quả không bị giập, nứt, héo, úng thối, côn trùng chích, sâu bệnh, nấm bệnh phá hoại.\r\n- Quả không dính đất cát và tạp chất lạ\r\n- Chỉ nhập kho khi tỷ lệ nguyên liệu đạt tiêu chuẩn ≥ 80%",
		"package_description": "Bao bì: \r\n- Túi lưới nhựa màu xanh, dây thít\r\n- Đóng khay \r\n- Tem: 1 tem thông tin QR code",
		"package_specs": "- Đóng túi nilon hở\r\n- Hoặc đóng khay quấn màng co\r\n- Đóng túi nilon dán tem thông tin giữa túi\r\n- Đóng khay dán tem giữa khay",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 29,
		"name": "Mướp hương",
		"unit": "Đơn vị tính: pack\r\nĐóng 2 - 3 quả (500g)",
		"package_size": "Chiều dài quả: \r\n20cm ÷ 30cm",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, hình dạng thuôn dài cân đối đặc trưng của giống.\r\n- Vỏ căng, màu xanh sáng đặc trưng, không bị bầm giập. Chấp nhận sẹo khô nhỏ, mờ-không ăn sâu vào thịt quả, diện tích sẹo ≤ 5% diện tích bề mặt quả.\r\n- Qủa không bị già (hột còn non và mềm, thịt quả không bị xơ), chai cứng.\r\n- Cuống còn tươi, nguyên vẹn, dài 1cm ÷ 2cm, vết cắt cuống không phạm vào thịt quả. \r\n- Quả không bị giập, nứt, héo, úng thối, côn trùng chích, sâu bệnh, nấm bệnh phá hoại.\r\n- Quả không dính đất cát và tạp chất lạ",
		"package_description": "Bao bì: \r\n - Quấn màng co pacck 2-3 đủ trọng lượng\r\n- Tem: 1 tem thông tin QR code",
		"package_specs": " - Tem dán phần giữa của pack",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 30,
		"name": "Cần tây lớn",
		"unit": "Đơn vị tính: túi\r\nĐóng túi: 500g ± 10%",
		"package_size": "30cm-45cm \r\n",
		"quality_requirement": "- Sản phẩm tươi mới, nguyên vẹn, thân màu trắng xanh, lá màu xanh đặc trưng, không có vết thâm đen hay úa vàng\r\n- Không bị già (không xơ cứng)\r\n- Không bị giập, thối úng hay sâu bệnh, côn trùng phá hoại.\r\n- Vết cắt gốc gọn, đẹp, không bị thâm đen, hết rễ hoàn toàn\r\n- Tỉ lệ thân lá hài hòa và đạt chiều dài quy định.\r\n- Không dinh bùn đất, tạp chất lạ.",
		"package_description": "Bao bì: \r\n - Bó lạt/cói/chun\r\n25x35cm/25x50cm\r\n- Tem:  1 tem treo thông tin QR code",
		"package_specs": "- Buộc dây vị trí 1/3 từ gốc lên",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 31,
		"name": "Nấm đùi gà",
		"unit": "Đóng khay/hộp nhựa/túi 300g ± 10%",
		"package_size": "- Thân nấm dài 8cm ÷ 15cm, đường kính 2cm ÷ 6cm\r\n- Đường kính mũ nấm 3-8cm; Cuống nấm dài  6-10cm",
		"quality_requirement": "- Sản phẩm nguyên vẹn, tươi mới, thân màu trắng, mũ nấm màu nâu đặc trưng\r\n- Không bị già (tai nấm không xòe ngang hoặc vểnh ngược lên, không ngả màu, không bị khô)\r\n- Không bị giập úng nước, không chảy nhớt, không có mùi lạ, không bị ẩm mốc\r\n- Không bị nấm bệnh, côn trùng phá hoại\r\n- Không có giá thể, không dính đất cát và tạp chất lạ.",
		"package_description": "Bao bì: \r\nKhay xốp trắng14 x 20cm cm\r\n- Tem: 1 tem treo thông tin QR code",
		"package_specs": "- Đóng khay quấn màng co hoặc đóng hộp nhựa\r\n- Tem dán phần giữa phần mặt trên của khay/hộp",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 32,
		"name": "Nấm kim châm",
		"unit": "Đóng túi: 200g ± 10%",
		"package_size": "13cm-18cm",
		"quality_requirement": "- Sản phẩm nguyên vẹn, không dập úng, đen thân phần gốc nấm.  \r\n- Sợi tơi không gãy.\r\n- Mũ nấm bóng và chắc.\r\n- Không dính bùn đất, tạp chất lạ.\r\n- Mũ và thân nấp màu trắng phần gốc có màu vàng đến nâu nhạt",
		"package_description": "Bao bì: \r\nTúi OPP/PP\r\n14x20cm\r\n- Tem: 1 tem treo thông tin QR code",
		"package_specs": "- Đóng túi hàn kín miệng \r\n- Tem dán phần giữa của túi rau, gốc bằng",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 33,
		"name": "Nấm sò trắng",
		"unit": "Đóng túi 500g ± 5%",
		"package_size": "- Thân nấm dài 2cm ÷ 6cm\r\n- Đường kính mũ nấm 2cm ÷ 5cm",
		"quality_requirement": "- Sản phẩm nguyên vẹn, tươi mới, màu trắng đặc trưng\r\n- Không bị già (mũ nấm không nở to, mỏng; không rách thành nhiều dường, không bị khô, không ngả màu)\r\n- Không bị giập úng nước, không chảy nhớt, không có mùi lạ, không bị ẩm mốc\r\n- Không bị nấm bệnh, côn trùng phá hoại\r\n- Không có giá thể và tạp chất lạ.",
		"package_description": "Bao bì: \r\nTúi OPP/PP\r\n25x35cm\r\n- Tem: 1 tem thương hiệu 7x7 & 1 tem thông tin Qacode",
		"package_specs": "- Đóng túi hàn kín miệng \r\n- Tem dán phần giữa của túi rau, gốc bằng",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 3 ÷ 5 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 34,
		"name": "Hành lá",
		"unit": "Đơn vị tính: bó\r\nĐóng gói theo từng bó 50g",
		"package_size": "30cm-45cm,",
		"quality_requirement": "- Sản phẩm tươi mới, còn nguyên rễ. \r\n- Lá nguyên vẹn không dập nát; không có lá vàng úa.\r\n- Không dính bùn đất, tạp chất lạ. \r\n- Lá xanh, rễ và thân trắng",
		"package_description": "Bao bì: Quấn màng co \r\n- Tem:  1 tem treo thông tin QR code",
		"package_specs": "- Quấn màng co, dán tem phần giữa bó\r\n- Các cạnh tem dán chặt trên màng co, gốc bằng nhau",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 35,
		"name": "Rau mùi tàu",
		"unit": "Đơn vị tính: bó\r\nĐóng gói theo từng bó 50g",
		"package_size": "12cm-17cm\r\n",
		"quality_requirement": "- Sản phẩm tươi mới, tách rời từng lá, lá màu xanh đậm\r\n- Lá nguyên vẹn không dập nát, không có lá vàng úa .\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao bì: \r\n - Bó lạt/cói/chun\r\n- Tem:  1 tem treo thông tin QR code",
		"package_specs": "- Tem treo vào dây bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 36,
		"name": "Rau mùi ta",
		"unit": "Đơn vị tính: túi\r\nĐóng túi: 100g ± 10%",
		"package_size": "12cm-17cm",
		"quality_requirement": "- Sản phẩm tươi mới, còn nguyên rễ, màu xanh\r\n- Lá nguyên vẹn không dập nát, không có lá vàng úa hoặc sâu bệnh.\r\n- Chưa bị ra hoa.\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao bì: \r\n - Bó lạt/cói/chun\r\n- Tem:  1 tem treo thông tin QR code",
		"package_specs": "- Tem treo vào dây bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 37,
		"name": "Rau kinh giới",
		"unit": "Đơn vị tính: bó\r\nĐóng gói theo từng bó 50g",
		"package_size": "10cm-17cm\r\n",
		"quality_requirement": "- Sản phẩm tươi mới, màu xanh đến xanh nhạt\r\n- Lá nguyên vẹn không dập nát, không sâu bệnh.\r\n- Còn nguyên cành, lá, không gãy ngọn.\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao bì: \r\n - Bó lạt/cói/chun\r\n- Tem:  1 tem treo thông tin QR code",
		"package_specs": "- Tem treo vào dây bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 38,
		"name": "Tía tô",
		"unit": "Đơn vị tính: bó\r\nĐóng gói theo từng bó 50g",
		"package_size": "10cm-20cm",
		"quality_requirement": "- Sản phẩm tươi mới, màu tím đặc trưng\r\n- Lá nguyên vẹn không dập nát, không sâu bệnh.\r\n- Còn nguyên cành, lá, không gãy ngọn.\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao bì: \r\n - Bó lạt/cói/chun\r\n- Tem:  1 tem treo thông tin QR code",
		"package_specs": "- Tem treo vào dây bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 39,
		"name": "Húng láng",
		"unit": "Đơn vị tính: túi\r\nĐóng túi: 100g ± 5%",
		"package_size": "5cm-10cm",
		"quality_requirement": "- Sản phẩm tươi mới, lá màu xanh/xanh nhạt, thân tím\r\n- Lá nguyên vẹn không dập nát, không sâu bệnh.\r\n- Còn nguyên cành, lá, không gãy ngọn.\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao bì: \r\n - Bó lạt/cói/chun\r\n- Tem:  1 tem treo thông tin QR code",
		"package_specs": "- Tem treo vào dây bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 40,
		"name": "Húng quế",
		"unit": "Đơn vị tính: bó\r\nĐóng gói theo từng bó 50g",
		"package_size": "10cm-15cm",
		"quality_requirement": "- Sản phẩm tươi mới, màu xanh đến xanh nhạt\r\n- Lá nguyên vẹn không dập nát, không sâu bệnh.\r\n- Còn nguyên cành, lá, không gãy ngọn.\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao bì: \r\n - Bó lạt/cói/chun\r\n- Tem:  1 tem treo thông tin QR code",
		"package_specs": "- Tem treo vào dây bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	},
	{
		"id": 41,
		"name": "Húng bạc hà",
		"unit": "Đơn vị tính: bó\r\nĐóng gói theo từng bó 50g",
		"package_size": "10cm-15cm",
		"quality_requirement": "- Sản phẩm tươi mới, màu xanh/xanh nhạt\r\n- Lá nguyên vẹn không dập nát, không sâu bệnh.\r\n- Còn nguyên cành, lá, không gãy ngọn.\r\n- Không dính bùn đất, tạp chất lạ.",
		"package_description": "Bao bì: \r\n - Bó lạt/cói/chun\r\n- Tem:  1 tem treo thông tin QR code",
		"package_specs": "- Tem treo vào dây bó",
		"storage_instruction": "Bảo quản tối ưu 6°C ÷ 8°C.\r\nSử dụng tốt nhất trong 2 ÷ 3 ngày kể từ ngày đóng gói.\r\nKhông sử dụng sản phẩm khi có dấu hiệu hư hỏng."
	}
]