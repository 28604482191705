import React, { useState, useEffect } from 'react';
import './Shop.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { orderActions, alertActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';

import { ScreenWithNavBarAndFooter } from 'app/components';
import { Modal } from "bootstrap";

import { shop_item } from "../Dashboard/shop_items";

import { decodeHtmlContent } from "utils/AppHelper";

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const Shop = connect(mapStateToProps, actionCreators)(props => {

    const { lang, locale_string } = props.pageInfo;

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [itemToShow, setItemToShow] = useState(null);
    const [shopItemModal, setShopItemModal] = useState(null);

    useEffect(() => {
        setShopItemModal(new Modal('#shopItemModal'));

        if (!location.state) {
            window.scrollTo(0, 0);
        }

        if (params.id) {
            let item = shop_item.filter(item => parseInt(item.id) === parseInt(params.id))

            if (item.length > 0) {
                setItemToShow(item[0]);
            } else {
                setItemToShow(null);
            }
        } else {
            setItemToShow(null);
        }

        return () => {
        }
    }, [location.pathname])

    useEffect(() => {
        if (itemToShow) {
            try {
                shopItemModal.show();
            } catch (error) {
                console.log(error);
            }
        }

        return () => {
            try {
                shopItemModal.hide();   
            } catch (error) {
                console.log(error);
            }
        }
    }, [itemToShow]);

    useEffect(() => {
        if (shopItemModal) {
            const modal = document.getElementById('shopItemModal');
            modal.addEventListener('hide.bs.modal', function() {
                navigate(NAVIGATION.SHOP, { state: { from_detail_page: true } });
            })
        }

        return () => {
        }
    }, [shopItemModal])
    
    
    function renderShopItems(list) {
        if (!list || list.length === 0) return null;

        let shop_items = [];
        list.forEach((item, index) => {
            shop_items.push(
                <div className="col-md-3 mb-3 mb-md-4" key={'shop_item_' + index}>
                    <Link to={NAVIGATION.SHOP + '/' + item.id} state={{ from_detail_page: true }} className="shop-item">
                        <div className="item-image">
                            <img src={`/public_assets/images/shop-item/${item.id}.jpg`} alt="" className="img-fluid w-100"></img>
                        </div>
                        <div className="item-info">
                            <h4 className="item-name">{item.name}</h4>
                            <p className="item-unit" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.unit) }}></p>
                        </div>
                    </Link>
                </div>
            )
        })

        return (
            <div className="row">
                {shop_items}
            </div>
        );
    }

    function renderShopItemDetail(item) {
        if (!item) return;

        return (
            <div className="row shop-item-detail-modal">
                <div className="col-md-4">
                    <img src={`/public_assets/images/shop-item/${item.id}.jpg`} alt="" className="img-fluid w-100 mb-4"></img>
                </div>
                <div className="col-md-8">
                    <h1 className="item-name">{item.name}</h1>
                    <table className="table table-bordered mb-0">
                        <tbody>
                            <tr>
                                <td>Đơn vị thành phẩm</td>
                                <td dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.unit) }}></td>
                            </tr>
                            <tr>
                                <td>Kích thước/Khối lượng đóng gói</td>
                                <td dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.package_size) }}></td>
                            </tr>
                            <tr>
                                <td>Quy cách đóng gói</td>
                                <td dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.package_specs) }}></td>
                            </tr>
                            <tr>
                                <td>Yêu cầu sản phẩm</td>
                                <td dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.quality_requirement) }}></td>
                            </tr>
                            {/* <tr>
                                <td>Hướng dẫn sử dụng, bảo quản</td>
                                <td dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.storage_instruction) }}></td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
    
    return (
        <ScreenWithNavBarAndFooter>
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <h2 className="sec-title">Các sản phẩm LocaMart cung cấp</h2>
                    <p className="sec-description">LocaMart cung cấp đa dạng các loại thực phẩm sạch tiêu chuẩn <b>VietGAP</b> và đặc sản <b>OCOP</b> cho người tiêu dùng trên khắp Việt Nam.</p>
                </div>
            </header>
            <section id="shop" className="page-section shop-section">
                <div className="container-xl">
                    <div className="sec-content">
                        { renderShopItems(shop_item) }
                    </div>
                </div>
            </section>

            {/* shopItemModal */}
            <div className="modal fade" id="shopItemModal" tabIndex="-1" aria-labelledby="shopItemModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen-lg-down modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="shopItemModalLabel">Thông tin sản phẩm</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            { renderShopItemDetail(itemToShow) }
                        </div>
                    </div>
                </div>
            </div>
        </ScreenWithNavBarAndFooter>
    )
})

export {
    Shop
};