import React, { useState, useEffect } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { orderActions, alertActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { ScreenWithNavBarAndFooter, Slider } from 'app/components';

import { decodeHtmlContent } from "utils/AppHelper";

import { province_list } from './data';
import { shop_item } from './shop_items';

import { membershipService } from "services";

const mapStateToProps = (state) => {
    const { pageInfo, auth, order } = state;
    return { pageInfo, auth, order }
}

const actionCreators = {
    updateOrder: orderActions.update,
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}

const Dashboard = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, apiRequest, error } = auth;

    const location = useLocation();
    const navigate = useNavigate();

    const home_header_slides = [
        'https://w.ladicdn.com/s1150x1050/60f3d39942e3210012c90587/4-20240101033906-lhen6.jpg',
        'https://w.ladicdn.com/s1150x1050/60f3d39942e3210012c90587/3-20240101033858-gl1y4.jpg',
        'https://w.ladicdn.com/s1150x1050/60f3d39942e3210012c90587/374da2a2-b415-404e-b0be-7fdf7ed0cea3-20240101033933-vs_j1.jpeg',
        'https://w.ladicdn.com/s1150x1050/60f3d39942e3210012c90587/e83ff432-89eb-43af-907e-55c93694330b-20240101033934-0peys.jpeg',
        'https://w.ladicdn.com/s1150x1050/60f3d39942e3210012c90587/b48c2599-39e1-4b6d-84fa-a748a610f9f5-20240101033933-nohg-.jpeg',
        'https://w.ladicdn.com/s1150x1050/60f3d39942e3210012c90587/473c390e-ccd6-4fdc-b0ef-01fb043ebeb5-20240101033933-e8pfp.jpeg',
        'https://w.ladicdn.com/s1150x1050/60f3d39942e3210012c90587/1111-20240101033858-lnbvf.jpg'
    ]

    const partner = [
        {
            name: 'GreenFarm',
            img_url: '/public_assets/images/home/partner-section/greenfarm.jpg'
        },
        {
            name: 'OCOP',
            img_url: '/public_assets/images/home/partner-section/ocop.jpg'
        },
        {
            name: 'VietFarm',
            img_url: '/public_assets/images/home/partner-section/vietfarm.jpg'
        },
        {
            name: 'Viên Sơn',
            img_url: '/public_assets/images/home/partner-section/vien-son.jpg'
        },
        {
            name: 'VietGAP',
            img_url: '/public_assets/images/home/partner-section/vietgap.jpg'
        },
        {
            name: 'PT Farms',
            img_url: '/public_assets/images/home/partner-section/pt-farms.jpg'
        },
    ]

    const news = [
        {
            "title": "Giải pháp phân phối cho nông sản Việt chất lượng cao từ LocaMart",
            "description": "(Dân trí) - Đầu ra cho nông sản Việt vẫn là bài toán cần tìm lời giải đối với các cấp chính quyền và cơ quan quản lý địa phương, thay vì chỉ cần cải tiến chất lượng lẫn số lượng.",
            "url": "https://dantri.com.vn/kinh-doanh/giai-phap-phan-phoi-cho-nong-san-viet-chat-luong-cao-tu-locamart-20231121095613441.htm ",
            "img_url": "https://cdnphoto.dantri.com.vn/4n_lXQ-NVvVl3kWu3_w10h_rEG4=/thumb_w/1360/2023/11/21/1700130215576dantridocx-1700535096046.png",
            "logo_url": "https://cdnphoto.dantri.com.vn/9O4JtWt_uDZY3w-Ubupzh57mhpw=/2023/12/27/artboard-white2x-8-1703643381239.png"
        },
        {
            "title": "Hưởng ứng Chương trình OCOP: LocaMart góp phần nâng tầm nông sản Việt",
            "description": "VOV.VN - Kể từ khi Thủ tướng Chính phủ phê duyệt Chương trình &quot;Mỗi xã một sản phẩm&quot; (OCOP) năm 2018, tính đến ngày 30/06/2023, cả nước ta có 63/63 tỉnh, thành phố tham gia đánh giá, phân hạng sản phẩm OCOP, có 5.069 chủ thể OCOP và 9.852 sản phẩm OCOP đạt 3 sao trở lên.",
            "url": "https://vov.vn/doanh-nghiep/thong-tin-doanh-nghiep/huong-ung-chuong-trinh-ocop-locamart-gop-phan-nang-tam-nong-san-viet-post1059877.vov ",
            "img_url": "https://media-vov.emitech.vn/sites/default/files/styles/large/public/2023-11/1_7.png.jpg",
            "logo_url": "https://vov.vn/themes/custom/vovvn/logo.svg"
        },
        {
            "title": "Hưởng ứng Chương trình OCOP để nâng tầm nông sản Việt",
            "description": "24h - Kể từ khi Thủ tướng Chính phủ phê duyệt chương trình \"Mỗi xã một sản phẩm\" (OCOP) vào năm 2018, Việt Nam đã chứng kiến sự phát triển mạnh mẽ của hoạt động sản xuất nông - đặc sản của từng vùng miền.",
            "url": "https://www.24h.com.vn/thi-truong-24h/huong-ung-chuong-trinh-ocop-de-nang-tam-nong-san-viet-c341a1519573.html?gidzl=kdEAEowOepgwQeeuBOQWFBrfYZuqWQ4_f6_VFMMAeclwPOquF83mQVevZMmvWATghpxUR3Mdt1WrBvwdEG",
            "img_url": "https://cdn.24h.com.vn/upload/4-2023/images/2023-11-17/Huong-ung-Chuong-trinh-OCOP-de-nang-tam-nong-san-Viet-7-1700190990-930-width800height535.jpg",
            "logo_url": "https://cdn.24h.com.vn/upload/1-2024/images/2024-01-04/logo-t---t------resize-a------c-1704360482-314-widthheight.svg"
        },
        {
            "title": "Ra mắt chuỗi siêu thị LocaMart, hướng tới \"một triệu gia đình Việt ăn rau sạch\"",
            "description": "SK&ĐS - Hiện nay, vấn đề thực phẩm sạch và an toàn đã trở thành mối quan tâm hàng đầu của người tiêu dùng Việt Nam. Chúng ta chứng kiến xu hướng toàn cầu thay đổi mạnh mẽ trong lối sống và quan điểm về thực phẩm.",
            "url": "https://suckhoedoisong.vn/ra-mat-chuoi-sieu-thi-locamart-huong-toi-mot-trieu-gia-dinh-viet-an-rau-sach-169231117100553101.htm ",
            "img_url": "https://suckhoedoisong.qltns.mediacdn.vn/thumb_w/640/324455921873985536/2023/11/17/nong-san-huu-co-094546891-17001902416892059813894.jpg",
            "logo_url": "https://static.mediacdn.vn/suckhoedoisong/image/logo.svg"
        },
        {
            "title": "LocaMart và khát vọng vì sức khỏe người Việt",
            "description": "Bảo đảm chất lượng vệ sinh an toàn thực phẩm giữ vị trí quan trọng trong việc bảo vệ sức khỏe, giảm tỷ lệ mắc bệnh, tăng cường sức lao động, học tập... từ đó thúc đẩy sự tăng trưởng kinh tế, văn hóa xã hội và thể hiện nếp sống văn minh.",
            "url": "https://phunu.nld.com.vn/tieu-dung-thong-minh/locamart-va-khat-vong-vi-suc-khoe-nguoi-viet-20231116194340027.htm",
            "img_url": "https://nld.mediacdn.vn/thumb_w/540/291774122806476800/2023/11/16/photo-1-1700138204850849266067.jpg",
            "logo_url": "https://static.mediacdn.vn/nld.com.vn/images/logo_tet_web.png"
        },
        {
            "title": "Bước ngoặt sáng của nông nghiệp Việt với &quot;Mỗi xã một sản phẩm&quot;",
            "description": "Năm 2022, giá trị xuất khẩu nông sản Việt đạt 43.2% tổng giá trị xuất khẩu của cả nước. Chương trình \"Mỗi xã một sản phẩm\" (OCOP) đã mở ra một chương mới cho ngành sản xuất nông sản và đặc sản tại Việt Nam.",
            "url": "https://kinhdoanhvathitruong.net/buoc-ngoat-sang-cua-nong-nghiep-viet-voi-moi-xa-mot-san-pham.html",
            "img_url": "https://lh7-us.googleusercontent.com/BFrOSsfT_28_SUC7mA9piLJIax6zlGGva9Vmsq-4mJ1oZTSTx0oTQZMfPGNqaRxXHIwwx79StrJ6-6CEUie1x5azSr9ECDhDbJ3emj4ItYsysCaemsi3azVCiMOIWYK8WazFBnFbygcb0r5iLMSfPAk",
            "logo_url": "https://kinhdoanhvathitruong.net/app/webroot/uploads/images/kdvtt.png"
        },
        {
            "title": "Nâng tầm nông sản Việt cùng OCOP và giải pháp Chuỗi siêu thị chất lượng cao",
            "description": "Năm 2022, lĩnh vực nông sản tại Việt Nam đã tạo nên một con số ấn tượng khi giá trị xuất khẩu nông sản đạt 43,2% tổng giá trị xuất khẩu của cả nước. Đây là một minh chứng rõ ràng cho tiềm năng và cơ hội phát triển của lĩnh vực nông sản tại Việt Nam.",
            "url": "https://kinhtevasuckhoe.com/nang-tam-nong-san-viet-cung-ocop-va-giai-phap-chuoi-sieu-thi-chat-luong-cao.html",
            "img_url": "https://lh7-us.googleusercontent.com/IznYt9sm0CSTO0mY1AaRda8EcLR6aywsdGvmhvEcfS8s_ujdRZ2Ij_jp2jxlDoshJwKYDNUjB121JyFJSPTteN6KqYL2s_YsEyzYMtZ4VAC9QwNdzz0lja6VMVEQA5Ujx_JZLRoPXE6uRarS_BvYPkU",
            "logo_url": "https://kinhtevasuckhoe.com/app/webroot/uploads/images/ktvsk.png"
        },
        {
            "title": "LocaMart - Giải pháp góp phần nâng tầm nông sản Việt",
            "description": "Năm 2022 đã ghi dấu một bước tiến đột phá trong lĩnh vực nông sản tại Việt Nam, khi giá trị xuất khẩu nông sản đạt đến 43,2% tổng giá trị xuất khẩu của cả nước. Điều này là một minh chứng rõ ràng cho tiềm năng và cơ hội phát triển của ngành nông sản tại Việt Nam. Tuy nhiên, để thúc đẩy tiềm năng này và đưa sản phẩm nông nghiệp Việt ra thị trường quốc tế, việc tạo ra một chuỗi giá trị từ nguồn gốc đến tay người tiêu dùng là vô cùng quan trọng.",
            "url": "https://thuonghieuvacuocsong.com/locamart-giai-phap-gop-phan-nang-tam-nong-san-viet.html",
            "img_url": "https://lh7-us.googleusercontent.com/WhmM-jtzBTw9QmjyYnW_3eSqM6eMs3dTt0MNzp2toQ4a-karvFATQ9lZi2b6YpAXjw_ENg2-2PMJWHbeaHAuk-duCgcF2C-qOYcbkZWWQtK0POlteY1OWpwO1lCTXmwJWYD7PKPfLaCRCXLhJbL7Ic0",
            "logo_url": "https://thuonghieuvacuocsong.com/app/webroot/uploads/files/1-30.png"
        },
        {
            "title": "Nâng tầm nông sản Việt với chương trình OCOP và sứ mệnh của LocaMart",
            "description": "Tính đến ngày 30/6/2023, cả nước đã có đủ 63 tỉnh và thành phố tham gia đánh giá OCOP, 5.069 chủ thể OCOP, và 9.852 sản phẩm OCOP đạt 3 sao trở lên. Mục tiêu tới năm 2025, chúng ta sẽ có ít nhất 10.000 sản phẩm OCOP đạt tiêu chuẩn từ 3 sao trở lên.",
            "url": "https://bizfnews.com/nang-tam-nong-san-viet-voi-chuong-trinh-ocop-va-su-menh-cua-locamart/",
            "img_url": "https://bizfnews.com/wp-content/uploads/2023/11/cover-1200-800-419.jpg.webp",
            "logo_url": "https://bizfnews.com/wp-content/uploads/2020/10/bizf-logo.png"
        },
        {
            "title": "LocaMart kết nối người tiêu dùng với nông sản Việt",
            "description": "Tính đến ngày 30/6/2023, cả nước đã có đủ 63 tỉnh và thành phố tham gia đánh giá OCOP, 5.069 chủ thể OCOP, và 9.852 sản phẩm OCOP đạt 3 sao trở lên. Mục tiêu tới năm 2025, chúng ta sẽ có ít nhất 10.000 sản phẩm OCOP đạt tiêu chuẩn từ 3 sao trở lên.",
            "url": "https://doanhnghieptoancau.com/locamart-ket-noi-nguoi-tieu-dung-voi-nong-san-viet/",
            "img_url": "https://doanhnghieptoancau.com/wp-content/uploads/2023/11/images.jpg",
            "logo_url": "https://i.imgur.com/pRL3u8n.png"
        },
        {
            "title": "LocaMart: Điểm sáng mới trong phân phối sản phẩm OCOP",
            "description": "Trong bối cảnh nỗ lực đưa sản phẩm OCOP đến với người tiêu dùng toàn quốc và vươn ra thế giới, tính đến ngày 30/6/2023, đã có đủ 63 tỉnh và thành phố tham gia đánh giá OCOP. Điều này đánh dấu bước tiến quan trọng trong việc nâng cao chất lượng và giá trị của nông sản Việt Nam.",
            "url": "http://bantinnhanh.net/locamart-diem-sang-moi-trong-phan-phoi-san-pham-ocop-2114.html",
            "img_url": "https://lh7-us.googleusercontent.com/a7EdGU949fR9TJvFU3u15ka4k57Q4Z8cLQoU0SCWjMsI_Wi9y_qHVkECLf_gXiwmf32OOuk4qj-nyLSraSlgNJbFyvHKqD4poZ8B9RbwALfCnVLD7b5gz03qThWp7Re4UiGVMZuLjGtCOR1cbpFoNQ",
            "logo_url": "http://bantinnhanh.net/public/images/lg-bantinnhanh.png"
        },
        {
            "title": "LocaMart - Đối tác phân phối đáng tin cậy của người nông dân Việt",
            "description": "Ngành hàng nông - đặc sản của Việt Nam đã từ lâu là nguồn tài nguyên vô giá đóng góp vào nền kinh tế và văn hóa của đất nước. Tuy nhiên, việc đưa sản phẩm nông - đặc sản từ nông trại đến bàn ăn của người tiêu dùng vẫn là một bài toán phức tạp, đặc biệt là khi cần đảm bảo chất lượng và giá trị của sản phẩm.",
            "url": "https://chuyendongthitruong.vn/locamart-doi-tac-phan-phoi-dang-tin-cay-cua-nguoi-nong-dan-viet-198553.html",
            "img_url": "https://chuyendongthitruong.vn/uploads/images/2023/11/04/2a-1699070791.png",
            "logo_url": "https://chuyendongthitruong.vn/uploads/images/setting/huyhoang/2021/10/26/logo-pc-1608001557-1635250494.png?ver=1687920392"
        },
        {
            "title": "Giải pháp đầu ra mới cho chuỗi giá trị ngành hàng nông - đặc sản Việt",
            "description": "Trong những năm gần đây, ngành nông sản Việt đã có những chuyển biến tích cực trong chất lượng sản phẩm. Nhờ có sự thúc đẩy từ chương trình &quot;Mỗi xã một sản phẩm&quot; (OCOP), chúng ta đã có gần 8.500 sản phẩm đạt tiêu chuẩn OCOP từ 3 sao trở lên và đủ 63 tỉnh thành tham gia đánh giá sản phẩm OCOP.",
            "url": "https://tiepthiplus.com/giai-phap-dau-ra-moi-cho-chuoi-gia-tri-nganh-hang-nong-dac-san-viet.html",
            "img_url": "https://lh7-us.googleusercontent.com/wQE5IVbNggZ8JiFEYPLo9LCqY94XYkzp0YPci8t3ieOF4ZaIBGMxEjz5E23WtK01LnYyqpP3nS1e1UiRrClfNYXXGq4foPhbZK7OvZa03Kqnv1h-FNgkoV4JoUB3699KQMxq9644s1WSxiZkmShhbbA",
            "logo_url": "https://tiepthiplus.com/app/webroot/uploads/files/1-29.png"
        },
        {
            "title": "LocaMart đồng hành cùng \"Một triệu gia đình Việt ăn rau sạch&quot;",
            "description": "Sau đại dịch Covid-19, vấn đề sức khỏe đối với người Việt nói riêng và tất cả mọi người trên toàn cầu nói chung trở thành vấn đề được quan tâm hàng đầu. Vô hình chung, an toàn vệ sinh thực phẩm trở thành ưu tiên số một trong danh sách chăm sóc sức khỏe của người Việt.",
            "url": "https://doisongtoancanh.net/locamart-dong-hanh-cung-mot-trieu-gia-dinh-viet-an-rau-sach.html",
            "img_url": "https://lh7-us.googleusercontent.com/3uylB2Rj_BADeX2w7z5yRVO9dF827S7RuWuuX0-FIJMHI7hitOR0FnAYNu6aJDMSMEXgKUV8fQBfZSa9-QcKipL-DRRt0JnykHF_de3X5wL5kC9AVAq5AJSmE6LCkWOjtvACiKNM0OKdMjZE791SbWk",
            "logo_url": "https://doisongtoancanh.net/app/webroot/uploads/images/dstc.jpg"
        },
        {
            "title": "LocaMart: Chuỗi siêu thị vì sức khỏe hàng triệu gia đình Việt",
            "description": "Trong những năm gần đây, vệ sinh an toàn thực phẩm trở thành một vấn đề được quan tâm nhất trong danh mục liên quan đến sức khỏe của người Việt, đặc biệt sau khi trải qua đại dịch Covid-19.",
            "url": "https://tiepthivatieudung.net/locamart-chuoi-sieu-thi-vi-suc-khoe-hang-trieu-gia-dinh-viet.html",
            "img_url": "https://lh7-us.googleusercontent.com/GVhJ79QG7BVJkZeFDyYdrkrKwiwU6JlGe034WiPtIG9xXaJJRLszEImnzzRJXGAAGpHkiMMEZrsBBTmFxLFzvPCPS6HvLYjsdE65s5MiuZbi6C0cEP9cEGM5r0S-f2nOae9f827xrwlvuWpMHkwZmQY",
            "logo_url": "https://tiepthivatieudung.net/app/webroot/uploads/files/1-32.png"
        }
    ]

    const [branches, setBranches] = useState([]);

    useEffect(() => {
        membershipService.fetch_nearby_store().then(data => {
            console.log('?????? nearbyStoreList', data);
            if (data && data.length > 0) {
                setBranches(data)
            } else {
                setBranches([])
            }
        })

        return () => {
        }
    }, [])
    
    function renderPartnerItem(list) {
        if (!list || list.length === 0) return null;

        const renderPartnerItemDesktop = (item, index) => {
            return (
                <div className="col-md-4 mb-3 mb-md-4" key={'partner_item_' + index}>
                    <img className="img-fluid w-75" src={item.img_url}></img>
                </div>
            )
        }

        if (window.innerWidth < 768) {
            return (<>
                <Slider
                    className="partner-slider"
                    list={list}
                    renderItem={(item, index) => (
                        <div className="partner-slider-item">
                            <img className="img-fluid w-100" src={item.img_url}></img>
                        </div>
                    )}
                    overrideConfig={{
                        slidesPerView: 1
                    }}
                />
            </>)
        }
        let shop_items = [];
        list.forEach((item, index) => {
            shop_items.push(renderPartnerItemDesktop(item, index))
        })

        return (
            <div className="row justify-content-center">
                <div className="col-md-9">
                    <div className="row">
                        {shop_items}
                    </div>
                </div>
            </div>
        );
    }

    function renderShopItems(list) {
        if (!list || list.length === 0) return null;

        const renderShopItemDesktop = (item, index) => {
            return (
                <div className="col-md-3 mb-3 mb-md-4" key={'shop_item_' + index}>
                    <Link to={NAVIGATION.SHOP + '/' + item.id} className="shop-item">
                        <div className="item-image">
                            <img src={`/public_assets/images/shop-item/${item.id}.jpg`} alt="" className="img-fluid w-100"></img>
                        </div>
                        <div className="item-info">
                            <h4 className="item-name">{item.name}</h4>
                            <p className="item-unit" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.unit) }}></p>
                        </div>
                    </Link>
                </div>
            )
        }

        if (window.innerWidth < 768) {
            return (<>
                <Slider
                    className="shop-slider"
                    list={list}
                    renderItem={(item, index) => (
                        <div className="shop-slider-item-wrapper">
                            <Link to={NAVIGATION.SHOP + '/' + item.id} className="shop-item" key={'shop_item_' + index}>
                                <div className="item-image">
                                    <img src={`/public_assets/images/shop-item/${item.id}.jpg`} alt="" className="img-fluid w-100"></img>
                                </div>
                                <div className="item-info">
                                    <h4 className="item-name">{item.name}</h4>
                                    <p className="item-unit" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.unit) }}></p>
                                </div>
                            </Link>
                        </div>
                    )}
                    overrideConfig={{
                        slidesPerView: 1
                    }}
                />
                <div className="text-center">
                    <Link to={NAVIGATION.SHOP} className="btn btn-lg btn-primary btn-rounded shadow px-5 mt-4"><i className="fa-regular fa-sharp fa-shopping-bag"></i>Xem thêm sản phẩm</Link>
                </div>
            </>)
        }
        let shop_items = [];
        list.slice(0, 12).forEach((item, index) => {
            shop_items.push(renderShopItemDesktop(item, index))
        })

        return (
            <div className="row">
                {shop_items}
                <div className="col-12 text-center">
                    <Link to={NAVIGATION.SHOP} className="btn btn-lg btn-primary btn-rounded shadow px-5 mt-4"><i className="fa-regular fa-sharp fa-shopping-bag"></i>Xem thêm sản phẩm</Link>
                </div>
            </div>
        );
    }

    function renderBranchItems(list, limit) {
        if (!list || list.length === 0) return null;

        const renderBranchItemDesktop = (item, index) => {
            return (
                <div className="col-md-6 mb-3 mb-md-4" key={'branch_item_' + index}>
                    <div className="branch-item">
                        <div className="item-icon">
                            <i className="fa-regular fa-sharp fa-store fa-2x"></i>
                        </div>
                        <div className="item-info">
                            <h4 className="item-name">{item.name}</h4>
                            <p className="item-address" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.address_full) }}></p>
                        </div>
                        <div className="text-end align-self-end">
                            <a href={`https://maps.google.com/maps?q=${item.latitude},${item.longitude}`} target="_blank" className="btn btn-sm btn-outline-primary btn-rounded px-3 mt-2"><i className="fa-regular fa-sharp fa-circle-location-arrow"></i>Mở bản đồ</a>
                        </div>
                    </div>
                </div>
            )
        }

        if (window.innerWidth < 768) {
            return (<>
                <Slider
                    className="branch-slider"
                    list={list}
                    renderItem={(item, index) => (
                        <div className="branch-slider-item-wrapper">
                            <div className="branch-item">
                                <div className="item-icon">
                                    <i className="fa-regular fa-sharp fa-store fa-lg"></i>
                                </div>
                                <div className="item-info">
                                    <h4 className="item-name">{item.name}</h4>
                                    <p className="item-address" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.address) }}></p>
                                </div>
                            </div>
                        </div>
                    )}
                    overrideConfig={{
                        slidesPerView: 1
                    }}
                />
                {
                    limit && limit < list.length
                    ? <div className="text-center">
                        <button className="btn btn-lg btn-outline-light btn-rounded px-5 mt-4" data-bs-toggle="modal" data-bs-target="#branchesModal"><i className="fa-regular fa-sharp fa-store"></i>Xem tất cả cửa hàng</button>
                    </div>
                    : ''
                }
            </>)
        }
        let shop_items = [];
        if (limit && limit < list.length) {
            list.slice(0, limit).forEach((item, index) => {
                shop_items.push(renderBranchItemDesktop(item, index))
            })
        } else {
            list.forEach((item, index) => {
                shop_items.push(renderBranchItemDesktop(item, index))
            })
        }

        return (
            <div className="row">
                {shop_items}
                {
                    limit && limit < shop_item.length
                    ? <div className="col-12 text-center">
                        <button className="btn btn-lg btn-outline-light btn-rounded px-5 mt-4" data-bs-toggle="modal" data-bs-target="#branchesModal"><i className="fa-regular fa-sharp fa-store"></i>Xem tất cả cửa hàng</button>
                    </div>
                    : ''
                }
            </div>
        );
    }

    function renderAllBranchItems(list) {
        if (!list || list.length === 0) return null;

        const renderBranchItemDesktop = (item, index) => {
            return (
                <div className="col-md-6 mb-3 mb-md-4" key={'branch_item_' + index}>
                    <div className="branch-item">
                        <div className="item-icon">
                            <i className="fa-regular fa-sharp fa-store fa-2x"></i>
                        </div>
                        <div className="item-info">
                            <h4 className="item-name">{item.name}</h4>
                            <p className="item-address" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.address_full) }}></p>
                        </div>
                        <div className="text-end align-self-end">
                            <a href={`https://maps.google.com/maps?q=${item.latitude},${item.longitude}`} target="_blank" className="btn btn-sm btn-outline-primary btn-rounded px-3 mt-2"><i className="fa-regular fa-sharp fa-circle-location-arrow"></i>Mở bản đồ</a>
                        </div>
                    </div>
                </div>
            )
        }
        let shop_items = [];
        list.forEach((item, index) => {
            shop_items.push(renderBranchItemDesktop(item, index))
        })

        return (
            <div className="row">
                {shop_items}
            </div>
        );
    }

    function renderNewsItem(list) {
        if (!list || list.length === 0) return null;

        const renderNewsItemDesktop = (item, index) => {
            return (
                <div className="col-md-3 mb-3 mb-md-4" key={'news_item_' + index}>
                    <a href={item.url} target="_blank" className="news-item">
                        <div className="article-logo">
                            <img src={item.logo_url} alt="" className="img-fluid w-100"></img>
                        </div>
                        <div className="article-image">
                            <img src={item.img_url} alt="" className="img-fluid w-100"></img>
                        </div>
                        <div className="article-info">
                            <h4 className="article-title" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                            <p className="article-description" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.description) }}></p>
                        </div>
                    </a>
                </div>
            )
        }

        if (window.innerWidth < 768) {
            return (<>
                <Slider
                    className="news-slider"
                    list={list}
                    renderItem={(item, index) => (
                        <div className="news-slider-item-wrapper">
                            <a href={item.url} target="_blank" className="news-item">
                                <div className="article-logo">
                                    <img src={item.logo_url} alt="" className="img-fluid w-100"></img>
                                </div>
                                <div className="article-image">
                                    <img src={item.img_url} alt="" className="img-fluid w-100"></img>
                                </div>
                                <div className="article-info">
                                    <h4 className="article-title">{item.title}</h4>
                                    <p className="article-description" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.description) }}></p>
                                </div>
                            </a>
                        </div>
                    )}
                    overrideConfig={{
                        slidesPerView: 1
                    }}
                />
            </>)
        }
        let articles = [];
        list.forEach((item, index) => {
            articles.push(renderNewsItemDesktop(item, index))
        })

        return (
            <div className="row">
                {articles}
            </div>
        );
    }

    return (
        <ScreenWithNavBarAndFooter className="page-home">
            <section className="page-section home-header">
                <Slider
                    className="home-header-slider"
                    list={home_header_slides}
                    renderItem={(item, index) => (
                        <div className="home-header-slider-item" style={{ backgroundImage: 'url(' + item + ')' }}>
                        </div>
                    )}
                    overrideConfig={{
                        slidesPerView: 1
                    }}
                />
            </section>
            <section id="about" className="page-section about-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/section-2/section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6">
                            <div className="row justify-content-center">
                                <div className="col-md-10 d-flex flex-column gap-4 align-items-center text-center">
                                    <img className="img-fluid w-75" src="https://w.ladicdn.com/s750x500/60f3d39942e3210012c90587/group-113-20240101041802-8h4gw.png" alt=""></img>
                                    <div className="ratio ratio-16x9">
                                        <iframe src="https://www.youtube.com/embed/ngKNGsenRSc?si=oPFnLjCdARUKW8ML" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    </div>
                                    <a className="btn btn-lg btn-outline-light py-2">
                                        <img className="img-fluid me-2 icon-locamos" src="/public_assets/images/locamos-icon.svg" width={40} height={40}></img>Profile LocaMart
                                    </a>
                                    <div className="about-section-social-links d-flex flex-row gap-4">
                                        <a className="btn btn-icon p-0 text-white" href="#" target="_blank"><i className="fa-brands fa-3x fa-square-facebook"></i></a>
                                        <a className="btn btn-icon p-0 text-white" href="#" target="_blank"><i className="fa-brands fa-3x fa-youtube"></i></a>
                                        <a className="btn btn-icon p-0 text-white" href="#" target="_blank"><i className="fa-brands fa-3x fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="sec-header">
                                <h2 className="sec-title desktop">Về LocaMart</h2>
                            </div>
                            <div className="sec-content">
                                <p className="mb-0">
                                    Ra đời với <b>sứ mệnh "Nâng tầm nông sản Việt"</b>, LocaMart mong muốn dùng <b>công nghệ</b> để thúc đẩy sự phát triển cả về chất và lượng cho lĩnh vực nông nghiệp Việt, từ đó đưa nông sản Việt ra khắp Việt Nam và vươn ra toàn cầu.<br />
                                    <br />
                                    <b>Hưởng ứng chương trình OCOP của chính phủ</b>, đồng thời mong muốn góp phần giải quyết thách thức đầu ra cho các sản phẩm nông - đặc sản, mở ra một kênh phân phối mới không chỉ tại Việt Nam mà còn trên toàn cầu, LocaMart định vị mình trở thành <b>chuỗi siêu thị</b> phân phối các sản phẩm OCOP chất lượng cao và rau củ quả sạch, đem lại những sản phẩm <b>Xanh - Tươi - Sạch</b> cho mọi gia đình Việt.<br />
                                    <br />
                                    Để thực hiện được sứ mệnh của mình, LocaMart tập trung phân phối các sản phẩm <b>OCOP chất lượng cao</b> từ 3<i className="fa-solid fa-sharp fa-star"></i> trở lên. Đồng thời, thay vì chỉ tập trung phân phối sản phẩm, LocaMart khai thác các <b>câu chuyện thương hiệu - câu chuyện sản phẩm</b>, tập trung <b>cải tiến bao bì, mẫu mã sản phẩm...</b> để lan tỏa <b>nét đẹp văn hóa</b> từng vùng miền tới tận tay người tiêu dùng.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="story" className="page-section story-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Câu chuyện về chúng tôi</h2>
                    </div>
                    <div className="sec-content">
                        <div className="story-letter-wrapper">
                            <span className="letter-quotes open">
                                <i className="fa-light fa-sharp fa-quote-left text-info"></i>
                            </span>
                            <span className="letter-quotes close">
                                <i className="fa-light fa-sharp fa-quote-right text-info"></i>
                            </span>
                            <p className="mb-0">
                                <b>Kính gửi Quý Nhà đầu tư, Quý Đối tác, Khách hàng!</b><br />
                                <br />
                                Tiếp nối triết lý <b>&quot;Công nghệ vị nhân sinh&quot;</b> của LocaMos, LocaMart ra đời với sứ mệnh <b>&quot;Nâng tầm nông sản Việt&quot;</b>, đưa nông - đặc sản Việt đi khắp Việt Nam và vươn ra toàn cầu. LocaMart là chuỗi siêu thị phân phối sản phẩm OCOP chất lượng cao và các sản phẩm nông - đặc sản, hải sản… sạch.<br />
                                <br />
                                <b>Nông nghiệp và xuất khẩu nông sản từ lâu đã đóng một vai trò quan trọng trong nền kinh tế của mỗi quốc gia.</b> Tính đến thời điểm hiện tại, Việt Nam chúng ta đã xuất khẩu nông sản tới gần <b>200 quốc gia và vùng lãnh thổ</b>. Điều đó chứng minh rằng, nền nông nghiệp Việt Nam hoàn toàn có đủ tiềm lực để cung ứng thực phẩm đảm bảo vệ sinh an toàn và đáp ứng nhu cầu xuất khẩu toàn cầu.<br />
                                <br />
                                <b>Đẩy mạnh ứng dụng công nghệ, đổi mới sáng tạo</b> vào các hoạt động sản xuất, chế biến, đóng gói, phân phối, xuất khẩu… của nền nông nghiệp, chúng ta có thể tạo ra những cải tiến tích cực cho giá trị nông sản Việt trên thị trường quốc tế, đồng thời giúp giải quyết công ăn việc làm, nâng cao đời sống của người dân và thúc đẩy phát triển kinh tế của đất nước.<br />
                                <br />
                                Trong bối cảnh nông nghiệp Việt đang trên đà khởi sắc, để thúc đẩy xuất khẩu nông sản mạnh mẽ hơn nữa, chương trình OCOP (Mỗi xã một sản phẩm) đã được Chính phủ thông qua và đưa vào triển khai, từ đó tạo ra những cải tiến đáng kể về chất và số lượng nông sản, hải sản... Ngay khi hiệu quả sản xuất và các nguồn cung tăng mạnh, bài toán cần chung tay ngay lúc này chính là: <b>Thúc đẩy đầu ra cho nông sản, đặc sản từ nội địa tới xuất khẩu toàn cầu.</b><br />
                                <br />
                                <b>Với triết lý Công nghệ vị nhân sinh, LocaMos đã và đang cho ra đời nhiều giải pháp - sản phẩm vì cộng đồng, phục vụ cho cộng đồng và do cộng đồng làm chủ, trong đó có:<br />
                                - Giải pháp Marketing Thế hệ mới;<br />
                                - Giải pháp phát triển Cộng đồng số LocaMos;<br />
                                - Ứng dụng LocaMos (là nền tảng kết nối và thực thi các giải pháp, sản phẩm trong hệ sinh thái của LocaMos);<br />
                                - Trung tâm gặp gỡ LocaCafe - Meeting Central;<br />
                                - Dự án LocaMart - Nâng tầm nông sản Việt.<br />
                                <br />
                                Tính đến nay, giải pháp Phát triển Cộng đồng số LocaMos đã có 1,5 triệu người dùng trong nước và quốc tế và đã có gần 5.000 doanh nghiệp ứng dụng thành công giải pháp Marketing Thế hệ mới vào hoạt động kinh doanh của mình.<br />
                                <br />
                                Đứng trước bối cảnh cần nhanh chóng đẩy mạnh phát triển nền nông nghiệp nước nhà, LocaMos cùng Cộng đồng số LocaMos có cùng một trăn trở, khát vọng được hưởng ứng, đồng hành cùng chương trình OCOP bằng việc ứng dụng công nghệ và các giải pháp vị nhân sinh của LocaMos trong lĩnh vực này.<br />
                                <br />
                                Từ việc ứng dụng công nghệ để đưa nông - đặc sản Việt ra toàn cầu, cộng đồng LocaMos toàn cầu nói riêng và tất cả mọi người nói chung có thể thực thi sứ mệnh &quot;Nâng tầm nông sản Việt&quot; và đồng thời cộng đồng, người tiêu dùng tại Việt Nam và trên toàn cầu cũng được hưởng thụ tinh hoa của nền nông nghiệp Việt Nam. Đó cũng chính là lý do LocaMart ra đời.</b>
                            </p>
                        </div>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 order-2 order-md-1">
                                <p>
                                    LocaMart được kỳ vọng sẽ trở thành đại diện - một minh chứng cho giải pháp Marketing Thế hệ mới của LocaMos, là đại diện của LocaMos trên hành trình thực thi triết lý, sứ mệnh và nhiệm vụ chung tay phát triển nền nông nghiệp nước nhà.<br />
                                    <br />
                                    Để thực hiện được hành trình và sứ mệnh to lớn này, chúng tôi biết rõ không ai có thể đi một mình. Bởi vậy, LocaMos - xin đại diện cho LocaMart - kêu gọi sự đồng hành, hỗ trợ của Cộng đồng số LocaMos, các Nhà đầu tư, quý Đối tác, Khách hàng bằng cách hợp tác đầu tư, kinh doanh, phát triển hệ thống siêu thị LocaMart của chúng tôi.<br />
                                    <br />
                                    Đồng hành cùng LocaMart, chính là một cách để các doanh nghiệp cùng chung tay thúc đẩy nền kinh tế đất nước Việt Nam!<br />
                                    <br />
                                    LocaMos rất mong đợi nhận được sự đồng hành của Quý Đối tác. Với thiện chí hợp tác cùng phát triển, Công ty Cổ phần Công nghệ LocaMos và Công ty Cổ phần LocaMart Việt Nam rất tin tưởng vào sự thành công tốt đẹp và lâu dài trong quá trình hợp tác giữa chúng ta.<br />
                                    <br />
                                    Xin trân trọng cảm ơn!
                                </p>
                            </div>
                            <div className="col-md-6 order-1 order-md-2 text-center">
                                <div className="story-image mb-4 mb-md-0">
                                    <img className="img-fluid w-100" src="https://w.ladicdn.com/s750x750/60f3d39942e3210012c90587/nhan-vat-20240101044602-6djsk.png" alt=""></img>
                                    <img className="story-card-flying-1" src="https://w.ladicdn.com/s450x450/60f3d39942e3210012c90587/layer-76-20240101044651-d_o09.png" alt=""></img>
                                    <img className="story-card-flying-2" src="https://w.ladicdn.com/s450x500/60f3d39942e3210012c90587/layer-75-20240101044639-quw5v.png" alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="highlight" className="page-section highlight-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-6 mb-4">
                                        <div className="highlight-item">
                                            <img className="highlight-icon" src="https://w.ladicdn.com/s400x400/60f3d39942e3210012c90587/layer-55-20240101050324-uuy1w.png" alt=""></img>
                                            <div>
                                                <h5>SỨ MỆNH</h5>
                                                <p>Nâng tầm nông sản Việt</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="highlight-item">
                                            <img className="highlight-icon" src="https://w.ladicdn.com/s400x400/60f3d39942e3210012c90587/layer-56-20240101050533-3ogpm.png" alt=""></img>
                                            <div>
                                                <h5>TẦM NHÌN</h5>
                                                <p>Trở thành chuỗi siêu thị Vị nhân sinh đầu tiên tại Việt Nam và nâng tầm nông sản Việt xuất khẩu toàn cầu</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="highlight-item">
                                            <img className="highlight-icon" src="https://w.ladicdn.com/s400x400/60f3d39942e3210012c90587/layer-59-20240101050553-gtwwa.png" alt=""></img>
                                            <div>
                                                <h5>LÝ TƯỞNG</h5>
                                                <p>Cùng cộng đồng LocaMos tại Việt Nam và toàn cầu mang nông - đặc sản tinh hoa Việt vươn tầm thế giới</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="highlight-item">
                                            <img className="highlight-icon" src="https://w.ladicdn.com/s400x400/60f3d39942e3210012c90587/layer-58-20240101050614-6cjdq.png" alt=""></img>
                                            <div>
                                                <h5>TRIẾT LÝ VẬN HÀNH</h5>
                                                <p>Công nghệ vị nhân sinh</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="highlight-item">
                                            <div className="d-flex flex-column w-100">
                                                <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center gap-4">
                                                    <div className="text-center">
                                                        <img className="highlight-icon me-0 mb-3" src="https://w.ladicdn.com/s400x400/60f3d39942e3210012c90587/layer-54-20240101051151-tfine.png" alt=""></img>
                                                        <h5>MỤC TIÊU</h5>
                                                    </div>
                                                    <div className="row justify-content-center flex-fill text-center">
                                                        <div className="col-6 col-md-4">
                                                            <p>
                                                                Phát triển<br />
                                                                <span>5.000</span><br />
                                                                điểm bán hàng trên toàn quốc
                                                            </p>
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            <p>
                                                                Phát triển<br />
                                                                <span>20.000</span><br />
                                                                điểm trung tâm <br className="desktop" />thương mại số
                                                            </p>
                                                        </div>
                                                        <div className="col-6 col-md-4 mt-4 mt-md-0">
                                                            <p>
                                                                Phát triển<br />
                                                                <span>10.000</span><br />
                                                                doanh nghiệp về nông hải sản OCOP
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="highlight-target-steps text-center mt-4">
                                                    <div className="highlight-line mb-3">
                                                        <div className="line-arrow">
                                                            <i className="fa-regular fa-sharp fa-chevron-right text-info"></i>
                                                        </div>
                                                        <div className="line-dotted"></div>
                                                        <div className="line-arrow">
                                                            <i className="fa-regular fa-sharp fa-chevron-right text-info"></i>
                                                        </div>
                                                        <div className="line-dotted"></div>
                                                        <div className="line-arrow">
                                                            <i className="fa-regular fa-sharp fa-chevron-right text-info"></i>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-around">
                                                        <div className="col-md-3">
                                                            <h6>Ngắn hạn</h6>
                                                            <p>Tập trung phân phối hàng OCOP 3, 4, 5 sao đã có thương hiệu và được chứng nhận đạt tiêu chuẩn tại các địa phương</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <h6>Trung hạn</h6>
                                                            <p>Xây dựng tiêu chuẩn và truy xuất nguồn gốc cho tất cả các mặt hàng OCOP trên nền tảng công nghệ và hệ thống kênh phân phối của LocaMart</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <h6>Dài hạn</h6>
                                                            <p>Xuất khẩu trên kênh phân phối LocaMart tại các thị trường nước ngoài mà LocaMos có mặt</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="partner" className="page-section partner-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Đối tác của LocaMart</h2>
                    </div>
                    <div className="sec-content">
                        { renderPartnerItem(partner) }
                    </div>
                </div>
            </section>
            {/* <section id="shop" className="page-section shop-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Các sản phẩm LocaMart cung cấp</h2>
                        <p className="sec-description">LocaMart cung cấp đa dạng các loại thực phẩm sạch tiêu chuẩn <b>VietGAP</b> và đặc sản <b>OCOP</b> cho người tiêu dùng trên khắp Việt Nam.</p>
                    </div>
                    <div className="sec-content">
                        { renderShopItems(shop_item) }
                    </div>
                </div>
            </section> */}
            <section id="branches" className="page-section branches-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/section-1/section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Hệ thống phân phối</h2>
                        <p className="sec-description">Hệ thống phân phối chuyên nghiệp và rộng khắp toàn quốc</p>
                    </div>
                    <div className="sec-content">
                        { renderBranchItems(branches, 8) }
                    </div>
                </div>

                {/* branchesModal */}
                <div className="modal fade" id="branchesModal" tabIndex="-1" aria-labelledby="branchesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="branchesModalLabel">Hệ thống phân phối</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                { renderAllBranchItems(branches) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="news" className="page-section news-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Báo chí nói về LocaMart</h2>
                    </div>
                    <div className="sec-content">
                        { renderNewsItem(news) }
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

export {
    Dashboard
}