import React, { useState, useEffect } from 'react';
import "./Account.scss"

import { connect } from 'react-redux';
import { authActions, alertActions } from 'app/redux/actions';

import { AccountLayout } from ".";
import {
    Column,
    Table,
    ExpandedState,
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    getExpandedRowModel,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table'

import { membershipService } from "services";

import { Modal } from "bootstrap";

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    userUpdate: authActions.user_update,
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}

const SalesReport = connect(mapStateToProps, actionCreators)(props => {
    const { user } = props.auth;
    const { lang, locale_string } = props.pageInfo;
    
    return (
        <AccountLayout className="page-content ps-0">
            <ul className="nav nav-tabs account-report-tabs ps-0 ps-md-4" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="referrals-tab" data-bs-toggle="tab" data-bs-target="#referrals-tab-pane" type="button" role="tab" aria-controls="referrals-tab-pane" aria-selected="false">Báo cáo hệ thống</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="commissions-tab" data-bs-toggle="tab" data-bs-target="#commissions-tab-pane" type="button" role="tab" aria-controls="commissions-tab-pane" aria-selected="true">Lịch sử hoa hồng</button>
                </li>
            </ul>
            <div className="tab-content account-report-tab-content ps-0 ps-md-4" id="myTabContent">
                <div className="tab-pane fade show active" id="referrals-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                    <div className="page-section pt-4">
                        <div className="sec-header">
                            <h4 className="sec-title">Báo cáo hệ thống</h4>
                        </div>
                        <div className="sec-content">
                            <ReferralsReport />
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="commissions-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                    <div className="page-section pt-4">
                        <div className="sec-header">
                            <h4 className="sec-title">Lịch sử hoa hồng</h4>
                        </div>
                        <div className="sec-content">
                            <CommissionReport />
                        </div>
                    </div>
                </div>
            </div>
        </AccountLayout>
    )
})

const CommissionReport = connect(mapStateToProps, actionCreators)(props => {
    const { user } = props.auth;
    const { lang, locale_string } = props.pageInfo;

    const [dataQuery, setDataQuery] = useState(null);
    const [loading, setLoading] = useState(true);

    const columns = React.useMemo(
        () => [
            {
                header: 'Thời gian',
                accessorKey: 'created_at',
                cell: info => info.getValue()
            },
            {
                header: 'Người mua',
                accessorKey: 'purchaser_full_name',
                cell: info => info.getValue()
            },
            {
                header: 'SĐT',
                accessorKey: 'purchaser_phone',
                cell: info => info.getValue()
            },
            {
                header: 'Doanh thu',
                accessorKey: 'bill_amount_original',
                cell: info => info.getValue().toLocaleString(locale_string) + ' VNĐ'
            },
            {
                header: 'Số tiền thanh toán',
                accessorKey: 'bill_amount',
                cell: info => info.getValue().toLocaleString(locale_string) + ' VNĐ'
            },
            {
                header: 'Số D-LocaVoucher sử dụng',
                accessorKey: 'bill_dvoucher',
                cell: info => info.getValue() + ' D-LocaVoucher'
            },
            {
                header: 'Loại hoa hồng',
                accessorKey: 'commission_level',
                cell: info => info.getValue()
            },
            {
                header: 'Tỉ lệ hoa hồng',
                accessorKey: 'percentage',
                cell: info => info.getValue() + '%'
            },
            {
                header: 'Hoa hồng',
                accessorKey: 'commission_amount',
                cell: info => info.getValue() + ' VNĐ'
            },
            {
                header: 'Hoa hồng D-LocaVoucher',
                accessorKey: 'commission_dvoucher',
                cell: info => info.getValue() + ' D-LocaVoucher'
            },
        ],
        []
    )

    const [{ pageIndex, pageSize }, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    })

    const defaultData = React.useMemo(() => [], [])

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const table = useReactTable({
        data: dataQuery?.list ?? defaultData,
        columns,
        pageCount: dataQuery?.total_page ?? -1,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
        debugTable: true,
    })

    useEffect(() => {
        membershipService.fetch_user_commissions({
            token: user.token,
            page: pageIndex,
            size: pageSize
        }).then(data => {
            setLoading(false);

            if (typeof data === 'object') {
                setDataQuery(data);
            } else {
                setDataQuery([]);
            }
        })

        return () => {
        }
    }, [user.token, pageIndex, pageSize])

    return (<>
        <div className="table-responsive">
            <table className="table table-bordered table-report">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (<>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </>)}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>

        {loading ? <p>Loading...</p> : ''}
        
        <div className="table-report-footer mb-2">
            <div className="table-report-footer-group">
                <button
                    className="btn btn-sm btn-secondary btn-icon"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                >
                    <i className="fa-light fa-sharp fa-chevrons-left"></i>
                </button>
                <button
                    className="btn btn-sm btn-secondary btn-icon"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    <i className="fa-light fa-sharp fa-chevron-left"></i>
                </button>
                <button
                    className="btn btn-sm btn-secondary btn-icon"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    <i className="fa-light fa-sharp fa-chevron-right"></i>
                </button>
                <button
                    className="btn btn-sm btn-secondary btn-icon"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                >
                    <i className="fa-light fa-sharp fa-chevrons-right"></i>
                </button>
                <br />
            </div>
            <div className="table-report-footer-group">
                <span>Trang</span>
                <strong>
                    {table.getState().pagination.pageIndex + 1} của {table.getPageCount()}
                </strong>
                <span>-</span>
                <span>Đến trang:</span>
                <div>
                    <input
                        type="number"
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                        }}
                        className="form-control form-control-sm"
                    />
                </div>
            </div>
            <div className="table-report-footer-group">
                <span className="text-nowrap">Hiển thị</span>
                <select
                    className="form-select form-select-sm"
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    </>)
})

const ReferralsReport = connect(mapStateToProps, actionCreators)(props => {
    const { user } = props.auth;
    const { lang, locale_string } = props.pageInfo;

    const [dataQuery, setDataQuery] = useState(null);
    const [loading, setLoading] = useState(true);
    const [referralId, setReferralId] = useState(null);
    const [searchId, setSearchId] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    const columns = React.useMemo(
        () => [
            {
                // header: 'User ID',
                accessorKey: 'user_id',
                header: ({ table }) => (
                    <>
                        <button
                            className="btn btn-icon btn-table-expand p-0 me-2"
                            {...{
                                onClick: table.getToggleAllRowsExpandedHandler(),
                            }}
                        >
                            {table.getIsAllRowsExpanded() ? <i className="fa-regular fa-sharp fa-square-minus"></i> : <i className="fa-regular fa-sharp fa-square-plus"></i>}
                        </button>
                        User ID
                    </>
                ),
                // cell: info => info.getValue()
                cell: ({ row, getValue }) => (
                    <div
                        style={{
                            // Since rows are flattened by default,
                            // we can use the row.depth property
                            // and paddingLeft to visually indicate the depth
                            // of the row
                            paddingLeft: `${row.depth * 1.05}rem`,
                        }}
                    >
                        <>
                            {row.getCanExpand() ? (
                                <button
                                    className="btn btn-icon btn-table-expand p-0 me-2"
                                    {...{
                                        onClick: row.getToggleExpandedHandler(),
                                        style: { cursor: 'pointer' },
                                    }}
                                >
                                    {row.getIsExpanded() ? <i className="fa-regular fa-sharp fa-square-minus"></i> : <i className="fa-regular fa-sharp fa-square-plus"></i>}
                                </button>
                            ) : (
                                <i className="fa-solid fa-user me-1"></i>
                            )}
                            {getValue()}
                        </>
                    </div>
                ),
            },
            {
                header: 'Họ và tên',
                accessorKey: 'full_name',
                cell: info => info.getValue()
            },
            {
                header: 'SĐT',
                accessorKey: 'phone',
                cell: info => info.getValue()
            },
            {
                header: 'Mức quản lý',
                accessorKey: 'type',
                cell: info => info.getValue()
            },
            {
                header: 'Doanh thu',
                accessorKey: 'total_revenue',
                cell: info => info.getValue().toLocaleString(locale_string) + ' VNĐ'
            },
            {
                header: 'Số tiền hoa hồng',
                accessorKey: 'total_commission',
                cell: info => info.getValue().toLocaleString(locale_string) + ' VNĐ'
            },
            {
                header: 'Số D-LocaVoucher',
                accessorKey: 'total_d_voucher_commission',
                cell: info => info.getValue()
            },
            {
                header: 'Hành động',
                accessorKey: 'id',
                cell: info => <button onClick={() => { setReferralId(info.getValue()) }} className="btn btn-sm btn-outline-primary btn-view-referral"><i className="fa-solid fa-sharp fa-eye"></i>Xem</button>
            },
        ],
        []
    )

    const [{ pageIndex, pageSize }, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    })

    const defaultData = React.useMemo(() => [], [])

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const [expanded, setExpanded] = React.useState({})

    const table = useReactTable({
        data: dataQuery?.first_list ?? defaultData,
        columns,
        pageCount: dataQuery?.total_page ?? -1,
        state: {
            expanded,
        },
        onExpandedChange: setExpanded,
        getSubRows: row => row.second_list,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true,
    })

    useEffect(() => {
        membershipService.fetch_user_referrals({
            token: user.token,
            page: pageIndex,
            size: pageSize,
            user_id: searchId,
            keyword: searchKeyword
        }).then(data => {
            setLoading(false);

            if (typeof data === 'object') {
                setDataQuery(data);
            } else {
                setDataQuery([]);
            }
        })

        return () => {
        }
    }, [user.token, pageIndex, pageSize, searchId, searchKeyword])    

    return (<>
        <div className="table-report-filter d-flex flex-column flex-md-row gap-2 mb-1">
            <div className="mb-2">
                <input type="text" id="searchId" className="form-control form-control-sm" placeholder="Tìm Mã giới thiệu" value={searchId} onChange={e => { setSearchId(e.target.value) }}></input>
            </div>
            <div className="mb-2">
                <input type="text" id="searchKeyword" className="form-control form-control-sm" placeholder="Tìm Họ tên" value={searchKeyword} onChange={e => { setSearchKeyword(e.target.value) }}></input>
            </div>
            <div className="mb-2">
                <button className="btn btn-sm btn-outline-primary" onClick={() => { setSearchId(''); setSearchKeyword(''); }}><i className="fa-regular fa-sharp fa-filter-slash"></i>Xóa tìm kiếm</button>
            </div>
        </div>
        <div className="table-responsive">
            <table className="table table-bordered table-report">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (<>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </>)}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>

        {loading ? <p>Loading...</p> : ''}
        
        <div className="table-report-footer mb-2">
            <div className="table-report-footer-group">
                <button
                    className="btn btn-sm btn-secondary btn-icon"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                >
                    <i className="fa-light fa-sharp fa-chevrons-left"></i>
                </button>
                <button
                    className="btn btn-sm btn-secondary btn-icon"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    <i className="fa-light fa-sharp fa-chevron-left"></i>
                </button>
                <button
                    className="btn btn-sm btn-secondary btn-icon"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    <i className="fa-light fa-sharp fa-chevron-right"></i>
                </button>
                <button
                    className="btn btn-sm btn-secondary btn-icon"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                >
                    <i className="fa-light fa-sharp fa-chevrons-right"></i>
                </button>
                <br />
            </div>
            <div className="table-report-footer-group">
                <span>Trang</span>
                <strong>
                    {table.getState().pagination.pageIndex + 1} của {table.getPageCount()}
                </strong>
                <span>-</span>
                <span>Đến trang:</span>
                <div>
                    <input
                        type="number"
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                        }}
                        className="form-control form-control-sm"
                    />
                </div>
            </div>
            <div className="table-report-footer-group">
                <span className="text-nowrap">Hiển thị</span>
                <select
                    className="form-select form-select-sm"
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
        <ReferralRevenue referralId={referralId} setReferralId={setReferralId} />   
    </>)
})

const ReferralRevenue = connect(mapStateToProps, actionCreators)(props => {
    const { referralId, setReferralId } = props;
    const { user } = props.auth;
    const { lang, locale_string } = props.pageInfo;

    const [filterDateFrom, setFilterDateFrom] = useState('');
    const [filterDateTo, setFilterDateTo] = useState('');

    const [dataQuery, setDataQuery] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const [referralInfoModal, setReferralInfoModal] = useState(null);

    const columns = React.useMemo(
        () => [
            {
                header: 'Thời gian',
                accessorKey: 'created_at',
                cell: info => info.getValue()
            },
            {
                header: 'Người mua',
                accessorKey: 'purchaser_full_name',
                cell: info => info.getValue()
            },
            // {
            //     header: 'Doanh thu',
            //     accessorKey: 'bill_amount_original',
            //     cell: info => info.getValue().toLocaleString(locale_string) + ' VNĐ'
            // },
            {
                header: 'Số tiền thanh toán',
                accessorKey: 'bill_amount',
                cell: info => info.getValue().toLocaleString(locale_string) + ' VNĐ'
            },
            {
                header: 'Số D-LocaVoucher sử dụng',
                accessorKey: 'bill_dvoucher',
                cell: info => info.getValue() + ' D-LocaVoucher'
            },
            {
                header: 'Loại hoa hồng',
                accessorKey: 'commission_level',
                cell: info => info.getValue()
            },
            {
                header: 'Tỉ lệ hoa hồng',
                accessorKey: 'percentage',
                cell: info => info.getValue() + '%'
            },
            {
                header: 'Hoa hồng',
                accessorKey: 'commission_amount',
                cell: info => info.getValue() + ' VNĐ'
            },
            {
                header: 'Hoa hồng D-LocaVoucher',
                accessorKey: 'commission_dvoucher',
                cell: info => info.getValue() + ' D-LocaVoucher'
            },
        ],
        []
    )

    const [{ pageIndex, pageSize }, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    })

    const defaultData = React.useMemo(() => [], [])

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const table = useReactTable({
        data: dataQuery?.list ?? defaultData,
        columns,
        pageCount: dataQuery?.total_page ?? -1,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
        debugTable: true,
    })

    useEffect(() => {
        setReferralInfoModal(new Modal('#referralInfoModal'));

        let modal = document.getElementById('referralInfoModal');
        modal.addEventListener('hide.bs.modal', () => {
            setReferralId(null);
        })

        return () => {
        }
    }, [])

    useEffect(() => {
        if (referralId) {
            membershipService.fetch_referral_revenue(referralId, {
                token: user.token,
                page: pageIndex,
                size: pageSize,
                date_from: filterDateFrom,
                date_to: filterDateTo
            }).then(data => {
                console.log(data);
                setLoading(false);

                if (typeof data === 'object') {
                    setDataQuery(data);
                    referralInfoModal.show();
                } else {
                    setDataQuery([]);
                }
            })
        }

        return () => {
        }
    }, [referralId, filterDateFrom, filterDateTo]);

    return (<>
        {/* referralInfoModal */}
        <div className="modal fade" id="referralInfoModal" tabIndex="-1" aria-labelledby="referralInfoModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-fullscreen-lg-down modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="referralInfoModalLabel">Chi tiết doanh thu</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="table-report-filter d-flex flex-column flex-md-row gap-2 mb-1">
                            <div className="mb-2">
                                <input type="date" id="filterDateFrom" className="form-control form-control-sm" placeholder="Từ ngày" value={filterDateFrom} onChange={e => { setFilterDateFrom(e.target.value) }}></input>
                            </div>
                            <div className="mb-2">
                                <input type="date" id="filterDateTo" className="form-control form-control-sm" placeholder="Đến ngày" value={filterDateTo} onChange={e => { setFilterDateTo(e.target.value) }}></input>
                            </div>
                            <div className="mb-2">
                                <button className="btn btn-sm btn-outline-primary" onClick={() => { setFilterDateFrom(''); setFilterDateTo(''); }}><i className="fa-regular fa-sharp fa-filter-slash"></i>Xóa tìm kiếm</button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-report">
                                <thead>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => {
                                                return (
                                                    <th key={header.id} colSpan={header.colSpan}>
                                                        {header.isPlaceholder ? null : (<>
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                        </>)}
                                                    </th>
                                                )
                                            })}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows.map(row => {
                                        return (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <td key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        {loading ? <p>Loading...</p> : ''}
                        
                        <div className="table-report-footer mb-2">
                            <div className="table-report-footer-group">
                                <button
                                    className="btn btn-sm btn-secondary btn-icon"
                                    onClick={() => table.setPageIndex(0)}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    <i className="fa-light fa-sharp fa-chevrons-left"></i>
                                </button>
                                <button
                                    className="btn btn-sm btn-secondary btn-icon"
                                    onClick={() => table.previousPage()}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    <i className="fa-light fa-sharp fa-chevron-left"></i>
                                </button>
                                <button
                                    className="btn btn-sm btn-secondary btn-icon"
                                    onClick={() => table.nextPage()}
                                    disabled={!table.getCanNextPage()}
                                >
                                    <i className="fa-light fa-sharp fa-chevron-right"></i>
                                </button>
                                <button
                                    className="btn btn-sm btn-secondary btn-icon"
                                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                    disabled={!table.getCanNextPage()}
                                >
                                    <i className="fa-light fa-sharp fa-chevrons-right"></i>
                                </button>
                                <br />
                            </div>
                            <div className="table-report-footer-group">
                                <span>Trang</span>
                                <strong>
                                    {table.getState().pagination.pageIndex + 1} của {table.getPageCount()}
                                </strong>
                                <span>-</span>
                                <span>Đến trang:</span>
                                <div>
                                    <input
                                        type="number"
                                        defaultValue={table.getState().pagination.pageIndex + 1}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            table.setPageIndex(page)
                                        }}
                                        className="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div className="table-report-footer-group">
                                <span className="text-nowrap">Hiển thị</span>
                                <select
                                    className="form-select form-select-sm"
                                    value={table.getState().pagination.pageSize}
                                    onChange={e => {
                                        table.setPageSize(Number(e.target.value))
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
})

export {
    SalesReport
}