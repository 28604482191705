import { NAVIGATION } from './routes';

const BASE_API = 'https://api-cardbuyproduct.locamart.vn'
const BASE_API_LOCAMOS = 'https://api-dev.locameet.net/api'
const BASE_IMAGE = 'https://storage.googleapis.com/sanvaybds-dev'

const HOMEPAGE = 'https://temrau.locamart.vn'

const GOOGLE_CLIENT_ID = '300540511178-shk3khe8qb5el42lg0ccfj16vtq9cb64.apps.googleusercontent.com'
const APPLE_CLIENT_ID = 'com.locamosapp.client'
const APPLE_REDIRECT_URI = 'https://demo-membership.locamos.vn/login'

const SHIPPING_FEES_3KM = 20000

export {
    BASE_API,
    BASE_API_LOCAMOS,
    HOMEPAGE,
    NAVIGATION,
    BASE_IMAGE,
    GOOGLE_CLIENT_ID,
    APPLE_CLIENT_ID, APPLE_REDIRECT_URI,
    SHIPPING_FEES_3KM
}