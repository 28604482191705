import React from 'react';
import './Layout.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { NAVIGATION } from 'config';

import { pageInfoActions } from 'app/redux/actions';

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    changeLang: pageInfoActions.change_lang
}

const ScreenWithNavBar = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

        </div>
    )
}

const ScreenWithNavBarAndFooter = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

            {/* Footer */}
            <Footer />

        </div>
    )
}

const TopNavBar = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, changeLang } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, user } = props.auth;

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top shadow top-navbar">
            <div className="container-xl">
                <button className="navbar-toggler me-auto order-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                    <i className="far fa-bars"></i>
                </button>
                <Link className="navbar-brand order-2" to={NAVIGATION.DASHBOARD}>
                    <img src="/public_assets/images/header-logo.svg" alt="" height={40}></img>
                </Link>
                <div className="collapse navbar-collapse order-4 order-lg-3" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto me-auto pt-3 pt-md-0">
                        <li className="nav-item">
                            <a href={`${window.location.origin}#about`} className="nav-link">Về chúng tôi</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Sản phẩm dịch vụ
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="https://dapsonghoarong.locamart.vn" target="_blank">Quà Limited <span className="badge bg-danger">HOT</span></a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="https://temrau.locamart.vn" target="_blank">Tem Rau</a></li>
                                <li><a className="dropdown-item" href="https://hopqua.locamart.vn" target="_blank">Tinh hoa quà Doanh nghiệp Việt</a></li>
                                <li><a className="dropdown-item" href="#" target="_blank">Sản phẩm tại chuỗi LocaMart</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to={`${window.location.origin}#branches`} className="nav-link">Danh sách cửa hàng</Link>
                        </li>
                        <li className="nav-item">
                            <a href={`${window.location.origin}#news`} className="nav-link">Truyền thông</a>
                        </li>
                    </ul>
                </div>
                <ul className="navbar-nav ms-auto order-3 order-lg-4">
                    <li className="nav-item dropdown navbar-user-dropdown">
                        <a href="#contact" className="btn btn-primary px-5">Liên hệ</a>                 
                    </li>
                </ul>
            </div>
        </nav>
    )
})

const Footer = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <footer id="contact" className="site-footer">
            <div className="container-xl">
                <div className="row align-items-center">
                    <div className="col-md-6 text-center text-md-start">
                        {/* <img height={64} src="/public_assets/images/home/locamart-slogan.svg" alt="" className="mb-5 mb-md-0"></img> */}
                        <h3 className="mt-4 mb-3">Liên hệ với LocaMart</h3>
                        <ul className="footer-links site-footer-company-info">
                            <li>Địa chỉ: 249 Thuỵ Khuê, Phường Thuỵ Khuê, Quận Tây Hồ, Thành phố Hà Nội, Việt Nam</li>
                            {/* {pageInfo.address_line2 ? <li>{pageInfo.address_line2}</li> : null} */}
                            <li>Hotline: <a href={`tel:1900866677`}>1900 866 677</a></li>
                            <li>Email: <a href={`mailto:support@locamart.vn`}>support@locamart.vn</a></li>
                            <li><i className="fa-brands fa-fw fa-square-facebook me-2"></i><a href="https://www.facebook.com/locamartofficial">https://www.facebook.com/locamartofficial</a></li>
                            <li><i className="fa-brands fa-fw fa-youtube me-2"></i><a href="https://www.youtube.com/@LocaMart">https://www.youtube.com/@LocaMart</a></li>
                            <li><img src="/public_assets/images/social-icons/zalo.webp" className="me-1" width={20} height={16}></img> <a href="https://zalo.me/2820821147149288952">https://zalo.me/2820821147149288952</a></li>
                            {/* <li>Số giấy chứng nhận ĐKKD: {pageInfo.company_registration_number}</li>
                            <li>Người đại diện theo pháp luật: {pageInfo.company_representative_person}</li>
                            <li>Chức vụ: {pageInfo.company_representative_position}</li> */}
                        </ul>
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <a href="https://locamart.vn" className="text-white"><i className="fa-light fa-sharp fa-globe fa-2xl"></i></a>
                        <a href="tel:1900866677" target="_blank" className="text-white mx-5"><i className="fa-light fa-sharp fa-mobile fa-2xl"></i></a>
                        <a href="https://www.facebook.com/locamartofficial" target="_blank" className="text-white"><i className="fa-light fa-sharp fa-message-quote fa-2xl"></i></a>
                    </div>
                </div>
                <div className="row justify-content-center footer-policy-links mt-5">
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.VEGETABLE_CARD} className="btn btn-link text-white">Tem Rau</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.PRIVACY_POLICY} className="btn btn-link text-white">Chính sách bảo mật</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.PAYMENT_POLICY} className="btn btn-link text-white">Chính sách thanh toán</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.SHIPMENT_POLICY} className="btn btn-link text-white">Chính sách vận chuyển</Link>
                    </div>
                </div>
                <div className="row justify-content-center footer-policy-links">
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.INSPECTION_POLICY} className="btn btn-link text-white">Chính sách kiểm hàng</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.RETURN_POLICY} className="btn btn-link text-white">Chính sách đổi trả</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.RIGHTS_PROTECTION_POLICY} className="btn btn-link text-white">CS bảo đảm quyền lợi</Link>
                    </div>
                    <div className="col-6 col-md-3">
                        <Link to={NAVIGATION.GUIDE_VNPAY} className="btn btn-link text-white">HD thanh toán VNPAY</Link>
                    </div>
                </div>
                {/* <div className="footer-brand">{pageInfo.company_shortcode}</div>
                <div className="row">
                    <div className="col-sm-6">
                        <h5 className="mb-3">{pageInfo.company_name}</h5>
                        <ul className="footer-links site-footer-company-info">
                            <li>{pageInfo.address}</li>
                            {pageInfo.address_line2 ? <li>{pageInfo.address_line2}</li> : null}
                            <li>Hotline: <a href={`tel:${pageInfo.phone_number}`}>{pageInfo.phone_number}</a></li>
                            <li>Email: <a href={`mailto:${pageInfo.email}`}>{pageInfo.email}</a></li>
                            <li>Số giấy chứng nhận ĐKKD: {pageInfo.company_registration_number}</li>
                            <li>Người đại diện theo pháp luật: {pageInfo.company_representative_person}</li>
                            <li>Chức vụ: {pageInfo.company_representative_position}</li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h5 className="mb-3">ĐƯỜNG DẪN</h5>
                        <ul className="footer-links">
                            <li><Link to={NAVIGATION.TERMS_OF_SERVICE}>{text.footer.links.tos[lang]}</Link></li>
                            <li><Link to={NAVIGATION.PRIVACY_POLICY}>{text.footer.links.privacy_policy[lang]}</Link></li>
                            <li><Link to={NAVIGATION.PAYMENT_POLICY}>{text.footer.links.payment_policy[lang]}</Link></li>
                            <li><Link to={NAVIGATION.WEBSITE_OWNER}>{text.footer.links.website_owner[lang]}</Link></li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h5 className="mb-3">THÔNG TIN</h5>
                        <ul className="footer-links">
                            <li><Link to={NAVIGATION.CONTACT_US}>{text.footer.information.contact[lang]} {pageInfo.brandname}</Link></li>
                            <li><Link to={NAVIGATION.ABOUT_US}>{text.footer.information.about[lang]} {pageInfo.brandname}</Link></li>
                            <li><Link to={NAVIGATION.FAQS}>{text.footer.information.faqs[lang]}</Link></li>
                            <li><Link to={NAVIGATION.KEYWORDS}>{text.footer.information.keywords[lang]}</Link></li>
                        </ul>
                    </div>
                </div> */}
            </div>
            {/* <div id="copyright" className="site-copyright">
                <div className="text-center">{pageInfo.company_shortname} &copy; {pageInfo.company_foundation_year}</div>
            </div> */}
        </footer>
    );
})

export {
    ScreenWithNavBar,
    ScreenWithNavBarAndFooter
}