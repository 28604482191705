import React, { useState, useEffect } from 'react';
import './Loading.scss';

const Loading = props => {
    const { show } = props;

    return (
        <div className={`loading-fullscreen ${show ? 'show' : ''}`}>
            <div className="loading-overlay"></div>
            <div className="loading-elements">
                <span className="loading-icon">
                    <img src="/public_assets/images/locamos-icon.svg" alt="" width={64} height={64}></img>
                </span>
            </div>
        </div>
    )
}

export default Loading;