import React, { useState, useEffect } from 'react';
import "./Account.scss"

import { connect } from 'react-redux';
import { authActions, alertActions } from 'app/redux/actions';

import { AccountLayout } from ".";
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
} from '@tanstack/react-table'

import { membershipService } from "services";

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    userUpdate: authActions.user_update,
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}

const Transactions = connect(mapStateToProps, actionCreators)(props => {
    const { user } = props.auth;
    const { lang, locale_string } = props.pageInfo;

    const [dataQuery, setDataQuery] = useState(null);
    const [loading, setLoading] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                header: 'Thời gian',
                accessorKey: 'created_at',
                cell: info => info.getValue()
            },
            {
                header: 'Người mua',
                accessorKey: 'full_name',
                cell: info => info.getValue()
            },
            {
                header: 'SĐT',
                accessorKey: 'phone',
                cell: info => info.getValue()
            },
            {
                header: 'Email',
                accessorKey: 'email',
                cell: info => info.getValue()
            },
            {
                header: 'Doanh thu',
                accessorKey: 'bill_amount',
                cell: info => info.getValue().toLocaleString(locale_string) + ' VNĐ'
            },
            {
                header: 'Tỉ lệ hoa hồng',
                accessorKey: 'percentage',
                cell: info => info.getValue() + '%'
            },
            {
                header: 'Hoa hồng',
                accessorKey: 'commission_amount',
                cell: info => info.getValue() + ' VNĐ'
            },
            {
                header: 'Hoa hồng D-LocaVoucher',
                accessorKey: 'commission_dvoucher',
                cell: info => info.getValue() + ' D-LocaVoucher'
            },
        ],
        []
    )

    const [{ pageIndex, pageSize }, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    })

    useEffect(() => {
        // membershipService.fetch_user_commissions({
        //     token: user.token,
        //     page: pageIndex,
        //     size: pageSize
        // }).then(data => {
        //     if (typeof data === 'object') {
        //         setDataQuery(data);
        //     } else {
        //         setDataQuery([]);
        //     }
        // })

        return () => {
        }
    }, [pageIndex, pageSize])

    const defaultData = React.useMemo(() => [], [])

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const table = useReactTable({
        data: dataQuery?.list ?? defaultData,
        columns,
        pageCount: dataQuery?.total_page ?? -1,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
        debugTable: true,
    })
    
    return (
        <AccountLayout className="page-content">
            <div className="table-responsive">
                <table className="table table-bordered table-report">
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (<>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </>)}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {loading ? <p>Loading...</p> : ''}
            
            <div className="table-report-footer mb-2">
                <div className="table-report-footer-group">
                    <button
                        className="btn btn-sm btn-secondary btn-icon"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <i className="fa-light fa-sharp fa-chevrons-left"></i>
                    </button>
                    <button
                        className="btn btn-sm btn-secondary btn-icon"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <i className="fa-light fa-sharp fa-chevron-left"></i>
                    </button>
                    <button
                        className="btn btn-sm btn-secondary btn-icon"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <i className="fa-light fa-sharp fa-chevron-left"></i>
                    </button>
                    <button
                        className="btn btn-sm btn-secondary btn-icon"
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        <i className="fa-light fa-sharp fa-chevrons-right"></i>
                    </button>
                    <br />
                </div>
                <div className="table-report-footer-group">
                    <span>Trang</span>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} của {table.getPageCount()}
                    </strong>
                    <span>-</span>
                    <span>Đến trang:</span>
                    <div>
                        <input
                            type="number"
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                table.setPageIndex(page)
                            }}
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
                <div className="table-report-footer-group">
                    <span className="text-nowrap">Hiển thị</span>
                    <select
                        className="form-select form-select-sm"
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </AccountLayout>
    )
})

export {
    Transactions
}