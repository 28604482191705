import React, { useState, useEffect } from 'react';
import './Payment.scss';

import { connect } from 'react-redux';
import { orderActions, alertActions, authActions } from 'app/redux/actions';

import { NAVIGATION, BASE_IMAGE } from 'config';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { ScreenWithNavBar, InputWithCopyButton, Loading } from 'app/components';
import PinInput from 'react-pin-input';

import { membershipService } from "services";

import { getParamPath, getParamsFromUrl } from "utils/AppHelper";

const mapStateToProps = (state) => {
    const { pageInfo, auth, order } = state;
    return { pageInfo, auth, order }
}

const actionCreators = {
    userUpdate: authActions.user_update,
    updateOrder: orderActions.update,
    clearOrder: orderActions.clear,
    alertSuccess: alertActions.success,
    alertWarning: alertActions.warning,
    alertError: alertActions.error
}

const PaymentPreview = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, order, userUpdate, updateOrder, alertSuccess, alertWarning, alertError } = props;
    const { user } = auth;
    const { lang, locale_string } = pageInfo;

    const navigate = useNavigate();
    const location = useLocation();

    const [originalPaymentMethod, setOriginalPaymentMethod] = useState(order.payment_method);
    
    const [dLocaVoucherNumber, setDLocaVoucherNumber] = useState(order.use_discount ? 50 : 0);

    const [subtotalNumber, setSubtotalNumber] = useState(order.use_discount ? 2000000 : 3000000);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        userUpdate(user.token);

        if (location.state && location.state.payment_method) {
            setOriginalPaymentMethod(location.state.payment_method);
        }

        return () => {
        }
    }, [])

    useEffect(() => {
        if (order.status === 'COMPLETED') {
            navigate(NAVIGATION.PAYMENT_RESULT, { replace: true });
        }

        return () => {
        }
    }, [order])
    
    function renderDeliveryDay(day_index) {
        if (!day_index || day_index === '') {
            return 'Bạn chưa chọn';
        }
        
        return day_index === '8' ? 'Chủ Nhật' : 'Thứ ' + day_index;
    }

    function renderPaymentMethod(payment_method) {
        let method = '';

        switch (payment_method) {
            case 'TRANSFER':
                method = 'CHUYỂN KHOẢN'
                break;

            case 'VNPAY':
                method = 'VNPAY'
                break;
        
            default:
                break;
        }

        return method;
    }

    function paymentConfirmed(e) {
        // if (order.pin_code === '' || order.pin_code.length < 4) {
        //     alertError('Vui lòng nhập Mã PIN giao dịch để xác nhận thanh toán!');
        // } else {
        //     navigate(NAVIGATION.PAYMENT_RESULT, { replace: true, state: { hello_parame: '123123123' } });
        // }
        // if (order.payment_method === 'VNPAY') {
        //     alertWarning('Tính năng đang phát triển!');
        //     return;
        // }
        setLoading(true);

        if (order.use_discount && user.userApp.dvoucher_quantity < 50) {
            setLoading(false);
            alertError('Tài khoản D-LocaVoucher của bạn không đủ. Vui lòng đổi thêm để tiếp tục!');
        } else {
            membershipService.buy_membership(order)
                .then(data => {
                    setLoading(false);
                    console.log(data);
                    if (typeof data === 'object') {
                        if (data.payment_transaction.status !== 'CREATE') {
                            switch (data.payment_transaction.status) {
                                case 'CREATE':
                                    if (data.payment_transaction.paymentMethod === 'TRANSFER') {
                                        alertWarning('Bạn đang có 01 giao dịch CHUYỂN KHOẢN chưa hoàn thành. Hãy bổ sung thông tin để tiếp tục!');
                                    } else {
                                        alertWarning('Bạn đang có 01 giao dịch VNPAY chưa hoàn thành. Vui lòng thực hiện lại giao dịch!');
                                    }
                                    break;
                                
                                case 'PENDING':
                                    alertWarning('Bạn đang có 01 giao dịch ' + renderPaymentMethod(data.payment_transaction.paymentMethod) + ' đang chờ xác minh. Vui lòng liên hệ CSKH để biết thêm chi tiết!');
                                    break;
            
                                case 'REFUSE':
                                    alertWarning('Bạn đang có 01 giao dịch ' + renderPaymentMethod(data.payment_transaction.paymentMethod) + ' không hợp lệ. Vui lòng cập nhật lại để hoàn thành giao dịch hoặc liên hệ CSKH để biết thêm chi tiết!');
                                    break;
            
                                default:
                                    break;
                            }
                        }

                        updateOrder({
                            ...order,
                            status: data.payment_transaction.status,
                            bank_account_transfer: data.bank_account_transfer,
                            payment_transaction: data.payment_transaction
                        }).then(() => {
                            // handle redirect based on payment_method
                            switch (data.payment_transaction.paymentMethod) {
                                case 'TRANSFER':
                                    navigate(NAVIGATION.PAYMENT_RESULT, { replace: true });
                                    break;

                                case 'VNPAY':
                                    if (data.vnpay_url) {
                                        // console.log(data.vnpay_url);
                                        // console.log(getParamsFromUrl(data.vnpay_url));
                                        window.location.href = data.vnpay_url;
                                    } else {
                                        if (order.payment_transaction.status === 'PENDING') {
                                            setLoading(false);
                                            navigate(NAVIGATION.PAYMENT_RESULT, { replace: true });
                                        } else {
                                            setLoading(false);
                                            alertError('Có lỗi khi tạo giao dịch. Vui lòng thử lại sau!');
                                        }
                                    }

                                default:
                                    break;
                            }
                        });                        
                    } else {
                        alertError(data);
                    }
                })
        }        
    }

    function goToPendingTransferResult(e) {
        updateOrder({
            ...order,
            bank_account_transfer: user.bank_account_transfer,
            payment_transaction: user.payment_transaction
        });
        navigate(NAVIGATION.PAYMENT_RESULT);
    }

    function goBack(e) {
        navigate(NAVIGATION.BUY_MEMBERSHIP, { replace: true });
    }
    
    return (
        <ScreenWithNavBar className="page-payment">
            <Loading show={loading} />
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="sec-header text-center">
                                <h2 className="sec-title">Xác nhận thanh toán</h2>
                                <p className="sec-description">Vui lòng kiểm tra lại toàn bộ thông tin trước khi Xác nhận. D-LocaVoucher (nếu có áp dụng) sẽ được tự động trừ từ <b>Tài khoản Ứng dụng LocaMos</b> của bạn</p>
                            </div>
                            {
                                user.payment_transaction && user.payment_transaction.status !== 'APPROVED'
                                ? <div className="alert alert-warning" role="alert">
                                    <b>Chú ý:</b> Bạn đang có 01 giao dịch với phương thức <b>{renderPaymentMethod(user.payment_transaction.paymentMethod)}</b> đang ở trạng thái chưa hoàn thành!
                                    <button className="btn btn-warning w-100 mt-3" onClick={goToPendingTransferResult}>Xem giao dịch</button>
                                    <button className="btn btn-link w-100 mt-3" onClick={e => { goBack() }}>Quay lại</button>
                                </div>
                                : ''
                            }
                            <div className="sec-content p-3 p-md-4">
                                <div className="payment-preview mb-4">
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td>Chủ thẻ</td>
                                                <td>{order.full_name ? order.full_name : 'Bạn chưa nhập'}</td>
                                            </tr>
                                            <tr>
                                                <td>Địa chỉ giao</td>
                                                <td>{order.address !== '' && order.district_text !== '' && order.province_text !== '' ? order.address + ', ' + order.district_text + ', ' + order.province_text : 'Bạn chưa chọn'}</td>
                                            </tr>
                                            <tr>
                                                <td>Ngày giao</td>
                                                <td>{renderDeliveryDay(order.delivery_day)}</td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                                <td>LocaMart lấy hàng</td>
                                                <td>{order.store_name ? order.store_name : 'Bạn chưa chọn'}</td>
                                            </tr>
                                            <tr>
                                                <td>Phương thức thanh toán</td>
                                                <td>{renderPaymentMethod(order.payment_method)}</td>
                                            </tr>
                                            <tr>
                                                <td>Tổng phí</td>
                                                <td>{(3000000).toLocaleString(locale_string)} VNĐ</td>
                                            </tr>
                                            <tr>
                                                <td>D-LocaVoucher</td>
                                                <td>{dLocaVoucherNumber}</td>
                                            </tr>
                                            <tr>
                                                <td>Cần thanh toán</td>
                                                <td>{subtotalNumber.toLocaleString(locale_string)} VNĐ</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className="payment-pin-confirm text-center">
                                    <p className="mb-0">Nhập mã PIN để xác nhận thanh toán</p>
                                    <p><Link to={NAVIGATION.SECURITY_SETTINGS}>Quên mã PIN?</Link></p>
                                    <div className="mb-5">
                                        <PinInput
                                            length={4}
                                            initialValue=""
                                            secret
                                            secretDelay={250}
                                            onChange={(value, index) => { }}
                                            type="numeric"
                                            inputMode="number"
                                            inputStyle={{ 
                                                fontWeight: '700',
                                                borderRadius: '.5rem',
                                                borderColor: 'var(--bs-gray-300)', 
                                                margin: '0 .625rem',
                                                transition: 'border-color 200ms ease',
                                                width: '64px',
                                                height: '72px',
                                                fontSize: '1.5rem'
                                            }}
                                            inputFocusStyle={{ borderColor: 'var(--bs-primary)' }}
                                            onComplete={(value, index) => { updateOrder({ ...order, pin_code: value }) }}
                                            autoSelect={true}
                                            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                        />
                                    </div>
                                </div> */}
                                <button className="btn btn-lg btn-success w-100 text-white my-4" onClick={paymentConfirmed} disabled={loading}>
                                    {
                                        loading
                                        ? <i className="fa-light fa-sharp fa-spin fa-loader"></i>
                                        : 'Xác nhận'
                                    }
                                </button>
                                <button className="btn btn-link w-100 text-secondary mb-2" onClick={goBack}>Quay lại</button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </ScreenWithNavBar>
    )
})

const PaymentResult = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, order, userUpdate, updateOrder, clearOrder, alertSuccess, alertWarning, alertError } = props;
    const { user } = auth;
    const { lang, locale_string } = pageInfo;

    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);

    const [updateOrderForm, setUpdateOrderForm] = useState({
        file: null,
        token: user.token || '',
        id: order.payment_transaction.id || '',
    })
    const [imagePreviewURI, setImagePreviewURI] = useState(null);

    const [vnpayStatus, setVnpayStatus] = useState(null);

    useEffect(() => {
        userUpdate(user.token);

        if (order.payment_transaction && order.payment_transaction.paymentMethod === 'VNPAY') {
            // alertError('VNPAY nè');
            setLoading(true);

            let vnpay_params = getParamPath();
            console.log(vnpay_params);
            membershipService.fetch_order_info({
                id: order.payment_transaction.id,
                token: user.token
            }).then(data => {
                console.log(data);

                setLoading(false);

                if (typeof data === 'object') {
                    setVnpayStatus(data);
                } else {
                    // setVnpayStatus({
                    //     response_code: order.payment_transaction.status,
                    //     transaction_no: ''
                    // })
                }
            })
        } else {
            setLoading(false);
        }

        return () => {
        }
    }, [])

    function renderPaymentStatusHeader(status, paymentMethod) {
        // console.log(status);
        switch (paymentMethod) {
            case 'TRANSFER':
                return renderPaymen_TRANSFER_StatusHeader(status);
                break;
            
            case 'VNPAY':
                return renderPayment_VNPAY_StatusHeader(vnpayStatus);
                break;

            default:
                break;
        }
        
    }

    function renderPaymen_TRANSFER_StatusHeader(status) {
        if (loading) return (<>
            <i className="fa-light fa-sharp fa-spin fa-2x fa-loader"></i>
        </>);
        
        switch (status) {
            case 'CREATE':
                return (<>
                    <h2 className="sec-title">Bổ sung thông tin</h2>
                    <p className="sec-description">Cảm ơn bạn đã lựa chọn LocaMart. Hãy chuyển khoản theo thông tin dưới đây và tải lên hình ảnh giao dịch thành công để chúng tôi xác nhận sớm nhất.</p>
                </>)
                break;

            case 'PENDING':
                return (<>
                    <h2 className="sec-title">Giao dịch chờ duyệt</h2>
                    <p className="sec-description">Cảm ơn bạn đã lựa chọn LocaMart. Giao dịch của bạn đang được xác minh. Chúng tôi sẽ thông báo kết quả sớm nhất.</p>
                </>)
                break;

            case 'REFUSE':
                return (<>
                    <h2 className="sec-title">Giao dịch từ chối</h2>
                    <p className="sec-description">Hình ảnh giao dịch của bạn không hợp lệ. Hãy chuyển khoản theo thông tin dưới đây và tải lên hình ảnh giao dịch thành công để chúng tôi xác nhận sớm nhất.</p>
                </>)
                break;

            case 'APPROVED':
                return (<>
                    <h2 className="sec-title">Giao dịch thành công</h2>
                    <p className="sec-description">Cảm ơn bạn đã lựa chọn Locamart. Bộ phận CSKH của chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất!</p>
                </>)
                break;

            case 'CLOSE':
                return (<>
                    <h2 className="sec-title">Giao dịch từ chối</h2>
                    <p className="sec-description">Hình ảnh giao dịch của bạn không hợp lệ. Vui lòng thực hiện lại giao dịch mới. Xin cảm ơn!</p>
                </>)
                break;

            default:
                break;
        }
    }

    function renderPayment_VNPAY_StatusHeader(status) {
        if (loading) return (<>
            <i className="fa-light fa-sharp fa-spin fa-2x fa-loader"></i>
        </>);

        if (!status) return (<>
            <h2 className="sec-title">Không tìm thấy</h2>
            <p className="sec-description">Không tìm thấy giao dịch mà bạn đang thực hiện. Vui lòng thử lại sau</p>
        </>);

        switch (status.vnpay.vnpResponseCode) {
            case '00':
                return (<>
                    <h2 className="sec-title">Giao dịch thành công</h2>
                    <p className="sec-description">Cảm ơn bạn đã lựa chọn Locamart. Bộ phận CSKH của chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất!</p>
                </>)
                break;

            case '07':
                return (<>
                    <h2 className="sec-title">Giao dịch chờ xác minh</h2>
                    <p className="sec-description">Giao dịch của bạn hiện đang chờ xác minh. Vui lòng kiểm tra lại trong vòng 30 phút hoặc liên hệ CSKH để biết thêm chi tiết</p>
                </>)
                break;
        
            default:
                return (<>
                    <h2 className="sec-title">Giao dịch chưa hoàn thành</h2>
                    <p className="sec-description">{status.response_code_label || 'Giao dịch chưa hoàn thành. Hãy chờ vài phút sau đó bấm Cập nhật kết quả giao dịch hoặc liên hệ CSKH để biết thêm chi tiết'}</p>
                </>)
                break;
        }
    }

    function renderPaymentMethod(payment_method) {
        let method = '';

        switch (payment_method) {
            case 'TRANSFER':
                method = 'Chuyển khoản ngân hàng'
                break;

            case 'VNPAY':
                method = 'VNPAY'
                break;
        
            default:
                break;
        }

        return method;
    }

    function handleImageSelect(e) {
        // console.log(e);

        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = function (ev) {
                setImagePreviewURI(ev.target.result)
            };
            reader.readAsDataURL(e.target.files[0]);

            setUpdateOrderForm(form => {
                return {
                    ...form,
                    file: e.target.files[0]
                }
            })
        } else {
            setImagePreviewURI(null);
        }

        try {
            e.target.blur();            
        } catch (error) {
            console.log(error);
        }
    }

    function renderPaymentResultTable(status, paymentMethod) {
        switch (paymentMethod) {
            case 'TRANSFER':
                return renderPayment_TRANSFER_ResultTable(status);
                break;
            
            case 'VNPAY':
                return renderPayment_VNPAY_ResultTable(vnpayStatus);
                break;
        
            default:
                break;
        }
    }

    function renderPayment_TRANSFER_ResultTable(status) {
        switch (status) {
            case 'CREATE':
            case 'REFUSE':
                return (<>
                    <tr>
                        <td colSpan={2}>
                            <p className="text-center fw-bold mb-2">THÔNG TIN CHUYỂN KHOẢN</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <InputWithCopyButton id="bankName" label="Tên ngân hàng" value={order.bank_account_transfer.bank_name} onChange={e => { console.log(e.value) }} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <InputWithCopyButton id="bankAccountBranch" label="Chi nhánh" value={order.bank_account_transfer.branch_name} onChange={e => { console.log(e.value) }} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <InputWithCopyButton id="bankAccountNumber" label="Số tài khoản" value={order.bank_account_transfer.account_number} onChange={e => { console.log(e.value) }} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <InputWithCopyButton id="transferNote" label="Số tiền" value={order.payment_transaction.billAmount} onChange={e => { console.log(e.value) }} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <InputWithCopyButton id="transferNote" label="Nội dung chuyển khoản" value={order.payment_transaction.transactionCode} onChange={e => { console.log(e.value) }} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <form onSubmit={submitOrderUpdate}>
                                <label htmlFor="transactionImage" className="form-label">Hình ảnh giao dịch</label>
                                <div className="form-image-select mb-3">
                                    <input className="form-control" type="file" id="file" name="file" accept="image/png, image/jpeg" required onChange={handleImageSelect}></input>
                                    <label htmlFor="file" className="form-label">
                                        {
                                            imagePreviewURI
                                            ? <div className="form-image-preview"><img src={imagePreviewURI} alt="" className="img-fluid w-100 h-100"></img></div>
                                            : ''
                                        }
                                        <i className="fa-light fa-sharp fa-upload fa-2x"></i><br />
                                        Tải lên hình ảnh<br />(JPG, PNG, không quá 15MB)
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-lg btn-primary w-100">Tải lên hình ảnh</button>
                            </form>
                        </td>
                    </tr>
                </>)
                break;
            
            case 'PENDING':
                return (<>
                    <tr>
                        <td>Phương thức</td>
                        <td>Chuyển khoản</td>
                    </tr>
                    <tr>
                        <td>Mã tra cứu</td>
                        <td>{order.payment_transaction.transactionCode}</td>
                    </tr>
                    <tr>
                        <td>D-LocaVoucher</td>
                        <td>{order.payment_transaction.billDVoucher}</td>
                    </tr>
                    <tr>
                        <td>Cần thanh toán</td>
                        <td>{order.payment_transaction.billAmount.toLocaleString(locale_string)} VNĐ</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <p className="mb-2">Hình ảnh giao dịch</p>
                            <div className="transaction-image">
                                <img src={BASE_IMAGE + order.payment_transaction.uploadImages} alt=""></img>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button className="btn btn-lg btn-success w-100 text-white mt-4" onClick={goHome}>Về trang chủ</button>
                        </td>
                    </tr>
                </>)
                break;

            case 'APPROVED':
            case 'CLOSE':
                return (<>
                    <tr>
                        <td>Phương thức</td>
                        <td>Chuyển khoản</td>
                    </tr>
                    <tr>
                        <td>Mã tra cứu</td>
                        <td>{order.payment_transaction.transactionCode}</td>
                    </tr>
                    <tr>
                        <td>D-LocaVoucher</td>
                        <td>{order.payment_transaction.billDVoucher}</td>
                    </tr>
                    <tr>
                        <td>Cần thanh toán</td>
                        <td>{order.payment_transaction.billAmount.toLocaleString(locale_string)} VNĐ</td>
                    </tr>
                    <tr>
                        <td>Đã thanh toán</td>
                        <td>{order.payment_transaction.amountPaid.toLocaleString(locale_string)} VNĐ</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button className="btn btn-lg btn-success w-100 text-white mt-4" onClick={goHome}>Về trang chủ</button>
                        </td>
                    </tr>
                </>)
            default:
                break;
        }
    }

    function renderPayment_VNPAY_ResultTable(status) {
        if (loading) return (<tr>
            <td colSpan={2} className="text-center">
                <i className="fa-light fa-sharp fa-spin fa-2x fa-loader"></i>
            </td>
        </tr>);

        if (!status) return (<>
            <tr>
                <td colSpan={2} className="text-center">
                    <i className="fa-light fa-sharp fa-3x text-danger fa-ban"></i>
                </td>
            </tr>
        </>);

        switch (status.vnpay.vnpResponseCode) {
            case '00':
                return (<>
                    <tr>
                        <td>Phương thức</td>
                        <td>{renderPaymentMethod(status.payment_transaction.paymentMethod)}</td>
                    </tr>
                    <tr>
                        <td>Mã tra cứu</td>
                        <td>{status.payment_transaction.transactionCode}</td>
                    </tr>
                    <tr>
                        <td>Mã giao dịch</td>
                        <td>{status.vnpay.vnpTransactionNo || 'Đang cập nhật'}</td>
                    </tr>
                    <tr>
                        <td>Nội dung</td>
                        <td>{(status.response_code_label || 'Đang cập nhật').replace(/\+/gi, " ")}</td>
                    </tr>
                    <tr>
                        <td>D-LocaVoucher</td>
                        <td>{status.payment_transaction.billDVoucher}</td>
                    </tr>
                    <tr>
                        <td>Cần thanh toán</td>
                        <td>{status.payment_transaction.billAmount.toLocaleString(locale_string)} VNĐ</td>
                    </tr>
                    <tr>
                        <td>Đã thanh toán</td>
                        <td>{status.vnpay.vnpAmount.toLocaleString(locale_string)} VNĐ</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button className="btn btn-lg btn-success w-100 text-white mt-4" onClick={goHome}>Về trang chủ</button>
                        </td>
                    </tr>
                </>)
                break;
        
            default:
                return (<>
                    <tr>
                        <td>Phương thức</td>
                        <td>{renderPaymentMethod(status.payment_transaction.paymentMethod)}</td>
                    </tr>
                    <tr>
                        <td>Mã tra cứu</td>
                        <td>{status.payment_transaction.transactionCode}</td>
                    </tr>
                    <tr>
                        <td>Mã giao dịch</td>
                        <td>{status.vnpay.vnpTransactionNo || 'Đang cập nhật'}</td>
                    </tr>
                    <tr>
                        <td>Nội dung</td>
                        <td>{(status.response_code_label || 'Đang cập nhật').replace(/\+/gi, " ")}</td>
                    </tr>
                    <tr>
                        <td>D-LocaVoucher</td>
                        <td>{status.payment_transaction.billDVoucher}</td>
                    </tr>
                    <tr>
                        <td>Cần thanh toán</td>
                        <td>{status.payment_transaction.billAmount.toLocaleString(locale_string)} VNĐ</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button className="btn btn-lg btn-secondary w-100 text-white mt-4 mb-3" onClick={reloadPage} disabled={loading}>
                                Cập nhật kết quả giao dịch
                            </button>
                            <button className="btn btn-lg btn-success w-100 text-white mb-4" onClick={retry_VNPAY_payment} disabled={loading}>
                                {
                                    loading
                                        ? <i className="fa-light fa-sharp fa-spin fa-loader"></i>
                                        : 'Thực hiện lại'
                                }
                            </button>
                            <button className="btn btn-link w-100 text-secondary mb-2" onClick={goBack}>Quay lại</button>
                        </td>
                    </tr>
                </>)
                break;
        }
    }

    function submitOrderUpdate(e) {
        e.preventDefault();
        e.stopPropagation();

        // console.log(e);
        setLoading(true);

        if (!e.target.checkValidity()) {
            setLoading(false);
            alertError('Vui lòng kiểm tra lại ảnh giao dịch đã chọn!');
        } else {
            console.log(updateOrderForm);
            membershipService.update_order(updateOrderForm)
                .then(data => {
                    setLoading(false);

                    if (typeof data === 'object') {
                        updateOrder({
                            ...order,
                            status: data.payment_transaction.status,
                            payment_transaction: data.payment_transaction,
                            payment_image_url: data.url
                        })
                    } else {
                        alertError(data);
                    }
                })
        }
    }
    
    function retry_VNPAY_payment(e) {
        setLoading(true);

        if (order.use_discount && user.userApp.dvoucher_quantity < 50) {
            setLoading(false);
            alertError('Tài khoản D-LocaVoucher của bạn không đủ. Vui lòng đổi thêm để tiếp tục!');
        } else {
            membershipService.buy_membership(order)
                .then(data => {
                    if (typeof data === 'object') {
                        console.log(data, data.payment_transaction.paymentMethod === 'VNPAY');
                        updateOrder({
                            ...order,
                            status: data.payment_transaction.status,
                            bank_account_transfer: data.bank_account_transfer,
                            payment_transaction: data.payment_transaction
                        }).then(() => {
                            // handle redirect based on payment_method
                            switch (data.payment_transaction.paymentMethod) {
                                case 'TRANSFER':
                                    navigate(NAVIGATION.PAYMENT_RESULT, { replace: true });
                                    break;

                                case 'VNPAY':
                                    if (data.vnpay_url) {
                                        console.log(data.vnpay_url);
                                        console.log(getParamsFromUrl(data.vnpay_url));
                                        window.location.href = data.vnpay_url;
                                    } else {
                                        if (order.payment_transaction.status === 'PENDING') {
                                            setLoading(false);
                                            alertWarning('Bạn có 01 giao dịch đang chờ xác minh. Vui lòng liên hệ CSKH để biết thêm chi tiết!');
                                        } else {
                                            setLoading(false);
                                            alertError('Có lỗi khi tạo giao dịch. Vui lòng thử lại sau!');
                                        }
                                    }

                                default:
                                    break;
                            }
                        });            
                    } else {
                        alertError('Có lỗi khi tạo giao dịch. Vui lòng tạo giao dịch mới hoặc thử lại sau!')
                    }
                })
        }        
    }

    function reloadPage(e) {
        window.location.reload();
    }
    

    function goHome(e) {
        navigate(NAVIGATION.DASHBOARD, { replace: true });
    }

    function goBack(e) {
        navigate(NAVIGATION.BUY_MEMBERSHIP, { replace: true });
    }
    
    return (
        <ScreenWithNavBar className="page-payment">
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="sec-header text-center">
                                {renderPaymentStatusHeader(order.payment_transaction.status, order.payment_transaction.paymentMethod)}
                            </div>
                            {
                                !loading
                                ? <div className="sec-content p-3 p-md-4">
                                    <div className="payment-preview">
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                {renderPaymentResultTable(order.payment_transaction.status, order.payment_transaction.paymentMethod)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </header>
        </ScreenWithNavBar>
    )
})

export {
    PaymentPreview, PaymentResult
};