import React, { useState, useEffect } from 'react';
import "./Account.scss"

import { connect } from 'react-redux';
import { authActions, alertActions } from 'app/redux/actions';

import { Link, NavLink, useLocation } from 'react-router-dom';
import { NAVIGATION, BASE_IMAGE, SHIPPING_FEES_3KM } from 'config';

import { ScreenWithNavBar, InputWithCopyButton } from 'app/components';
import { Transactions } from "./Transactions";
import { SalesReport } from "./SalesReport";

import { membershipService } from "services";

import { province_list } from '../Dashboard/data';

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    userUpdate: authActions.user_update,
    alertSuccess: alertActions.success,
    alertError: alertActions.error,
    alertWarning: alertActions.warning
}

const AccountLayout = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, userUpdate } = props;
    const { user } = auth;
    const { lang, locale_string } = pageInfo;

    const location = useLocation();

    useEffect(() => {
        userUpdate(user.token);

        return () => {
        }
    }, [userUpdate])

    const showToday = () => {
        let today = new Date().toLocaleString(locale_string, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

        return today
    }

    function renderMembershipStatus(status) {
        let text = '';
        switch (status) {
            case 'ACTIVE':
                text = 'Thời hạn thẻ: ' + user.membership.membership_to_date;
                break;
            
            case 'EXPIRED':
                text = 'Tem Rau của bạn đã hết hạn';
                break;

            default:
                text = 'Bạn chưa đăng ký Tem Rau';
                break;
        }

        return text;
    }

    if (pageInfo.inapp && pageInfo.inapp === '1') {
        return (
            <ScreenWithNavBar className="page-content page-account">
                <header className="page-header content-page-header inapp-page-header">
                    <div className="container-xl">
                        <div className="sec-header">
                            <h2 className="sec-title">{ location.state && location.state.page_title ? ' ' + location.state.page_title : 'Tem Rau' }</h2>
                            <p className="sec-description">
                                { user.membership_status ? renderMembershipStatus(user.membership_status) : 'Có lỗi khi tải thông tin Tem Rau của bạn. Vui lòng thử lại sau!' }
                            </p>
                            {
                                window.location.hostname === 'demo-membership.locamos.vn'
                                ? <InputWithCopyButton id="token" label="App Token" className="mt-3" btnClassName="btn-light" value={user.token} onChange={e => { console.log(e.value) }} />
                                : ''
                            }
                            {
                                window.location.hostname === 'demo-membership.locamos.vn'
                                ? <button data-url="https://zalo.me/2820821147149288952" data-target="browser" className="btn btn-primary w-100 mt-3 btn-to-browser">Bấm để mở link sang trình duyệt</button>
                                : ''
                            }
                        </div>
                    </div>
                </header>
                <section className="page-section">
                    <div className="container-xl">
                        <div className="sec-content">
                            <div className="account-layout flex-column flex-md-row">
                                <div className="account-layout-content">
                                    { props.children }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ScreenWithNavBar>
        )
    }
    
    return (
        <ScreenWithNavBar className="page-content page-account">
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <div className="sec-header">
                        <h2 className="sec-title">Tài khoản</h2>
                        <p className="sec-description">
                            { user.membership_status ? renderMembershipStatus(user.membership_status) : 'Có lỗi khi tải thông tin Tem Rau của bạn. Vui lòng thử lại sau!' }
                        </p>
                    </div>
                </div>
            </header>
            <section className="page-section">
                <div className="container-fluid">
                    <div className="sec-content">
                        <div className="account-layout">
                            <div className="account-layout-nav">
                                <div className="navbar navbar-expand-md navbar-light flex-column p-0 account-layout-navbar">
                                    <button className="btn btn-secondary w-100 text-start btn-account-navbar-toggler" data-bs-toggle="collapse" data-bs-target="#accountNav" aria-controls="accountNav">
                                        <i className="far fa-bars"></i>
                                        { location.state && location.state.page_title ? ' ' + location.state.page_title : 'Tem Rau' }
                                    </button>
                                    <div className="collapse navbar-collapse w-100" id="accountNav">
                                        <ul className="nav nav-pills flex-column w-100">
                                            <li className="nav-item">
                                                <NavLink to={NAVIGATION.MEMBERSHIP} className="nav-link" state={{ page_title: 'Tem Rau' }}>Tem Rau</NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                                <NavLink to={NAVIGATION.ACCOUNT} className="nav-link" state={{ page_title: 'Thông tin tài khoản' }}>Thông tin tài khoản</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to={NAVIGATION.TRANSACTIONS} className="nav-link" state={{ page_title: 'Lịch sử giao dịch' }}>Lịch sử giao dịch</NavLink>
                                            </li> */}
                                            <li className="nav-item">
                                                <NavLink to={NAVIGATION.SALES_REPORT} className="nav-link" state={{ page_title: 'Báo cáo doanh thu' }}>Báo cáo doanh thu</NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                                <NavLink to={NAVIGATION.SECURITY_SETTINGS} className="nav-link" state={{ page_title: 'Mã PIN giao dịch' }}>Mã PIN giao dịch</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to={NAVIGATION.REFERRAL} className="nav-link" state={{ page_title: 'Giới thiệu bạn bè' }}>Giới thiệu bạn bè</NavLink>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={`account-layout-content flex-grow ${ props.className ? props.className : '' }`}>
                                { props.children }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBar>
    )
})

const NoMembership = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const { lang, locale_string } = pageInfo;
    
    return (
        <div className="no-membership">
            <img className="img-fluid mb-3" src="/public_assets/images/home/section-5/ThungRau2.png"></img>
            <h4>Ồ! Bạn chưa đăng ký Tem Rau!</h4>
            <p dangerouslySetInnerHTML={{ __html: props.customText ? props.customText : 'Hãy kích hoạt Tem Rau để dễ dàng tiếp cận với nguồn rau sạch hàng ngày từ vườn rau xanh <b>chuẩn VietGAP</b> của LocaMart.' }}></p>
            <div>
                <Link to={NAVIGATION.ACTIVE_MEMBERSHIP} className="btn btn-success me-2"><i className="fa-light fa-sharp fa-barcode-read"></i>Kích hoạt Tem Rau</Link>
                {
                    pageInfo.inapp !== '1'
                    ? <Link to={NAVIGATION.BUY_MEMBERSHIP} className="btn btn-warning"><i className="fa-light fa-sharp fa-bag-shopping"></i>Mua Tem Rau</Link>
                    : ''
                }
            </div>
        </div>
    )
})

const Account = connect(mapStateToProps, actionCreators)(props => {
    const { user } = props.auth;
    const { lang, locale_string } = props.pageInfo;
    
    return (
        <AccountLayout>
            Thông tin tài khoản
        </AccountLayout>
    )
})

const Membership = connect(mapStateToProps, actionCreators)(props => {
    const { auth, pageInfo } = props;
    const { user } = auth;
    const { lang, locale_string } = pageInfo;

    function renderMembershipCard(status) {
        switch (status) {
            case 'ACTIVE':
                return (<>
                    <div className={`membership-card mb-4 ${pageInfo.inapp === '1' ? 'inapp' : ''}`}>
                        <div className="card-owner-wrapper">
                            <p className="card-owner">{user.membership.full_name}</p>
                        </div>
                        <div className="card-valid-date">
                            <p className="card-start-date">
                                <span>BẮT ĐẦU</span><br />
                                {user.membership.membership_from_date}
                            </p>
                            <p className="card-end-date ms-5">
                                <span>KẾT THÚC</span><br />
                                {user.membership.membership_to_date}
                            </p>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <Link to={NAVIGATION.ACTIVE_MEMBERSHIP} className="btn btn-success me-2"><i className="fa-light fa-sharp fa-barcode-read"></i>Kích hoạt Tem Rau</Link>
                        {
                            pageInfo.inapp !== '1'
                            ? <Link to={NAVIGATION.BUY_MEMBERSHIP} className="btn btn-warning"><i className="fa-light fa-sharp fa-bag-shopping"></i>Mua Tem Rau</Link>
                            : ''
                        }
                    </div>
                </>)
                break;
            
            case 'EXPIRED':
                return (<>
                    <div className={`membership-card mb-4 ${pageInfo.inapp === '1' ? 'inapp' : ''}`}>
                        <div className="card-owner-wrapper">
                            <p className="card-owner">{user.membership.full_name}</p>
                        </div>
                        <div className="card-valid-date">
                            <p className="card-start-date">
                                <span>BẮT ĐẦU</span><br />
                                {user.membership.membership_from_date}
                            </p>
                            <p className="card-end-date ms-5">
                                <span>KẾT THÚC</span><br />
                                {user.membership.membership_to_date}
                            </p>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <p>Hãy gia hạn Tem Rau để dễ dàng tiếp cận với nguồn rau sạch hàng ngày từ vườn rau xanh <b>chuẩn VietGAP</b> của LocaMart.</p>
                        <div>
                            <Link to={NAVIGATION.ACTIVE_MEMBERSHIP} className="btn btn-success me-2"><i className="fa-light fa-sharp fa-barcode-read"></i>Kích hoạt Tem Rau</Link>
                            {
                                pageInfo.inapp !== '1'
                                ? <Link to={NAVIGATION.BUY_MEMBERSHIP} className="btn btn-warning"><i className="fa-light fa-sharp fa-bag-shopping"></i>Mua Tem Rau</Link>
                                : ''
                            }
                        </div>
                    </div>
                </>)
                break;

            default:
                return <NoMembership />
                break;
        }
    }
    
    return (
        <AccountLayout>
            { user.membership_status ? renderMembershipCard(user.membership_status) : 'Có lỗi khi tải thông tin Tem Rau của bạn. Vui lòng thử lại sau!'}
            {
                user.membership_status && user.membership_status === 'ACTIVE'
                ? <UserRedeemHistoryAndSettings />
                : ''
            }

        </AccountLayout>
    )
})

const UserRedeemHistoryAndSettings = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError, alertWarning } = props;
    const { user } = auth;
    const { lang, locale_string, inapp } = pageInfo;

    const [redeemHistoryList, setRedeemHistoryList] = useState([]);
    const [redeemHistoryCurrentPage, setRedeemHistoryCurrentPage] = useState(0);
    const [redeemHistoryTotalPage, setRedeemHistoryTotalPage] = useState(0);

    const [membershipForm, setMembershipForm] = useState({
        token: user.token || '',
        full_name: user.membership.full_name || '',
        phone: user.membership.phone || '',
        province_id: user.membership.province_id || '',
        province_text: '',
        district_id: user.membership.district_id || '',
        district_text: '',
        address: renderMergedAddress(user.membership.address || ''),
        delivery_day: user.membership.day_delivery || '',
        store_id: user.membership.store_id || '',
        store_name: '',
        ship_type: user.membership.ship_type || '',
        status_redeem: user.membership.status_redeem || ''
    })

    const [districtList, setDistrictList] = useState(() => {
        if (membershipForm.province_id !== '' && membershipForm.district_id !== '') {
            let district_list = province_list.filter(item => item.Id === membershipForm.province_id);
            if (district_list.length > 0) {
                return district_list[0].Districts;
            } else {
                return [];
            }
        } else {
            return [];
        }
    });

    const [nearbyStoreList, setNearbyStoreList] = useState([]);
    const [noNearbyStore, setNoNearbyStore] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const [loading, setLoading] = useState(false);
    const [errorHighlight, setErrorHighlight] = useState([]);

    useEffect(() => {
        let province_text, district_text;
        if (user.membership.province_id && user.membership.district_id) {
            let district_list = province_list.filter(item => item.Id === user.membership.province_id);
            if (district_list.length > 0) {
                province_text = district_list[0].Name;

                let selected_district = district_list[0].Districts.filter(district => district.Id === user.membership.district_id);
                if (selected_district.length > 0) {
                    district_text = selected_district[0].Name;
                }
            }
        }

        setMembershipForm({
            token: user.token || '',
            full_name: user.membership.full_name || '',
            phone: user.membership.phone || '',
            province_id: user.membership.province_id || '',
            province_text,
            district_id: user.membership.district_id || '',
            district_text,
            address: renderMergedAddress(user.membership.address || ''),
            delivery_day: user.membership.day_delivery || '',
            store_id: user.membership.store_id || '',
            store_name: '',
            ship_type: user.membership.ship_type || '',
            status_redeem: user.membership.status_redeem || ''
        })

        setFirstLoad(true);

        return () => {
        }
    }, [user])
    

    useEffect(() => {
        // console.log('?????? inapp', pageInfo.inapp);

        membershipService.fetch_user_redeem_history({
            token: user.token,
            page: redeemHistoryCurrentPage,
            size: 10
        }).then(data => {
            if (typeof data === 'object') {
                setRedeemHistoryList(data.items);
                setRedeemHistoryTotalPage(data.total_page);
            }
        })

        return () => {
        }
    }, [setRedeemHistoryList])

    useEffect(() => {
        if (errorHighlight.length > 0) {
            validateForm();
        }

        if (membershipForm.province_id !== '' && membershipForm.district_id !== '') {
            let params = firstLoad || membershipForm.store_id !== ''
                ? {
                    province_id: membershipForm.province_id,
                    district_id: membershipForm.district_id,
                    all_district_in_province: 1
                }
                : {
                    province_id: membershipForm.province_id,
                    district_id: membershipForm.district_id,
                }
            membershipService.fetch_nearby_store(params)
            .then(data => {
                console.log('?????? firstLoad', firstLoad);
                if (firstLoad) {
                    setFirstLoad(false);
                }

                if (data && data.length > 0) {
                    setNearbyStoreList(data);
                    setNoNearbyStore(false);
                } else {
                    setNearbyStoreList([]);
                    setNoNearbyStore(true);
                    if (!firstLoad) {
                        alertWarning('Danh sách LocaMart trong khu vực bạn chọn hiện đang cập nhật! Vui lòng thử lại sau.')
                    }
                }
            })
        } else {
            setNearbyStoreList([]);
            setNoNearbyStore(false);
        }

        return () => {
        }
    }, [membershipForm])

    function renderRedeemHistory(list) {
        if (!list || list.length === 0) {
            return (
                <tr>
                    <td colSpan={6}>
                        <div className="redeem-history-item-card">
                            { renderRedeemType('NULL') }
                            <div className="redeem-history-item-info flex-fill">
                                <p className="redeem-title"><b>Chưa nhận rau</b></p>
                                <p className="redeem-history-item-meta">
                                    Bạn chưa nhận rau lần nào. LocaMart sẽ chuyển rau theo lịch bạn đã đăng ký!
                                </p>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        }

        function renderRedeemStatus(status) {
            switch (status) {
                case 'IN_TRANSIT':
                    return <span className="text-warning">Đang vận chuyển</span>
                    break;
                
                case 'COMPLETED':
                    return <span className="text-success">Hoàn thành</span>
                    break;
                
                default:
                    return status;
                    break;
            }
        }

        function renderRedeemType(type) {
            switch (type) {
                case 'AT_HOME':
                    return <div className="redeem-type delivery"><i className="fa-light fa-sharp fa-lg fa-person-biking-mountain"></i></div>
                    break;

                case 'AT_STORE':
                    return <div className="redeem-type pickup"><i className="fa-light fa-sharp fa-lg fa-cart-shopping-fast"></i></div>
                    break;

                case 'NULL':
                    return <div className="redeem-type delivery"><i className="fa-light fa-sharp fa-lg fa-sparkles"></i></div>
                    break;
            
                default:
                    break;
            }
        }

        let redeems = [];
        list.forEach((item, index) => {
            if (pageInfo.inapp && pageInfo.inapp === '1') {
                redeems.push(
                    <tr key={'redeem_history_item_' + item.id}>
                        <td colSpan={6}>
                            <div className="redeem-history-item-card">
                                { renderRedeemType(item.receive_type) }
                                <div className="redeem-history-item-info flex-fill">
                                    <p className="redeem-title"><b>{item.created_at}</b></p>
                                    <p className="redeem-history-item-meta">
                                        Mã đơn hàng: {item.order_code || item.id} | Nhân viên: {item.staff_name}
                                    </p>
                                    {item.status === 'IN_TRANSIT' ? <button className="btn btn-sm btn-primary w-100" data-bs-toggle="modal" data-bs-target="#redeemConfirmationCodeModal" onClick={e => { showRedeemConfirmationCode(item.id) }}>Lấy mã xác nhận</button> : ''}
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            } else {
                redeems.push(
                    <tr key={'redeem_history_item_' + item.id}>
                        <td>{index + 1}</td>
                        <td>{item.created_at}</td>
                        <td>{item.order_code || item.id}</td>
                        <td>{item.staff_name}</td>
                        <td>{item.receive_type === 'AT_HOME' ? 'Giao tận nhà' : 'Tại cửa hàng'}</td>
                    </tr>
                )
            }
        })

        return redeems;
    }

    function showRedeemConfirmationCode(redeem_id) {
        console.log('?????? redeem_id', redeem_id);
    }

    function renderCertificateList(list) {
        if (!list || list.length === 0) return null;

        let certs = [];
        list.forEach((item, index) => {
            certs.push(
                <tr key={'cert_item_' + index}>
                    <td>{index + 1}</td>
                    <td>{item.created_at}</td>
                    <td>
                        {
                            inapp === '1'
                            ? <button className="btn btn-sm btn-outline-primary py-1 btn-to-browser" data-url={BASE_IMAGE + item.url_certificate} data-target="browser">Xem GCN Bảo hiểm</button>
                            : <a href={BASE_IMAGE + item.url_certificate} target="_blank" className="btn btn-sm btn-outline-primary py-1">Xem GCN Bảo hiểm</a>
                        }
                    </td>
                </tr>
            )
        })

        return certs;
    }

    function renderProvinceOptions(list) {
        if (!list || list.length === 0) return null;

        let provinces = [];
        list.forEach((item, index) => {
            provinces.push(
                <option value={item.Id} key={'province_option_' + index}>{item.Name}</option>
            )
        })

        return provinces;
    }

    function handleProvinceSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        if (!id || id === '') {
            setDistrictList([]);
        };

        setMembershipForm(form => {
            return {
                ...form,
                district_id: '',
                district_text: '',
                store_id: '',
                store_name: '',
                province_id: id,
                province_text: text
            }
        });

        let district_list = province_list.filter(item => item.Id === id);
        if (district_list.length > 0) {
            setDistrictList(district_list[0].Districts);   
        }
    }

    function renderDistrictOptions(list) {
        if (!list || list.length === 0) return null;

        let districts = [];
        list.forEach((item, index) => {
            districts.push(
                <option value={item.Id} key={'district_option_' + index}>{item.Name}</option>
            )
        })

        return districts;
    }

    function handleDistrictSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        setMembershipForm(form => {
            return {
                ...form,
                district_id: id,
                district_text: text,
                store_id: '',
                store_name: ''
            }
        });
    }

    function renderNearbyStoreOptions(list) {
        if (!list || list.length === 0) return null;

        let stores = [];
        list.forEach((item, index) => {
            stores.push(
                <option value={item.store_id} key={'nearby_store_option_' + index}>{item.name} - {item.address_full}</option>
            )
        })

        return stores;
    }

    function handleNearbyStoreSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        setMembershipForm(form => {
            return {
                ...form,
                store_id: id,
                store_name: text
            }
        });
    }

    const handleFormChange = (e, input_name) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setMembershipForm(form => ({
            ...form,
            [input_name]: value
        }))
    }

    function renderMergedAddress(address) {
        let array = address.split(', ');
        if (array.length < 3) return address;

        let return_address = '';
        array.slice(0, array.length - 2).forEach(item => {
            return_address += ', ' + item;
        })

        return return_address.slice(2);
    }

    function validateForm() {
        const form_field = [
            {
                field_id: 'token',
                field_name: 'User Token'
            },
            {
                field_id: 'full_name',
                field_name: 'Họ và tên'
            },
            {
                field_id: 'phone',
                field_name: 'Số điện thoại'
            },
            {
                field_id: 'province_id',
                field_name: 'Tỉnh/Thành phố'
            },
            {
                field_id: 'district_id',
                field_name: 'Quận/Huyện'
            },
            {
                field_id: 'address',
                field_name: 'Địa chỉ nhận hàng'
            },
            {
                field_id: 'delivery_day',
                field_name: 'Ngày giao hàng'
            },
            // {
            //     field_id: 'store_id',
            //     field_name: 'LocaMart đến lấy hàng'
            // }
        ]

        let error_field = [];
        form_field.forEach(item => {
            if (!membershipForm[item.field_id] || membershipForm[item.field_id] === '') {
                error_field.push(item.field_id);
            }
        })

        if (error_field.length > 0) {
            let text = '';

            if (error_field.length < 3) {
                error_field.forEach(item => {
                    try {
                        let field = form_field.filter(field => field.field_id === item)[0];
                        text += ', ' + field.field_name;                        
                    } catch (error) {
                        //
                    }
                });
            } else {
                text = '  Các trường thông tin';
            }
            setErrorHighlight(error_field);
            return text;

        } else {
            return '';
        }
    }

    function saveUserMembershipSettings(e) {
        e.preventDefault();

        setLoading(true);
        console.log(membershipForm);

        let validate = validateForm();
        if (validate !== '') {
            setLoading(false);
            alertError(validate.slice(2) + ' không được để trống!');
        } else {
            // alertError('Tính năng đang phát triển!');
            // setLoading(false);
            membershipService.update_membership_info(membershipForm)
                .then(data => {
                    setLoading(false);
                    if (data === 'SUCCESS') {
                        alertSuccess('Cập nhật thông tin thành công!');
                    } else {
                        alertError(data);
                    }
                })
        }
    }

    function errorHighlightCheck(field) {
        return errorHighlight.indexOf(field) !== -1 ? 'is-invalid' : '';
    }

    return (<>
        <section className="page-section">
            <div className="sec-header">
                <h4 className="sec-title">Lịch sử nhận rau</h4>
                <p className="sec-description">Số lượt nhận rau còn lại: {user.membership.times_received}</p>
            </div>
            <div className="sec-content">
                <div className={`table-responsive table-redeem-history ${pageInfo.inapp === '1' ? 'inapp' : ''}`}>
                    <table className={`table ${pageInfo.inapp === '1' ? 'table-borderless' : 'table-bordered'} mb-0`}>
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Thời gian nhận</th>
                                <th>Mã đơn hàng</th>
                                <th>Nhân viên</th>
                                <th>Phương thức nhận</th>
                            </tr>
                        </thead>
                        <tbody>
                            { renderRedeemHistory(redeemHistoryList) }
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
        {
            user.certificate_insurances && user.certificate_insurances.length > 0
            ? <section className="page-section pt-0">
                <div className="sec-header">
                    <h4 className="sec-title">Chứng nhận bảo hiểm</h4>
                </div>
                <div className="sec-content">
                    <div className="table-responsive table-redeem-history">
                        <table className="table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Thời gian cập nhật</th>
                                    <th style={{ width: '200px' }}>GCN Bảo hiểm</th>
                                </tr>
                            </thead>
                            <tbody>
                                { renderCertificateList(user.certificate_insurances) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            : ''
        }
        <section className="page-section pt-0">
            <div className="sec-header">
                <h4 className="sec-title">Tùy chọn Tem Rau</h4>
            </div>
            <div className="accordion" id="userMembershipSettingAccordion">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Thông tin nhận rau
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#userMembershipSettingAccordion">
                        <div className="accordion-body">
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="mobile" placeholder="Số điện thoại" value={membershipForm.phone} onChange={e => { handleFormChange(e, 'phone') }}></input>
                                <label htmlFor="mobile" className="form-label">Số điện thoại</label>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h6 className="mt-2 mb-1">Địa chỉ giao hàng</h6>
                                    <p className="form-text">
                                        Phí vận chuyển: <span className="fw-bold text-warning">{ !noNearbyStore ? '20.000 VNĐ' : SHIPPING_FEES_3KM.toLocaleString(locale_string) + ' VNĐ'}</span>
                                        <br />
                                        <span className="fw-bold text-warning">Lưu ý: Chỉ hỗ trợ giao hàng tuần với các địa chỉ có bán kính dưới 3km tính từ Cửa hàng LocaMart bạn chọn.</span>
                                    </p>
                                </div>
                                <div className="col-md-6 pe-md-2">
                                    <div className="form-floating mb-3">
                                        <select className={`form-select ${errorHighlightCheck('province_id')}`} id="province_id" aria-label="Tỉnh/Thành phố" value={membershipForm.province_id} onChange={handleProvinceSelect}>
                                            <option value="">Vui lòng chọn</option>
                                            {renderProvinceOptions(province_list)}
                                        </select>
                                        <label htmlFor="province_id">Tỉnh/Thành phố</label>
                                    </div>
                                </div>
                                <div className="col-md-6 ps-md-2">
                                    <div className="form-floating mb-3">
                                        <select className={`form-select ${errorHighlightCheck('district_id')}`} id="district_id" aria-label="Quận/Huyện" value={membershipForm.district_id} onChange={handleDistrictSelect}>
                                            <option value="">Vui lòng chọn</option>
                                            {renderDistrictOptions(districtList)}
                                        </select>
                                        <label htmlFor="district_id">Quận/Huyện</label>
                                    </div>
                                </div>
                                <div className="col-md-6 pe-md-2">
                                    <div className="form-floating mb-3">
                                        <input type="text" className={`form-control ${errorHighlightCheck('address')}`} id="address" placeholder="Địa chỉ giao hàng" value={membershipForm.address} onChange={e => handleFormChange(e, 'address')}></input>
                                        <label htmlFor="address">Địa chỉ giao hàng</label>
                                    </div>
                                </div>
                                <div className="col-md-6 ps-md-2">
                                    <div className="form-floating mb-3">
                                        <select className={`form-select ${errorHighlightCheck('delivery_day')}`} id="delivery_day" aria-label="Ngày giao hàng" value={membershipForm.delivery_day} onChange={e => handleFormChange(e, 'delivery_day')}>
                                            <option value="">Vui lòng chọn</option>
                                            <option value="2">Thứ 2</option>
                                            <option value="3">Thứ 3</option>
                                            <option value="4">Thứ 4</option>
                                            <option value="5">Thứ 5</option>
                                            <option value="6">Thứ 6</option>
                                            <option value="7">Thứ 7</option>
                                            <option value="8">Chủ Nhật</option>
                                        </select>
                                        <label htmlFor="delivery_day">Ngày giao hàng</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating mb-3">
                                        <select className={`form-select ${ errorHighlightCheck('store_id') }`} id="store_id" aria-label="LocaMart đến lấy hàng" value={membershipForm.store_id} onChange={handleNearbyStoreSelect} disabled={membershipForm.store_id !== ''}>
                                            <option value="">Vui lòng chọn</option>
                                            {renderNearbyStoreOptions(nearbyStoreList)}
                                        </select>
                                        <label htmlFor="store_id">LocaMart đến lấy hàng *</label>
                                        <div className="form-text text-dark">
                                            * Bạn có thể đến Cửa hàng LocaMart đã chọn để lấy rau khi có nhu cầu
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary mt-3" disabled={loading} onClick={saveUserMembershipSettings}>Cập nhật thông tin</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Tạm ngưng nhận rau
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#userMembershipSettingAccordion">
                        <div className="accordion-body">
                            {
                                membershipForm.status_redeem
                                ? <>
                                    <p>Để tạm ngưng việc tự động giao rau hàng tuần, hãy nhấn nút tạm ngưng nhận rau để thông báo cho cửa hàng LocaMart bạn đã đăng ký</p>
                                    <button className="btn btn-danger"><i className="fa-solid fa-sharp fa-circle-stop"></i>Tạm ngưng nhận rau</button>
                                </>
                                : <>
                                    <p className="mt-3">Bạn đang tạm ngừng nhận rau kể từ: 11:47 23-11-2023</p>
                                    <button className="btn btn-success"><i className="fa-solid fa-sharp fa-circle-play"></i>Tiếp tục nhận rau</button>
                                </>
                            }
                        </div>
                    </div>
                </div> */}
            </div>

            {/* redeemConfirmationCodeModal */}
            <div className="modal fade" id="redeemConfirmationCodeModal" tabIndex="-1" aria-labelledby="redeemConfirmationCodeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <h5 className="modal-title" id="redeemConfirmationCodeModalLabel">Mã xác nhận của bạn là</h5>
                            <p>Hãy cung cấp mã này cho nhân viên bán/giao hàng để xác nhận lấy rau</p>
                            <div className="redeem-confirmation-code mb-4" id="redeemConfirmationCode">
                                38
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
})

const Referral = connect(mapStateToProps, actionCreators)(props => {
    const { user } = props.auth;
    const { lang, locale_string } = props.pageInfo;

    function renderReferralLink(status) {
        switch (status) {
            case 'ACTIVE':
            case 'EXPIRED':
                return (
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" id="refLink" placeholder="Link giới thiệu Tem Rau" aria-label="Link giới thiệu Tem Rau" aria-describedby="button-addon2"></input>
                        <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="fa-light fa-sharp fa-copy me-2"></i>Sao chép</button>
                    </div>
                )
                break;

            default:
                return <NoMembership customText="Bạn vui lòng kích hoạt Tem Rau để có link giới thiệu bạn bè!" />
                break;
        }
    }
    
    return (
        <AccountLayout>
            <h4 className="page-account-title">Giới thiệu bạn bè</h4>
            { user.membership_status ? renderReferralLink(user.membership_status) : 'Có lỗi khi tải thông tin Tem Rau của bạn. Vui lòng thử lại sau!' }
        </AccountLayout>
    )
})

const SecuritySettings = connect(mapStateToProps, actionCreators)(props => {
    const { alertSuccess, alertError } = props;
    const { user } = props.auth;
    const { lang, locale_string } = props.pageInfo;
    
    return (
        <AccountLayout>
            <h4 className="page-account-title">Bảo mật tài khoản</h4>
            <div className="card">
                <div className="card-header">
                    <h6 className="card-title">Mã PIN giao dịch</h6>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="pincode_old" className="form-label">Mã PIN cũ</label>
                                <input type="text" className="form-control" id="pincode_old" placeholder="Mã PIN cũ"></input>
                            </div>
                        </div>
                        <div className="col-md-6 pe-md-2">
                            <div className="mb-3">
                                <label htmlFor="pincode_new" className="form-label">Mã PIN mới</label>
                                <input type="text" className="form-control" id="pincode_new" placeholder="Mã PIN mới"></input>
                            </div>
                        </div>
                        <div className="col-md-6 ps-md-2">
                            <div className="mb-3">
                                <label htmlFor="pincode_confirm" className="form-label">Xác nhận mã PIN mới</label>
                                <input type="text" className="form-control" id="pincode_confirm" placeholder="Xác nhận mã PIN mới"></input>
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary btn-200 me-2" onClick={e => { alertError('Tính năng đang phát triển. Vui lòng thử lại sau!') }}>Tạo mã PIN mới</button>
                            <button className="btn btn-warning" data-bs-toggle="modal" data-bs-target="#forgotPINModal">Quên mã PIN?</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h6 className="card-title">Mật khẩu truy cập</h6>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="password_old" className="form-label">Mật khẩu cũ</label>
                                <input type="text" className="form-control" id="password_old" placeholder="Mật khẩu cũ"></input>
                            </div>
                        </div>
                        <div className="col-md-6 pe-md-2">
                            <div className="mb-3">
                                <label htmlFor="password_new" className="form-label">Mật khẩu mới</label>
                                <input type="text" className="form-control" id="password_new" placeholder="Mật khẩu mới"></input>
                            </div>
                        </div>
                        <div className="col-md-6 ps-md-2">
                            <div className="mb-3">
                                <label htmlFor="password_confirm" className="form-label">Xác nhận mật khẩu mới</label>
                                <input type="text" className="form-control" id="password_confirm" placeholder="Xác nhận mật khẩu mới"></input>
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary btn-200" onClick={e => { alertError('Tính năng đang phát triển. Vui lòng thử lại sau!') }}>Cập nhật mật khẩu</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* forgotPINModal */}
            <div className="modal fade" id="forgotPINModal" tabIndex="-1" aria-labelledby="forgotPINModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="forgotPINModalLabel">Quên mã PIN giao dịch</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Để đặt lại mã PIN giao dịch, vui lòng liên hệ Bộ phận CSKH của chúng tôi qua các cách sau đây:</p>
                            <ul>
                                <li>Hotline: <a href="tel:1900999967">1900 99 99 67</a></li>
                                <li>Email: <a href="mailto:support@locamos.com">support@locamos.com</a></li>
                            </ul>
                            <p>Xin cảm ơn!</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Đóng lại</button>
                        </div>
                    </div>
                </div>
            </div>
        </AccountLayout>
    )
})

export {
    AccountLayout, Account, Membership, Referral, SecuritySettings, Transactions, SalesReport
};