import { pageInfoConstants } from 'app/redux/constants';

const getCurrencyUnit = () => {
    switch (window.location.hostname) {
        default:
            return 'VNĐ';
    }
}

const getDefaultLang = () => {
    switch (window.location.hostname) {
        default:
            return 'vi';
    }
}

const getDefaultLocaleString = () => {
    switch (window.location.hostname) {
        default:
            return 'vi-VN';
    }
}

const getLocaleString = (lang) => {
    switch (lang) {
        case 'en':
            return 'en-US';

        case 'vi':
            return 'vi-VN';

        default:
            return 'vi-VN';
    }
}

const initialState = {
    logo: '/assets/images/app-logo.png',
    brandname: 'LocaMart', 
    slogan: 'Nâng tầm Nông sản Việt',
    address: 'Số 2 Văn Cao, P. Thụy Khuê, Q. Tây Hồ',
    address_line2: 'Thành phố Hà Nội, Việt Nam',
    phone_number: '1900 99 99 67', 
    email: 'contact@locamart.vn',
    domain: 'locamart.vn',
    company_name: 'CÔNG TY CỔ PHẦN LOCAMART VIỆT NAM',
    company_shortname: 'LocaMart',
    company_shortcode: 'LM',
    company_registration_number: '0110485967',
    company_representative_person: 'VŨ NGỌC QUYẾT',
    company_representative_position: 'Giám Đốc',
    company_foundation_year: '2023',
    work_hours: "08:00 - 22:00",
    currency_unit: getCurrencyUnit(),
    display_lang: getDefaultLang(),
    locale_string: getDefaultLocaleString(),
    current_path: '/',
    inapp: 0,
}

export function pageInfo(state = initialState, action) {
    switch (action.type) {
        case pageInfoConstants.FETCH_SUCCESS:
            let fetch_time = new Date();
            return {
                ...state,
                ...action.data,
                timestamp: fetch_time.getTime()
            };

        case pageInfoConstants.CHANGE_LANGUAGE:
            return {
                ...state,
                display_lang: action.data,
                locale_string: getLocaleString(action.data)
            };

        case pageInfoConstants.CHANGE_INAPP:
            return {
                ...state,
                inapp: action.data,
            };

        case pageInfoConstants.FETCH:
        case pageInfoConstants.FETCH_FAILURE:
        default:
            return state;
    }
}