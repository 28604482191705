import React, { useState, useEffect } from 'react';
import './Membership.scss';

import { connect } from 'react-redux';
import { orderActions, alertActions, authActions } from 'app/redux/actions';

import { NAVIGATION, SHIPPING_FEES_3KM } from 'config';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { QrScanner } from '@yudiel/react-qr-scanner';
import { ScreenWithNavBarAndFooter, ScreenWithNavBar, Loading } from 'app/components';
import { Modal } from "bootstrap";

import { province_list } from '../Dashboard/data';

import { membershipService } from "services";

const mapStateToProps = (state) => {
    const { pageInfo, auth, order } = state;
    return { pageInfo, auth, order }
}

const actionCreators = {
    userUpdate: authActions.user_update,
    updateOrder: orderActions.update,
    clearOrder: orderActions.clear,
    alertSuccess: alertActions.success,
    alertWarning: alertActions.warning,
    alertError: alertActions.error,
}

const BuyMembership = connect(mapStateToProps, actionCreators)(props => {
    const { auth, order, pageInfo, userUpdate, updateOrder, alertSuccess, alertWarning, alertError } = props;
    const { user } = auth;
    const { lang, locale_string } = pageInfo;

    const navigate = useNavigate();

    const [membershipForm, setMembershipForm] = useState({
        ref_id: user.userApp.invited_by || order.ref_id,
        user_id: user.userApp.user_id,
        token: user.token || '',
        full_name: order.full_name || user.userApp.full_name,
        phone: renderPhoneNumber(order.phone || user.userApp.phone),
        province_id: order.province_id || '',
        province_text: order.province_text || '',
        district_id: order.district_id || '',
        district_text: order.district_text || '',
        address: order.address || '',
        store_id: order.store_id || '',
        store_name: order.store_name || '',
        null_store_id: order.null_store_id,
        delivery_day: order.delivery_day || '',
        payment_method: order.payment_method || 'TRANSFER',
        use_discount: order.use_discount || false,
        membership_id: user.membership.id || '',
        status: order.status
    })

    const [districtList, setDistrictList] = useState(() => {
        if (membershipForm.province_id !== '' && membershipForm.district_id !== '') {
            let district_list = province_list.filter(item => item.Id === membershipForm.province_id);
            if (district_list.length > 0) {
                return district_list[0].Districts;
            } else {
                return [];
            }
        } else {
            return [];
        }
    });

    const [nearbyStoreList, setNearbyStoreList] = useState([]);
    const [noNearbyStore, setNoNearbyStore] = useState(membershipForm.null_store_id);
    const [noNearbyStoreWarningModal, setNoNearbyStoreWarningModal] = useState(null);
    
    const [referrerSearch, setReferrerSearch] = useState(membershipForm.ref_info && membershipForm.ref_info.search_query ? membershipForm.ref_info.search_query : '');

    const [dLocaVoucherNumber, setDLocaVoucherNumber] = useState(membershipForm.use_discount ? 50 : 0);

    const [subtotalNumber, setSubtotalNumber] = useState(membershipForm.use_discount ? 2000000 : 3000000);

    const [loading, setLoading] = useState(false);
    const [errorHighlight, setErrorHighlight] = useState([]);

    useEffect(() => {
        userUpdate(user.token);
        setNoNearbyStoreWarningModal(new Modal('#noNearbyStoreWarningModal'));

        return () => {
        }
    }, [])
    
    useEffect(() => {
        updateOrder(membershipForm);
        // validateForm();
        if (errorHighlight.length > 0) {
            validateForm();
        }

        if (membershipForm.province_id !== '' && membershipForm.district_id !== '') {
            membershipService.fetch_nearby_store({ 
                province_id: membershipForm.province_id,
                district_id: membershipForm.district_id 
            }).then(data => {
                // console.log('?????? nearbyStoreList', data);
                if (data && data.length > 0) {
                    setNearbyStoreList(data);
                    setNoNearbyStore(false);
                } else {
                    setNearbyStoreList([]);
                    setNoNearbyStore(true);
                }
            })
        } else {
            setNearbyStoreList([]);
            setNoNearbyStore(false);
        }

        return () => {
        }
    }, [membershipForm, updateOrder])

    useEffect(() => {
        if (membershipForm.use_discount) {
            setDLocaVoucherNumber(50);
            setSubtotalNumber(2000000);
        } else {
            setDLocaVoucherNumber(0);
            setSubtotalNumber(3000000);
        }

        return () => {
        }
    }, [membershipForm.use_discount, setDLocaVoucherNumber, setSubtotalNumber])
    
    function renderProvinceOptions(list) {
        if (!list || list.length === 0) return null;

        let provinces = [];
        list.forEach((item, index) => {
            provinces.push(
                <option value={item.Id} key={'province_option_' + index}>{item.Name}</option>
            )
        })

        return provinces;
    }

    function handleProvinceSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        if (!id || id === '') {
            setDistrictList([]);
        };

        setMembershipForm(form => {
            return {
                ...form,
                district_id: '',
                district_text: '',
                store_id: '',
                store_name: '',
                province_id: id,
                province_text: text
            }
        });

        // console.log(id, index, text);

        let district_list = province_list.filter(item => item.Id === id);
        if (district_list.length > 0) {
            setDistrictList(district_list[0].Districts);   
        }
    }

    function renderDistrictOptions(list) {
        if (!list || list.length === 0) return null;

        let districts = [];
        list.forEach((item, index) => {
            districts.push(
                <option value={item.Id} key={'district_option_' + index}>{item.Name}</option>
            )
        })

        return districts;
    }

    function handleDistrictSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        setMembershipForm(form => {
            return {
                ...form,
                district_id: id,
                district_text: text,
                store_id: '',
                store_name: ''
            }
        });
    }

    function renderNearbyStoreOptions(list) {
        if (!list || list.length === 0) return null;

        let stores = [];
        list.forEach((item, index) => {
            stores.push(
                <option value={item.store_id} key={'nearby_store_option_' + index}>{item.name} - {item.address_full}</option>
            )
        })

        return stores;
    }

    function handleNearbyStoreSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        setMembershipForm(form => {
            return {
                ...form,
                store_id: id,
                store_name: text
            }
        });
    }

    const handleFormChange = (e, input_name) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setMembershipForm(form => ({
            ...form,
            [input_name]: value
        }))
    }
    
    function renderPhoneNumber(phone) {
        if (!phone || phone === '') return null;

        if (phone[0] === '0') return phone;

        if (phone[0] === '+' && phone.slice(1, 3) === '84') return '0' + phone.slice(3, phone.length);
        
        if (phone.slice(0, 2) === '84') return '0' + phone.slice(2, phone.length);

        return phone;
    }

    function renderPaymentMethod(payment_method) {
        let method = '';

        switch (payment_method) {
            case 'TRANSFER':
                method = 'CHUYỂN KHOẢN'
                break;

            case 'VNPAY':
                method = 'VNPAY'
                break;
        
            default:
                break;
        }

        return method;
    }

    function checkUserRefferer(e) {
        e.preventDefault();

        if (!referrerSearch || referrerSearch.length < 6) {
            alertError('Vui lòng nhập đầy đủ Mã giới thiệu / SĐT người giới thiệu!')
            return null;
        }

        membershipService.check_user_referer({ token: user.token, ref_id: referrerSearch })
            .then(data => {
                // console.log(data);
                if (typeof data === 'object') {
                    setMembershipForm(form => {
                        return {
                            ...form,
                            ref_id: referrerSearch,
                            ref_info: {
                                ...data,
                                search_query: referrerSearch
                            }
                        }
                    })
                } else {
                    alertError(data);
                }
            })
    }

    function validateForm() {
        const form_field = [
            {
                field_id: 'user_id',
                field_name: 'User ID'
            },
            {
                field_id: 'token',
                field_name: 'User Token'
            },
            {
                field_id: 'full_name',
                field_name: 'Họ và tên'
            },
            {
                field_id: 'phone',
                field_name: 'Số điện thoại'
            },
            {
                field_id: 'province_id',
                field_name: 'Tỉnh/Thành phố'
            },
            {
                field_id: 'district_id',
                field_name: 'Quận/Huyện'
            },
            {
                field_id: 'address',
                field_name: 'Địa chỉ nhận hàng'
            },
            {
                field_id: 'delivery_day',
                field_name: 'Ngày giao hàng'
            },
            {
                field_id: 'store_id',
                field_name: 'LocaMart đến lấy hàng'
            },
            {
                field_id: 'null_store_id',
                field_name: 'Ô tick Đồng ý giao hàng tuần'
            },
            {
                field_id: 'payment_method',
                field_name: 'Phương thức thanh toán'
            },
            {
                field_id: 'membership_id',
                field_name: 'Membership ID'
            },
        ]

        let error_field = [];
        form_field.forEach(item => {
            switch (item.field_id) {
                case 'store_id':
                    // console.log('?????? nearbyStoreList.length', nearbyStoreList.length);
                    if (nearbyStoreList.length > 0) {
                        // console.log(membershipForm['store_id'] === '');
                        if (!membershipForm['store_id'] || membershipForm['store_id'] === '') {
                            error_field.push('store_id');
                        }
                    }
                    break;
                
                case 'null_store_id':
                    if (nearbyStoreList.length < 1) {
                        if (!membershipForm['null_store_id']) {
                            error_field.push('null_store_id');
                        }
                    }
                    break;

                default:
                    if (!membershipForm[item.field_id] || membershipForm[item.field_id] === '') {
                        error_field.push(item.field_id);
                    }
                    break;
            }
        })

        if (error_field.length > 0) {
            let text = '';

            if (error_field.length < 3) {
                error_field.forEach(item => {
                    try {
                        let field = form_field.filter(field => field.field_id === item)[0];
                        text += ', ' + field.field_name;                        
                    } catch (error) {
                        //
                    }
                });
            } else {
                text = '  Các trường thông tin';
            }
            setErrorHighlight(error_field);
            return text;

        } else {
            setErrorHighlight([]);
            return '';
        }
    }

    function proceedToPayment(e) {
        e.preventDefault();
        noNearbyStoreWarningModal.hide();

        setLoading(true);

        let validate = validateForm();
        if (validate !== '') {
            setLoading(false);
            alertError(validate.slice(2) + ' không được để trống!');
        } else {
            if (membershipForm.use_discount && user.userApp.dvoucher_quantity < 50) {
                alertError('Tài khoản D-LocaVoucher của bạn không đủ. Vui lòng đổi thêm để tiếp tục!')
            } else if (
                membershipForm.payment_method === 'TRANSFER'
                && user.payment_transaction
                && user.payment_transaction.paymentMethod === 'TRANSFER'
                && user.payment_transaction.status !== 'APPROVED'
            ) {
                switch (user.payment_transaction.status) {
                    case 'CREATE':
                        alertWarning('Bạn đang có 01 giao dịch CHUYỂN KHOẢN chưa hoàn thành. Hãy bổ sung thông tin để tiếp tục!');
                        break;
                    
                    case 'PENDING':
                        alertWarning('Bạn đang có 01 giao dịch CHUYỂN KHOẢN đang chờ xác nhận. Chúng tôi sẽ thông báo kết quả sớm nhất!');
                        break;

                    case 'REFUSE':
                        alertWarning('Bạn đang có 01 giao dịch CHUYỂN KHOẢN có hình ảnh không hợp lệ. Vui lòng cập nhật lại để hoàn thành giao dich!');
                        break;

                    default:
                        break;
                }

                updateOrder({
                    ...order,
                    bank_account_transfer: user.bank_account_transfer,
                    payment_transaction: user.payment_transaction
                })
                navigate(NAVIGATION.PAYMENT_RESULT);
            } else if (
                membershipForm.payment_method === 'VNPAY'
                && user.payment_transaction
                && (user.payment_transaction.status === 'PENDING' || user.payment_transaction.status === 'REFUSE')
            ) {
                alertWarning('Bạn đang có 01 giao dịch ' + renderPaymentMethod(user.payment_transaction.paymentMethod) + ' chưa hoàn thành. Vui lòng hoàn thiện hoặc liên hệ CSKH để biết thêm chi tiết!');
                updateOrder({
                    ...order,
                    bank_account_transfer: user.bank_account_transfer,
                    payment_transaction: user.payment_transaction
                })
                navigate(NAVIGATION.PAYMENT_RESULT); 
            } else {
                navigate(NAVIGATION.PAYMENT_PREVIEW, { state: { payment_method: membershipForm.payment_method } });
            }
            setLoading(false);
        }
    }
    
    function handleContinueButton(e) {
        e.preventDefault();
        
        if (nearbyStoreList.length > 0) {
            proceedToPayment(e);
        } else {
            noNearbyStoreWarningModal.show();
        }
    }

    function errorHighlightCheck(field) {
        return errorHighlight.indexOf(field) !== -1 ? 'is-invalid' : '';
    }

    function goToPendingTransferResult(e) {
        updateOrder({
            ...order,
            bank_account_transfer: user.bank_account_transfer,
            payment_transaction: user.payment_transaction
        });
        navigate(NAVIGATION.PAYMENT_RESULT);
    }
    
    return (
        <ScreenWithNavBarAndFooter className="page-buy-membership">
            <Loading show={loading} />
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="sec-header">
                                <h2 className="sec-title">Mua Tem Rau</h2>
                                <p className="sec-description">Sở hữu ngay Tem Rau để dễ dàng tiếp cận với nguồn rau sạch hàng ngày từ vườn rau xanh <b>chuẩn VietGAP</b> của LocaMart.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="page-section">
                <div className="container-xl">
                    {
                        user.payment_transaction && user.payment_transaction.status !== 'APPROVED'
                        ? <div className="alert alert-warning mb-5" role="alert">
                            <b>Chú ý:</b> Bạn đang có 01 giao dịch với phương thức <b>{renderPaymentMethod(user.payment_transaction.paymentMethod)}</b> đang ở trạng thái chưa hoàn thành!
                            <button className="btn btn-warning w-100 mt-3" onClick={goToPendingTransferResult}>Xem giao dịch</button>
                        </div>
                        : ''
                    }
                    <div className="alert alert-success mb-5" role="alert">
                        <div className="d-flex flex-column flex-md-row gap-3 justify-content-between align-items-center">
                            <span className="flex-fill">Nếu bạn đã có Tem Rau LocaMart dạng thẻ cứng, hãy kích hoạt ngay nhé!</span>
                            <Link to={NAVIGATION.ACTIVE_MEMBERSHIP} className="btn btn-success">Kích hoạt thẻ cứng</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 mb-4 mb-md-0">
                            <h3 className="mb-4 mb-md-5">Thông tin chủ sở hữu</h3>
                            <div data-bs-theme="light">
                                <div className="row">
                                    <div className="col-md-6 pe-md-2">
                                        <div className="form-floating mb-3">
                                            <input type="text" className={`form-control ${ errorHighlightCheck('full_name') }`} id="full_name" placeholder="Họ và tên" defaultValue={membershipForm.full_name} onChange={e => handleFormChange(e, 'full_name')}></input>
                                            <label htmlFor="fullname">Họ và tên</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ps-md-2">
                                        <div className="form-floating mb-3">
                                            <input type="text" className={`form-control ${ errorHighlightCheck('phone') }`} id="phone" placeholder="Số điện thoại" defaultValue={membershipForm.phone} onChange={e => handleFormChange(e, 'phone')}></input>
                                            <label htmlFor="phone">Số điện thoại</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h6 className="mt-3 mb-1">Địa chỉ giao hàng</h6>
                                        <p className="form-text">
                                            Phí vận chuyển: <span className="fw-bold text-warning">{ !noNearbyStore ? '20.000 VNĐ' : SHIPPING_FEES_3KM.toLocaleString(locale_string) + ' VNĐ'}</span>
                                            <br />
                                            <span className="fw-bold text-warning">Lưu ý: Chỉ hỗ trợ giao hàng tuần với các địa chỉ có bán kính dưới 3km tính từ Cửa hàng LocaMart bạn chọn.</span>
                                        </p>
                                    </div>
                                    <div className="col-md-6 pe-md-2">
                                        <div className="form-floating mb-3">
                                            <select className={`form-select ${ errorHighlightCheck('province_id') }`} id="province_id" aria-label="Tỉnh/Thành phố" defaultValue={membershipForm.province_id} onChange={handleProvinceSelect}>
                                                <option value="">Vui lòng chọn</option>
                                                {renderProvinceOptions(province_list)}
                                            </select>
                                            <label htmlFor="province_id">Tỉnh/Thành phố</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ps-md-2">
                                        <div className="form-floating mb-3">
                                            <select className={`form-select ${ errorHighlightCheck('district_id') }`} id="district_id" aria-label="Quận/Huyện" value={membershipForm.district_id} onChange={handleDistrictSelect}>
                                                <option value="">Vui lòng chọn</option>
                                                {renderDistrictOptions(districtList)}
                                            </select>
                                            <label htmlFor="district_id">Quận/Huyện</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 pe-md-2">
                                        <div className="form-floating mb-3">
                                            <input type="text" className={`form-control ${ errorHighlightCheck('address') }`} id="address" placeholder="Địa chỉ giao hàng" defaultValue={membershipForm.address} onChange={e => handleFormChange(e, 'address')}></input>
                                            <label htmlFor="address">Địa chỉ giao hàng</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ps-md-2">
                                        <div className="form-floating mb-3">
                                            <select className={`form-select ${ errorHighlightCheck('delivery_day') }`} id="delivery_day" aria-label="Ngày giao hàng" value={membershipForm.delivery_day}  onChange={e => handleFormChange(e, 'delivery_day')}>
                                                <option value="">Vui lòng chọn</option>
                                                <option value="2">Thứ 2</option>
                                                <option value="3">Thứ 3</option>
                                                <option value="4">Thứ 4</option>
                                                <option value="5">Thứ 5</option>
                                                <option value="6">Thứ 6</option>
                                                <option value="7">Thứ 7</option>
                                                <option value="8">Chủ Nhật</option>
                                            </select>
                                            <label htmlFor="delivery_day">Ngày giao hàng</label>
                                        </div>
                                    </div>
                                    {
                                        !noNearbyStore
                                        ? <div className="col-12">
                                            <div className="form-floating mb-3">
                                                <select className={`form-select ${ errorHighlightCheck('store_id') }`} id="store_id" aria-label="LocaMart đến lấy hàng" value={membershipForm.store_id} onChange={handleNearbyStoreSelect}>
                                                    <option value="">Vui lòng chọn</option>
                                                    {renderNearbyStoreOptions(nearbyStoreList)}
                                                </select>
                                                <label htmlFor="store_id">LocaMart đến lấy hàng *</label>
                                                <div className="form-text text-dark">
                                                    * Bạn có thể đến Cửa hàng LocaMart đã chọn để lấy rau khi có nhu cầu<br />
                                                </div>
                                            </div>
                                        </div>
                                        : <div className="col-12">
                                            <div className="alert alert-warning">
                                                <p className="mb-1">
                                                    Danh sách cửa hàng trong khu vực bạn chọn hiện đang cập nhật. Nếu bạn đồng ý vẫn muốn giao rau hàng tuần tới địa chỉ này, vui lòng tick vào ô phía dưới. Bộ phận CSKH của LocaMart sẽ liên hệ để xác nhận.<br />
                                                    <span className="fw-bold text-danger">Lưu ý: Chỉ hỗ trợ giao hàng tuần với các địa chỉ có bán kính dưới 3km tính từ Cửa hàng LocaMart gần nhất.</span>
                                                </p>
                                                <div className="mb-3">
                                                    <div className={`form-check ${ errorHighlightCheck('null_store_id') }`}>
                                                        <input className={`form-check-input ${ errorHighlightCheck('null_store_id') }`} type="checkbox" checked={membershipForm.null_store_id} onChange={e => {handleFormChange(e, 'null_store_id')}} id="agreeToShipWithHigherPrice"></input>
                                                        <label className="form-check-label fw-bold" htmlFor="agreeToShipWithHigherPrice">
                                                            Tôi đồng ý nhận giao rau hàng tuần với mức phí vận chuyển {SHIPPING_FEES_3KM.toLocaleString(locale_string)} VNĐ tới địa chỉ đã chọn phía trên
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        membershipForm.ref_id !== '' && !membershipForm.ref_info
                                        ? ''
                                        : <div className="col-12">
                                            <h6 className="my-3">Người giới thiệu {membershipForm.ref_info && membershipForm.ref_info.full_name ? ': ' + membershipForm.ref_info.full_name : ''}</h6>
                                            <div className="input-group mb-3">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="ref_search" placeholder="Mã giới thiệu" value={referrerSearch} onChange={e => { setReferrerSearch(e.target.value) }}></input>
                                                    <label htmlFor="ref_search">Mã giới thiệu / SĐT người giới thiệu</label>
                                                </div>
                                                <button className="btn btn-primary" onClick={checkUserRefferer}>Kiểm tra</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 ps-md-5">
                            <h3 className="mb-4 mb-md-5">Thanh toán</h3>
                            <div className="mb-3">
                                <p className="mb-2 fw-semibold">Phương thức thanh toán</p>
                                <div className={`form-group ${ errorHighlightCheck('payment_method') }`}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="paymentMethod" id="paymentMethod_TRANSFER" value="TRANSFER" onChange={e => handleFormChange(e, 'payment_method')} defaultChecked={membershipForm.payment_method === 'TRANSFER'}></input>
                                        <label className="form-check-label" htmlFor="paymentMethod_TRANSFER">
                                            Chuyển khoản ngân hàng
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="paymentMethod" id="paymentMethod_VNPAY" value="VNPAY" onChange={e => handleFormChange(e, 'payment_method')} defaultChecked={membershipForm.payment_method === 'VNPAY'}></input>
                                        <label className="form-check-label" htmlFor="paymentMethod_VNPAY">
                                            VNPAY
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <p className="mb-0 fw-semibold">Mã giảm giá</p>
                                <div className="form-text mt-0 mb-2">Bạn còn <b>{user.userApp.dvoucher_quantity} D-LocaVoucher</b> trong tài khoản</div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="useDLocaVoucher" id="useDLocaVoucher" onChange={e => handleFormChange(e, 'use_discount')} defaultChecked={membershipForm.use_discount}></input>
                                    <label className="form-check-label" htmlFor="useDLocaVoucher">
                                        Giảm giá với 50 D-LocaVoucher<br />
                                    </label>
                                </div>
                            </div>
                            <div className="payment-preview">
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>Tổng phí</td>
                                            <td>{(3000000).toLocaleString(locale_string)} VNĐ</td>
                                        </tr>
                                        <tr>
                                            <td>D-LocaVoucher</td>
                                            <td>{dLocaVoucherNumber}</td>
                                        </tr>
                                        <tr>
                                        </tr>
                                        <tr>
                                            <td>Thanh toán</td>
                                            <td>{subtotalNumber.toLocaleString(locale_string)} VNĐ</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <button className="btn btn-lg btn-success text-white w-100" onClick={handleContinueButton} disabled={loading ? true : false}>Tiếp tục</button>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* noNearbyStoreWarningModal */}
            <div className="modal fade" id="noNearbyStoreWarningModal" tabIndex="-1" aria-labelledby="noNearbyStoreWarningModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="noNearbyStoreWarningModalLabel">Chú ý</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Danh sách cửa hàng trong khu vực bạn chọn hiện đang cập nhật. Nếu bạn đồng ý vẫn muốn giao rau hàng tuần tới địa chỉ này, hãy nhấn nút Xác nhận. Bộ phận CSKH của LocaMart sẽ liên hệ với bạn sớm nhất.<br />
                                <span className="fw-bold text-danger">Lưu ý: Chỉ hỗ trợ giao hàng tuần với các địa chỉ có bán kính dưới 3km tính từ Cửa hàng LocaMart gần nhất. Phí vận chuyển: {SHIPPING_FEES_3KM.toLocaleString(locale_string)} VNĐ</span>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Quay lại</button>
                            <button type="button" className="btn btn-primary" onClick={proceedToPayment}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            </div>
        </ScreenWithNavBarAndFooter>
    )
})

const ActiveMembership = connect(mapStateToProps, actionCreators)(props => {
    const { auth, order, pageInfo, userUpdate, updateOrder, clearOrder, alertSuccess, alertError } = props;
    const { user } = auth;
    const { lang, locale_string } = pageInfo;

    const navigate = useNavigate();

    const [pageStatus, setPageStatus] = useState('');

    const [membershipForm, setMembershipForm] = useState({
        ref_id: user.userApp.invited_by || order.ref_id,
        ref_info: order.ref_info || null,
        user_id: user.userApp.user_id,
        token: user.token || '',
        full_name: order.full_name || user.userApp.full_name,
        phone: renderPhoneNumber(order.phone || user.userApp.phone),
        province_id: order.province_id || '',
        province_text: order.province_text || '',
        district_id: order.district_id || '',
        district_text: order.district_text || '',
        address: order.address || '',
        store_id: order.store_id || null,
        store_name: order.store_name || '',
        null_store_id: order.null_store_id,
        delivery_day: order.delivery_day || '',
        membercard_id: order.membercard_id || '',
        membercard_value: order.membercard_value || '',
        membership_id: user.membership.id || '',
        status: order.status
    })

    const [districtList, setDistrictList] = useState(() => {
        if (membershipForm.province_id !== '' && membershipForm.district_id !== '') {
            let district_list = province_list.filter(item => item.Id === membershipForm.province_id);
            if (district_list.length > 0) {
                return district_list[0].Districts;
            } else {
                return [];
            }
        } else {
            return [];
        }
    });

    const [nearbyStoreList, setNearbyStoreList] = useState([]);
    const [noNearbyStore, setNoNearbyStore] = useState(membershipForm.null_store_id);
    const [noNearbyStoreWarningModal, setNoNearbyStoreWarningModal] = useState(null);
    
    const [referrerSearch, setReferrerSearch] = useState(membershipForm.ref_info && membershipForm.ref_info.search_query ? membershipForm.ref_info.search_query : '');

    const [loading, setLoading] = useState(false);
    const [errorHighlight, setErrorHighlight] = useState([]);

    useEffect(() => {
        userUpdate(user.token);
        setNoNearbyStoreWarningModal(new Modal('#noNearbyStoreWarningModal'));

        return () => {
        }
    }, [])

    useEffect(() => {
        if (window.innerWidth > 767) {
            setPageStatus('FORM');
        } else {
            setPageStatus('QRCODE')
        }

        return () => {
        }
    }, [window.innerWidth])
    

    useEffect(() => {
        updateOrder(membershipForm);

        if (errorHighlight.length > 0) {
            validateForm();
        }

        if (membershipForm.province_id !== '' && membershipForm.district_id !== '') {
            membershipService.fetch_nearby_store({ 
                province_id: membershipForm.province_id,
                district_id: membershipForm.district_id 
            }).then(data => {
                // console.log('?????? nearbyStoreList', data);
                if (data && data.length > 0) {
                    setNearbyStoreList(data);
                    setNoNearbyStore(false);
                } else {
                    setNearbyStoreList([]);
                    setNoNearbyStore(true);
                }
            })
        } else {
            setNearbyStoreList([]);
            setNoNearbyStore(false);
        }

        return () => {
        }
    }, [membershipForm, updateOrder])
    
    function renderProvinceOptions(list) {
        if (!list || list.length === 0) return null;

        let provinces = [];
        list.forEach((item, index) => {
            provinces.push(
                <option value={item.Id} key={'province_option_' + index}>{item.Name}</option>
            )
        })

        return provinces;
    }

    function handleProvinceSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        if (!id || id === '') {
            setDistrictList([]);
        };

        setMembershipForm(form => {
            return {
                ...form,
                district_id: '',
                district_text: '',
                province_id: id,
                province_text: text
            }
        });

        // console.log(id, index, text);

        let district_list = province_list.filter(item => item.Id === id);
        if (district_list.length > 0) {
            setDistrictList(district_list[0].Districts);   
        }
    }

    function renderDistrictOptions(list) {
        if (!list || list.length === 0) return null;

        let districts = [];
        list.forEach((item, index) => {
            districts.push(
                <option value={item.Id} key={'district_option_' + index}>{item.Name}</option>
            )
        })

        return districts;
    }

    function handleDistrictSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        setMembershipForm(form => {
            return {
                ...form,
                district_id: id,
                district_text: text,
                store_id: '',
                store_name: ''
            }
        });
    }

    function renderNearbyStoreOptions(list) {
        if (!list || list.length === 0) return null;

        let stores = [];
        list.forEach((item, index) => {
            stores.push(
                <option value={item.store_id} key={'nearby_store_option_' + index}>{item.name} - {item.address_full}</option>
            )
        })

        return stores;
    }

    function handleNearbyStoreSelect(e) {
        let id = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let text = e.nativeEvent.target[index].text;

        setMembershipForm(form => {
            return {
                ...form,
                store_id: id,
                store_name: text
            }
        });
    }

    function handleFormChange(e, input_name) {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setMembershipForm(form => ({
            ...form,
            [input_name]: value
        }))
    }
    
    function renderPhoneNumber(phone) {
        if (!phone || phone === '') return null;

        if (phone[0] === '0') return phone;

        if (phone[0] === '+' && phone.slice(1, 3) === '84') return '0' + phone.slice(3, phone.length);
        
        if (phone.slice(0, 2) === '84') return '0' + phone.slice(2, phone.length);

        return phone;
    }

    function renderDeliveryDay(day_index) {
        if (!day_index || day_index === '') {
            return 'Bạn chưa chọn';
        }
        
        return day_index === '8' ? 'Chủ Nhật' : 'Thứ ' + day_index;
    }

    function checkUserRefferer(e) {
        e.preventDefault();

        if (!referrerSearch || referrerSearch.length < 6) {
            alertError('Vui lòng nhập đầy đủ Mã giới thiệu / SĐT người giới thiệu!')
            return null;
        }

        membershipService.check_user_referer({ token: user.token, ref_id: referrerSearch })
            .then(data => {
                // console.log(data);
                if (typeof data === 'object') {
                    setMembershipForm(form => {
                        return {
                            ...form,
                            ref_id: referrerSearch,
                            ref_info: {
                                ...data,
                                search_query: referrerSearch
                            }
                        }
                    })
                } else {
                    alertError(data);
                }
            })
    }

    function handleQRCodeScan(qr_result) {
        // console.log('?????? qr_result', qr_result);
        if (!qr_result || qr_result.length === 0) return null;

        let membercard_array = qr_result.split('|');
        // console.log('??????? membercard_array', membercard_array);

        if (membercard_array.length > 1) {
            setPageStatus('FORM');
        }

        setMembershipForm(form => {
            return {
                ...form,
                membercard_id: membercard_array[0],
                membercard_value: membercard_array[1]
            }
        })

    }

    function validateForm() {
        // console.log('?????? membershipForm', membershipForm);
        const form_field = [
            {
                field_id: 'user_id',
                field_name: 'User ID'
            },
            {
                field_id: 'token',
                field_name: 'User Token'
            },
            {
                field_id: 'full_name',
                field_name: 'Họ và tên'
            },
            {
                field_id: 'phone',
                field_name: 'Số điện thoại'
            },
            {
                field_id: 'province_id',
                field_name: 'Tỉnh/Thành phố'
            },
            {
                field_id: 'district_id',
                field_name: 'Quận/Huyện'
            },
            {
                field_id: 'address',
                field_name: 'Địa chỉ nhận hàng'
            },
            {
                field_id: 'delivery_day',
                field_name: 'Ngày giao hàng'
            },
            {
                field_id: 'store_id',
                field_name: 'LocaMart đến lấy hàng'
            },
            {
                field_id: 'null_store_id',
                field_name: 'Ô tick Đồng ý giao hàng tuần'
            },
            {
                field_id: 'membercard_id',
                field_name: 'Mã Tem Rau'
            },
            {
                field_id: 'membercard_value',
                field_name: 'Mệnh giá thẻ'
            },
            {
                field_id: 'membership_id',
                field_name: 'Membership ID'
            },
        ]

        let error_field = [];
        form_field.forEach(item => {
            switch (item.field_id) {
                case 'store_id':
                    // console.log('?????? nearbyStoreList.length', nearbyStoreList.length);
                    if (nearbyStoreList.length > 0) {
                        // console.log(membershipForm['store_id'] === '');
                        if (!membershipForm['store_id'] || membershipForm['store_id'] === '') {
                            error_field.push('store_id');
                        }
                    }
                    break;
                
                case 'null_store_id':
                    if (nearbyStoreList.length < 1) {
                        if (!membershipForm['null_store_id']) {
                            error_field.push('null_store_id');
                        }
                    }
                    break;

                default:
                    if (!membershipForm[item.field_id] || membershipForm[item.field_id] === '') {
                        error_field.push(item.field_id);
                    }
                    break;
            }
        })

        if (error_field.length > 0) {
            let text = '';

            if (error_field.length < 3) {
                error_field.forEach(item => {
                    try {
                        let field = form_field.filter(field => field.field_id === item)[0];
                        text += ', ' + field.field_name;                        
                    } catch (error) {
                        //
                    }
                });
            } else {
                text = '  Các trường thông tin';
            }
            setErrorHighlight(error_field);
            return text;

        } else {
            setErrorHighlight([]);
            return '';
        }
    }

    function proceedToActivate(e) {
        e.preventDefault();
        noNearbyStoreWarningModal.hide();

        setLoading(true);

        let validate = validateForm();
        // console.log(validate);
        if (validate !== '') {
            setLoading(false);
            alertError(validate.slice(2) + ' không được để trống!');
        } else {
            // alertSuccess('Bắn!!!');
            // setLoading(false);
            // return;
            membershipService.active_membership(membershipForm)
                .then(data => {
                    setLoading(false);

                    if (typeof data === 'object') {
                        // alertSuccess('Kích hoạt thẻ thành công');
                        clearOrder();
                        navigate(NAVIGATION.ACTIVE_MEMBERSHIP_SUCCESS, { state: { response: data } });
                    } else {
                        alertError(data);
                    }
                })
        }
    }
    
    function handleActiveButton(e) {
        e.preventDefault();
        
        if (nearbyStoreList.length > 0) {
            proceedToActivate(e);
        } else {
            noNearbyStoreWarningModal.show();
        }
    }

    function errorHighlightCheck(field) {
        return errorHighlight.indexOf(field) !== -1 ? 'is-invalid' : '';
    }
    
    return (
        <ScreenWithNavBarAndFooter className="page-buy-membership">
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="sec-header">
                                <h2 className="sec-title">Kích hoạt Tem Rau</h2>
                                <p className="sec-description">Cảm ơn bạn đã sở hữu cho mình tấm Tem Rau của LocaMart. Hãy kích hoạt ngay để nhận rau sạch <b>chuẩn VietGAP</b> hàng tuần.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="page-section">
                <div className="container-xl">
                    {
                        pageStatus === 'QRCODE'
                            ? <div className="qr-code-scanner">
                                <div className="row justify-content-center">
                                    <div className="col-md-4 text-center">
                                        <p>Quét QR Code trên thẻ để tiếp tục hoặc bấm nút phía dưới để tự nhập thông tin</p>
                                        <QrScanner
                                            onDecode={handleQRCodeScan}
                                            onError={(error) => {
                                                // console.log(error.name);
                                                if (error.name && error.name === 'NotAllowedError') {
                                                    alertError('Bạn cần cấp quyền truy cập camera để thực hiện chức năng quét QR!');
                                                }
                                            }}
                                            scanDelay={1000}
                                        />
                                        <button className="btn btn-outline-primary mt-4" onClick={e => { setPageStatus('FORM') }}>Tự nhập thông tin Tem Rau</button>
                                    </div>
                                </div>
                            </div>
                            : ''
                        }
                    {
                        pageStatus === 'FORM'
                        ? <div className="row">
                            <div className="col-md-7 mb-4 mb-md-0">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="mb-4">Tem Rau</h3>
                                    </div>
                                    <div className="col-md-4 pe-md-2 mb-3">
                                        <div className="form-floating">
                                            <input type="text" className={`form-control ${ errorHighlightCheck('membercard_id') }`} id="membercard_id" placeholder="Mã Tem Rau" defaultValue={membershipForm.membercard_id} onChange={e => handleFormChange(e, 'membercard_id')}></input>
                                            <label htmlFor="membercard_id">Mã Tem Rau</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-md-2 pe-md-2 mb-3">
                                        <div className="form-floating">
                                            <select type="text" className={`form-select ${ errorHighlightCheck('membercard_value') }`} id="membercard_value" placeholder="Mệnh giá thẻ" value={membershipForm.membercard_value} onChange={e => handleFormChange(e, 'membercard_value')}>
                                                <option value="">Vui lòng chọn</option>
                                                <option value="3000000">3.000.000đ</option>
                                                <option value="1000000">1.000.000đ</option>
                                                <option value="500000">500.000đ</option>
                                            </select>
                                            <label htmlFor="fullname">Mệnh giá thẻ</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-md-2 mb-3">
                                        <button className="btn btn-outline-primary w-100 h-100" onClick={e => { setPageStatus('QRCODE') }}><i className="fa-sharp fa-light fa-barcode-read"></i> Quét QR Thẻ</button>
                                    </div>
                                    <div className="col-12">
                                        <h3 className="mb-4 mt-5">Thông tin chủ sở hữu</h3>
                                    </div>
                                    <div className="col-md-6 pe-md-2">
                                        <div className="form-floating mb-3">
                                            <input type="text" className={`form-control ${ errorHighlightCheck('full_name') }`} id="full_name" placeholder="Họ và tên" defaultValue={membershipForm.full_name} onChange={e => handleFormChange(e, 'full_name')}></input>
                                            <label htmlFor="fullname">Họ và tên</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ps-md-2">
                                        <div className="form-floating mb-3">
                                            <input type="text" className={`form-control ${ errorHighlightCheck('phone') }`} id="phone" placeholder="Số điện thoại" defaultValue={membershipForm.phone} onChange={e => handleFormChange(e, 'phone')}></input>
                                            <label htmlFor="phone">Số điện thoại</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h6 className="mt-3 mb-1">Địa chỉ giao hàng</h6>
                                        <p className="form-text">
                                            Phí vận chuyển: <span className="fw-bold text-warning">{ !noNearbyStore ? '20.000 VNĐ' : SHIPPING_FEES_3KM.toLocaleString(locale_string) + ' VNĐ'}</span>
                                            <br />
                                            <span className="fw-bold text-warning">Lưu ý: Chỉ hỗ trợ giao hàng tuần với các địa chỉ có bán kính dưới 3km tính từ Cửa hàng LocaMart bạn chọn.</span>
                                        </p>
                                    </div>
                                    <div className="col-md-6 pe-md-2">
                                        <div className="form-floating mb-3">
                                            <select className={`form-select ${ errorHighlightCheck('province_id') }`} id="province_id" aria-label="Tỉnh/Thành phố" defaultValue={membershipForm.province_id} onChange={handleProvinceSelect}>
                                                <option value="">Vui lòng chọn</option>
                                                {renderProvinceOptions(province_list)}
                                            </select>
                                            <label htmlFor="province_id">Tỉnh/Thành phố</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ps-md-2">
                                        <div className="form-floating mb-3">
                                            <select className={`form-select ${ errorHighlightCheck('district_id') }`} id="district_id" aria-label="Quận/Huyện" value={membershipForm.district_id} onChange={handleDistrictSelect}>
                                                <option value="">Vui lòng chọn</option>
                                                {renderDistrictOptions(districtList)}
                                            </select>
                                            <label htmlFor="district_id">Quận/Huyện</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 pe-md-2">
                                        <div className="form-floating mb-3">
                                            <input type="text" className={`form-control ${ errorHighlightCheck('address') }`} id="address" placeholder="Địa chỉ giao hàng" defaultValue={membershipForm.address} onChange={e => handleFormChange(e, 'address')}></input>
                                            <label htmlFor="address">Địa chỉ giao hàng</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ps-md-2">
                                        <div className="form-floating mb-3">
                                            <select className={`form-select ${ errorHighlightCheck('delivery_day') }`} id="delivery_day" aria-label="Ngày giao hàng" value={membershipForm.delivery_day}  onChange={e => handleFormChange(e, 'delivery_day')}>
                                                <option value="">Vui lòng chọn</option>
                                                <option value="2">Thứ 2</option>
                                                <option value="3">Thứ 3</option>
                                                <option value="4">Thứ 4</option>
                                                <option value="5">Thứ 5</option>
                                                <option value="6">Thứ 6</option>
                                                <option value="7">Thứ 7</option>
                                                <option value="8">Chủ Nhật</option>
                                            </select>
                                            <label htmlFor="delivery_day">Ngày giao hàng</label>
                                        </div>
                                    </div>
                                    {
                                        !noNearbyStore
                                        ? <div className="col-12">
                                            <div className="form-floating mb-3">
                                                <select className={`form-select ${ errorHighlightCheck('store_id') }`} id="store_id" aria-label="LocaMart đến lấy hàng" value={membershipForm.store_id} onChange={handleNearbyStoreSelect}>
                                                    <option value="">Vui lòng chọn</option>
                                                    {renderNearbyStoreOptions(nearbyStoreList)}
                                                </select>
                                                <label htmlFor="store_id">LocaMart đến lấy hàng *</label>
                                                <div className="form-text text-dark">
                                                    * Bạn có thể đến Cửa hàng LocaMart đã chọn để lấy rau khi có nhu cầu
                                                </div>
                                            </div>
                                        </div>
                                        : <div className="col-12">
                                            <div className="alert alert-warning">
                                                <p className="mb-1">
                                                    <span className="fw-bold text-danger">Lưu ý: Chỉ hỗ trợ giao hàng tuần với các địa chỉ có bán kính dưới 3km tính từ Cửa hàng LocaMart gần nhất.</span><br />
                                                    Danh sách cửa hàng trong khu vực bạn chọn hiện đang cập nhật. Nếu bạn đồng ý vẫn muốn giao rau hàng tuần tới địa chỉ này, vui lòng tick vào ô phía dưới. Bộ phận CSKH của LocaMart sẽ liên hệ để xác nhận.
                                                </p>
                                                <div className="mb-3">
                                                    <div className={`form-check ${ errorHighlightCheck('null_store_id') }`}>
                                                        <input className={`form-check-input ${ errorHighlightCheck('null_store_id') }`} type="checkbox" checked={membershipForm.null_store_id} onChange={e => {handleFormChange(e, 'null_store_id')}} id="agreeToShipWithHigherPrice"></input>
                                                        <label className="form-check-label fw-bold" htmlFor="agreeToShipWithHigherPrice">
                                                            Tôi đồng ý nhận giao rau hàng tuần với mức phí vận chuyển {SHIPPING_FEES_3KM.toLocaleString(locale_string)} VNĐ tới địa chỉ đã chọn phía trên
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        membershipForm.ref_id !== '' && !membershipForm.ref_info
                                        ? ''
                                        : <div className="col-12">
                                            <h6 className="my-3">Người giới thiệu {membershipForm.ref_info && membershipForm.ref_info.full_name ? ': ' + membershipForm.ref_info.full_name : ''}</h6>
                                            <div className="input-group mb-3">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="ref_search" placeholder="Mã giới thiệu" value={referrerSearch} onChange={e => { setReferrerSearch(e.target.value) }}></input>
                                                    <label htmlFor="ref_search">Mã giới thiệu / SĐT người giới thiệu</label>
                                                </div>
                                                <button className="btn btn-primary" onClick={checkUserRefferer}>Kiểm tra</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-5 ps-md-5">
                                <h3 className="mb-4">Kiểm tra thông tin</h3>
                                <div className="active-preview">
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td>Mã Tem Rau</td>
                                                <td>{membershipForm.membercard_id ? membershipForm.membercard_id : 'Bạn chưa nhập'}</td>
                                            </tr>
                                            <tr>
                                                <td>Mệnh giá</td>
                                                <td>{membershipForm.membercard_value ? parseInt(membershipForm.membercard_value).toLocaleString(locale_string) + 'VNĐ' : 'Bạn chưa chọn'}</td>
                                            </tr>
                                            <tr>
                                                <td>Chủ thẻ</td>
                                                <td>{membershipForm.full_name ? membershipForm.full_name : 'Bạn chưa nhập'}</td>
                                            </tr>
                                            <tr>
                                                <td>Giao hàng</td>
                                                <td>{membershipForm.address !== '' && membershipForm.district_text !== '' && membershipForm.province_text !== '' ? membershipForm.address + ', ' + membershipForm.district_text + ', ' + membershipForm.province_text : 'Bạn chưa chọn'}</td>
                                            </tr>
                                            <tr>
                                                <td>Ngày giao</td>
                                                <td>{renderDeliveryDay(membershipForm.delivery_day)}</td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                                <td>LocaMart lấy hàng</td>
                                                <td>{membershipForm.store_name ? membershipForm.store_name : 'Bạn chưa chọn'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button className="btn btn-lg btn-success text-white shadow-lg w-100 mt-4 mb-3" onClick={handleActiveButton} disabled={loading ? true : false}>Xác nhận kích hoạt</button>
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                </div>
            </section>

            {/* noNearbyStoreWarningModal */}
            <div className="modal fade" id="noNearbyStoreWarningModal" tabIndex="-1" aria-labelledby="noNearbyStoreWarningModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="noNearbyStoreWarningModalLabel">Chú ý</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                <span className="fw-bold text-danger">Lưu ý: Chỉ hỗ trợ giao hàng tuần với các địa chỉ có bán kính dưới 3km tính từ Cửa hàng LocaMart gần nhất. Phí vận chuyển: {SHIPPING_FEES_3KM.toLocaleString(locale_string)} VNĐ</span><br />
                                Danh sách cửa hàng trong khu vực bạn chọn hiện đang cập nhật. Nếu bạn đồng ý vẫn muốn giao rau hàng tuần tới địa chỉ này, hãy nhấn nút Xác nhận. Bộ phận CSKH của LocaMart sẽ liên hệ với bạn sớm nhất.
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Quay lại</button>
                            <button type="button" className="btn btn-primary" onClick={proceedToActivate}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            </div>
        </ScreenWithNavBarAndFooter>
    )
})

const ActiveMembershipSuccess = connect(mapStateToProps, actionCreators)(props => {
    const { auth, order, pageInfo, userUpdate, updateOrder, clearOrder, alertSuccess, alertError } = props;
    const { user } = auth;
    const { lang, locale_string } = pageInfo;

    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [responseData, setResponseData] = useState(null);

    useEffect(() => {
        if (location.state && location.state.response) {
            setResponseData(location.state.response);
            setLoading(false);
        } else {
            setLoading(false);
            setResponseData(null);
        }

        return () => {
        }
    }, [location, setResponseData, setLoading])

    function renderActiveCardResultHeader(data) {
        if (loading) return (
            <i className="fa-light fa-sharp fa-spin fa-2x fa-loader"></i>
        );

        if (!data) return (<>
            <h2 className="sec-title">Không tìm thấy</h2>
            <p className="sec-description">Vui lòng quay lại trang Kích hoạt thẻ hoặc Quản lý tài khoản để kiểm tra thông tin!</p>
        </>)

        return (<>
            <h2 className="sec-title">Kích hoạt thành công</h2>
            <p className="sec-description">Cảm ơn bạn đã lựa chọn LocaMart! Bộ phận CSKH của chúng tôi sẽ liên hệ với bạn</p>
        </>)
    }

    function renderActiveCardResult(data) {
        if (loading) return;

        if (!data) return (
            <div className="payment-preview text-center">
                <i className="fa-light fa-sharp fa-3x text-danger fa-ban"></i>
            </div>
        );

        return (<>
            <div className="payment-preview">
                <div className="text-center mb-3">
                    <p>Hãy theo dõi Zalo OA của LocaMart để nhận thông báo, ưu đãi, danh mục rau hàng tuần sớm nhất!</p>
                    <img src="/public_assets/images/locamart-zalo-oa.jpg" alt="" className="img-fluid w-100 mb-3"></img>
                    {/* <a href="https://zalo.me/2820821147149288952" className="btn btn-primary w-100">Theo dõi ngay</a> */}
                </div>
                <hr />
                <p className="fw-bold text-center my-3">THÔNG TIN TEM RAU</p>
                <table className="table table-borderless mb-0">
                    <tbody>
                        <tr>
                            <td>Thời gian bắt đầu</td>
                            <td>{responseData.formatMembershipFromDate}</td>
                        </tr>
                        <tr>
                            <td>Thời gian kết thúc</td>
                            <td>{responseData.formatMembershipToDate}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Link to={NAVIGATION.MEMBERSHIP} className="btn btn-lg btn-success w-100 mt-3">Xem Tem Rau của bạn</Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>)
    }

    return (
        <ScreenWithNavBar className="page-payment">
            <header className="page-header content-page-header">
                <div className="container-xl">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="sec-header text-center">
                                {renderActiveCardResultHeader(responseData)}
                            </div>
                            <div className="sec-content p-3 p-md-4">
                                {renderActiveCardResult(responseData)}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </ScreenWithNavBar>
    )
})

export {
    BuyMembership, ActiveMembership, ActiveMembershipSuccess
};