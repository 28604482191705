export const NAVIGATION = {
    // Authentications
    LOGIN: '/login',
    AUTH_TOKEN: '/authentication',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    LOGOUT: '/logout',
    
    // Main Screens
    DASHBOARD: '/',
    VEGETABLE_CARD: '/about',

    // Acount
    ACCOUNT: '/account',
    MEMBERSHIP: '/membership',
    REFERRAL: '/referral',
    SECURITY_SETTINGS: '/security-settings',
    TRANSACTIONS: '/transactions',
    SALES_REPORT: '/sales-report',

    // Buy Membership
    BUY_MEMBERSHIP: '/buy-membership',
    PAYMENT_PREVIEW: '/payment-preview',
    PAYMENT_CONFIRM_OTP: '/payment-otp',
    PAYMENT_RESULT: '/payment-result',

    // Active Membership
    ACTIVE_MEMBERSHIP: '/active',
    ACTIVE_MEMBERSHIP_SUCCESS: '/active-success',
    REDEEM_MEMBERSHIP: '/redeem',

    // Shop
    SHOP: '/shop',
    SHOP_ITEM_DETAIL: '/shop/:id',

    // Others
    ERROR_404: '/not-found',
    TERMS_OF_SERVICE: '/terms-of-service',
    PRIVACY_POLICY: '/privacy-policy',
    PAYMENT_POLICY: '/payment-policy',
    SHIPMENT_POLICY: '/shipment-policy',
    INSPECTION_POLICY: '/inspection-policy',
    RETURN_POLICY: '/return-policy',
    RIGHTS_PROTECTION_POLICY: '/rights-protection-policy',
    WEBSITE_OWNER: '/website-owner',
    CONTACT_US: '/contact-us',
    ABOUT_US: '/about-us',
    FAQS: '/faqs',
    KEYWORDS: '/keywords',
    GUIDE_VNPAY: '/huong-dan-thanh-toan-vnpay'
}